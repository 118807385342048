import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, object, SchemaOf, string } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep25ItemViewModel>();

export interface CommercialCombinedStep25Props {
  initialValue: CommercialCombinedStep25ViewModel;
}

export type CommercialCombinedStep25Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep25Props
>;

export interface CommercialCombinedStep25ViewModel {
  locations: Array<CommercialCombinedStep25ItemViewModel>;
}

export interface CommercialCombinedStep25ItemViewModel {
  subsidenceGroundHeaveLandslipCoverAdded?: boolean | null;
  subsidenceGroundHeaveLandslipCoverAddedRestrictionsApply?: boolean | null;
  subsidenceGroundHeaveLandslipCoverAddedRestrictionsApplyDetails?:
    | string
    | null;
}

const commercialCombinedStep25ItemSchema: SchemaOf<CommercialCombinedStep25ItemViewModel> =
  object({
    subsidenceGroundHeaveLandslipCoverAdded: boolean().nullable().required(),
    subsidenceGroundHeaveLandslipCoverAddedRestrictionsApply: requiredIf(
      boolean().nullable(),
      'subsidenceGroundHeaveLandslipCoverAdded'
    ),
    subsidenceGroundHeaveLandslipCoverAddedRestrictionsApplyDetails: requiredIf(
      string().nullable().label('Full details'),
      [
        'subsidenceGroundHeaveLandslipCoverAdded',
        'subsidenceGroundHeaveLandslipCoverAddedRestrictionsApply'
      ]
    )
  });

export const commercialCombinedStep25Schema: SchemaOf<CommercialCombinedStep25ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep25ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep25ViewModelInitialValue: CommercialCombinedStep25ViewModel =
  { locations: [] };
export const CommercialCombinedStep25ViewModelInitialValueDev: CommercialCombinedStep25ViewModel =
  {
    locations: [
      {
        subsidenceGroundHeaveLandslipCoverAdded: false
      }
    ]
  };

export function useCommercialCombinedStep25InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep25ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep25ViewModelInitialValueDev
        : CommercialCombinedStep25ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
