import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import {
  CommercialCombinedQuoteResultViewModel,
  mocked_CommercialCombinedQuoteResultViewModel_Quote
} from './quoteResultViewModel';
import { CommercialCombinedQuoteSubmitRequestViewModel } from './quoteSubmitRequestViewModel';
import {
  CommercialCombinedSubmitResponse,
  mocked_CommercialCombinedPortalSubmitResponse_Submit,
  mocked_CommercialCombinedBackOfficeSubmitResponse_Submit
} from './submitResponse';
import { useRouterArea } from 'common/useRouterArea';
import { useCallback } from 'react';

export function useGetDataAsync(): (guid: string) => Promise<any[]> {
  const { getAsync } = useAxiosRequestManager();
  const { urlPrefix } = useRouterArea();

  return useCallback(async (guid) => {
    const response = await getAsync<any[]>(
      `${urlPrefix}commercial-combined/${guid}`,
      undefined,
      []
    );

    return response.data || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useGetClientDataAsync(): (clientId: string) => Promise<any[]> {
  const { getAsync } = useAxiosRequestManager();
  const { urlPrefix } = useRouterArea();

  return useCallback(async (clientId) => {
    const response = await getAsync<any[]>(
      `${urlPrefix}commercial-combined/client/${clientId}`,
      undefined,
      []
    );

    return response.data || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useQuoteAsync(): (
  payload: CommercialCombinedQuoteSubmitRequestViewModel
) => Promise<CommercialCombinedQuoteResultViewModel> {
  const { postAsync } = useAxiosRequestManager();

  return async (payload) =>
    (
      await postAsync(
        `/quotes`,
        undefined,
        payload,
        mocked_CommercialCombinedQuoteResultViewModel_Quote(payload.data)
      )
    ).data!;
}

export function useSubmitAsync(): (
  guid: string | null | undefined,
  payload: CommercialCombinedQuoteSubmitRequestViewModel
) => Promise<CommercialCombinedSubmitResponse> {
  const { postAsync } = useAxiosRequestManager();
  const { urlPrefix, isBackOffice } = useRouterArea();

  return async (guid, payload) =>
    (
      await postAsync(
        `${urlPrefix}quotes/submit/${guid || ''}`,
        undefined,
        payload,
        isBackOffice
          ? mocked_CommercialCombinedBackOfficeSubmitResponse_Submit
          : mocked_CommercialCombinedPortalSubmitResponse_Submit
      )
    ).data!;
}

export function useNewFromBackOfficeSubmitAsync(): (
  brokerId: string | undefined,
  clientId: string | undefined,
  payload: CommercialCombinedQuoteSubmitRequestViewModel
) => Promise<CommercialCombinedSubmitResponse> {
  const { postAsync } = useAxiosRequestManager();
  const { urlPrefix, isBackOffice } = useRouterArea();

  return async (brokerId, clientId, payload) =>
    (
      await postAsync(
        `${urlPrefix}quotes/submit/new/${brokerId}/${clientId || ''}`,
        undefined,
        payload,
        isBackOffice
          ? mocked_CommercialCombinedBackOfficeSubmitResponse_Submit
          : mocked_CommercialCombinedPortalSubmitResponse_Submit
      )
    ).data!;
}

export const defaultDataValue = () => new Array<any>(29);

export function useGetQuoteDataAsync(): [
  boolean,
  (payload: string) => Promise<Array<any> | null>
] {
  const { loading, getAsync } = useAxiosRequestManager();
  const { urlPrefix } = useRouterArea();

  const data = [...defaultDataValue()];
  data[0] = { activity: 505 };

  return [
    loading,
    async (payload) =>
      (await getAsync(`${urlPrefix}quotes/${payload}`, undefined, data)).data!
  ];
}
