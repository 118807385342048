import {
  PropsWithChildren,
  FC,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField, useFormikContext } from 'formik';
import { IMaskInput } from 'react-imask';
import {
  customValidateDateFormatInput,
  formatDate,
  getValidDateOrNull
} from 'common/functions/Date';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import IMask from 'imask';
import { PopperProps } from '@mui/material';
import moment from 'moment';

type FormTextBoxProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      disabled?: boolean;
      minDate?: Date | null;
      maxDate?: Date | null;
      onChange?: (value: string | null | undefined) => void;
      onAccept?: (value: Date | null | undefined) => void;
    }
>;

type FormTextBoxComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormTextBox: FormTextBoxComponent<FormTextBoxProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [internalValue, setInternalValue] = useState('');
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    inputOtherWrapperClassName,
    placeholder,
    disabled,
    minDate,
    maxDate,
    children,
    ...otherProps
  } = splitProps[0];
  const [labels, others] = filterChildren(children, 'FormLabel');
  const [fieldProps, meta, { setValue }] = useField<Date | null | undefined>(
    props.name
  );
  const { validateOnChange } = useFormikContext();

  const placeholderFix = () => {
    if (placeholder === undefined) {
      return 'dd/mm/yyyy';
    }

    if (!placeholder || !placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  const autofix = useCallback(
    (type: 'd' | 'm'): boolean | 'pad' =>
      internalValue.length <= (type === 'd' ? 1 : 3) ? 'pad' : false,
    [internalValue.length]
  );

  useEffect(() => {
    setInternalValue(formatDate(fieldProps.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputContainer = useRef(null);
  const popperProps: Partial<PopperProps> = {
    anchorEl: inputContainer.current
  };

  const customInput = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat={customValidateDateFormatInput}
        value={fieldProps.value || null}
        PopperProps={popperProps}
        views={['year', 'month', 'day']}
        InputAdornmentProps={{
          className: 'position-absolute',
          style: {
            right: meta.error ? '3rem' : '1.4rem',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10
          }
        }}
        onChange={(dtStrValue) => {
          if (moment.isDate(dtStrValue)) {
            const dt = moment(dtStrValue).utc(true);
            setValue(dt.toDate(), validateOnChange);
            setInternalValue(formatDate(dt));
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={({ InputProps }) => (
          <div ref={inputContainer} className="position-relative">
            <IMaskInput
              inputRef={inputRef}
              mask="d{/}`m{/}`Y"
              blocks={{
                d: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 31,
                  maxLength: 2,
                  autofix: autofix('d'),
                  eager: true
                },
                m: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 12,
                  maxLength: 2,
                  autofix: autofix('m'),
                  eager: true
                },
                Y: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 9999
                }
              }}
              prepare={(value: string, masked: any, flags: any) => {
                if (flags?.input && flags?.raw && !masked.value && value) {
                  const firstDigit = Number(value[0]);
                  if (!isNaN(firstDigit) && firstDigit > 3) {
                    return `0${firstDigit}/`;
                  }
                }
                return value;
              }}
              autofix={true}
              value={internalValue}
              unmask={false}
              onAccept={(uValue: any, el, ev) => {
                let v = (uValue || '')?.toString() || '';

                if (
                  ev &&
                  ev.inputType &&
                  ev.inputType.startsWith('delete') &&
                  v.endsWith('/')
                ) {
                  v = v.slice(0, -1);
                }

                if (
                  ev &&
                  ev.inputType &&
                  ev.inputType.startsWith('insert') &&
                  [2, 5].some((x) => v.length === x)
                ) {
                  v += '/';
                }

                if (v.length === 10) {
                  const dtVal = getValidDateOrNull(v);
                  setValue(dtVal?.toDate(), validateOnChange);
                } else {
                  setValue(undefined, validateOnChange);
                }

                setInternalValue(v);
                if (v.endsWith('/')) {
                  setTimeout(() => {
                    inputRef.current?.setSelectionRange(30, 30);
                  }, 50);
                }
              }}
              {...{
                id,
                name: fieldProps.name,
                className: `form-control ${meta.error ? 'is-invalid' : ''}`,
                disabled,
                placeholder: placeholderFix()
              }}
            />
            {InputProps?.endAdornment}
          </div>
        )}
      />
    </LocalizationProvider>
  );

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? labels : <FormLabel htmlFor={name} {...splitProps[1]} />}
      {inputOtherWrapperClassName ? (
        <div
          className={`${inputOtherWrapperClassName} ${
            meta?.error ? 'is-invalid' : ''
          }`}
        >
          <>
            {customInput}
            {others}
          </>
        </div>
      ) : (
        <>
          {customInput}
          {others}
        </>
      )}
      <FormControlError msg={meta?.error} />
    </FormControlWrapper>
  );
};
FormTextBox.Label = FormLabel;

FormTextBox.displayName = 'FormTextBoxDate';
export default FormTextBox;
