import { currencyFormat } from 'common/functions/formats/currency';
import { coverTypeSelectionStepIndex } from 'pages/buy/CommercialCombined';
import { CommercialCombinedStep2ViewModel } from 'pages/buy/CommercialCombined/Step2.models';
import { FC, useMemo } from 'react';
import { useFormMultiStepGroupContext } from './controls/form/form-multi-step-group.hooks';

const InfoCard: FC<{ refer: boolean; price: number }> = ({ refer, price }) => {
  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  if (refer) {
    return (
      <div className="bg-light rounded-5 p-3 mb-4">
        We need some further information from you in order to provide a
        quotation. Please continue.
      </div>
    );
  }

  return (
    <div className="bg-light rounded-5 p-3 mb-4">
      <div className="text-center">
        <div className="fw-bold h1 text-secondary my-2">
          {currencyFormat(price)} <span className="small">/year</span>{' '}
        </div>
        <p className="fw-medium">
          Indication for your {step2ViewModel?.selectedCoverName} insurance
          quote
        </p>
      </div>
      <div className="border-top border-secondary border-dashed pt-3">
        <div className="small">
          <p>
            This includes IPT of {currencyFormat(price - price / 1.12)}.
            Indication of Premium has been on offered on the basis of the
            information provided by yourself or your agent. A full quotation is
            subject to satisfactory completion of the full online quotation
            form.
          </p>
        </div>
      </div>
    </div>
  );
};
InfoCard.displayName = 'InfoCard';

export default InfoCard;
