import { scrollToFirstError } from 'common/functions/ScrollToFirstError';
import { FormikProps } from 'formik';
import {
  FC,
  ForwardedRef,
  PropsWithChildren,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

export type ForwardedRefPropsHandler = {
  getValues?: () => {} | Promise<{}>;
  onBeforeNext?: (data: any[]) => boolean | Promise<boolean>;
  prevStepIndex?: (data: any[]) => number;
  nextStepIndex?: (data: any[]) => number;
  hideBackButton?: boolean;
  nextButtonText?: string;
};

export type FormMultiStepItemProps = PropsWithChildren<{
  title?: string;
  handler?: () => ForwardedRefPropsHandler | undefined;
}>;

export type ForwardedRefProps = {
  handler?: ForwardedRefPropsHandler;
};

export type FormMultiStepItemComponent = FC<FormMultiStepItemProps>;

const FormMultiStepItem: FormMultiStepItemComponent = ({ children }) => {
  return <>{children}</>;
};
FormMultiStepItem.displayName = 'FormMultiStepItem';

export default FormMultiStepItem;

export const useImperativeHandleForMultiStepItem = <T,>(
  ref: ForwardedRef<ForwardedRefProps>,
  actions?: {
    prevStepIndex?: (data: any[]) => number;
    nextStepIndex?: (data: any[]) => number;
    getValues?: (values: T) => T | Promise<T>;
    onBeforeNextAsync?: (
      data: any[],
      formikProps: FormikProps<T>
    ) => Promise<void | boolean | undefined>;
    hideBackButton?: boolean;
    nextButtonText?: string;
  }
) => {
  const formikForm = useRef<FormikProps<T>>(null);
  const [validateOnChange, setValidateOnChange] = useState(false);

  useImperativeHandle<ForwardedRefProps, ForwardedRefProps>(
    ref,
    () => ({
      handler: {
        onBeforeNext: async (data: any[]) => {
          setValidateOnChange(true);
          await formikForm.current!.validateForm();

          if (!formikForm.current!.isValid) {
            scrollToFirstError();
            return false;
          }

          if (actions?.onBeforeNextAsync) {
            const oRet = await actions.onBeforeNextAsync(
              data,
              formikForm.current!
            );
            if (oRet === false) {
              return false;
            }
          }

          return true;
        },
        getValues: () => {
          if (actions?.getValues) {
            return Promise.resolve(
              actions.getValues(formikForm.current!.values)
            );
          }

          return Promise.resolve(formikForm.current!.values);
        },
        prevStepIndex: actions?.prevStepIndex,
        nextStepIndex: actions?.nextStepIndex,
        hideBackButton: actions?.hideBackButton,
        nextButtonText: actions?.nextButtonText
      }
    }),
    [actions]
  );

  return { formikForm, validateOnChange, setValidateOnChange };
};
