import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { FormConditionalCollapse, FormRadioButtonListBoolean, FormTextBox } from 'components/controls';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep20Component,
  commercialCombinedStep20Schema,
  CommercialCombinedStep20ItemViewModel,
  CommercialCombinedStep20ViewModel
} from './Step20.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';

const CommercialCombinedStep20: CommercialCombinedStep20Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep20ViewModel>(ref);

  const { getFieldName, locationHeadingText } = usePremisesExtraQuestionsHelper<
    CommercialCombinedStep20ItemViewModel,
    CommercialCombinedStep20ViewModel
  >();
  if (!locationHeadingText) return null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep20Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <p>
          <strong>Are all the buildings at the premises to be insured:</strong>
        </p>
        <FormRadioButtonListBoolean
          name={getFieldName('heatingFrostFree')}
          label-text="Heated with a system linked to a frost-stat to maintain minimum temperature of °4C between 31st October and 31st March? Or are Pipes lagged to prevent freezing?"
        />
        <FormRadioButtonListBoolean
          name={getFieldName('portableHeaters')}
          label-text="Have you or do you intend to use, provide or store any type of portable heaters) on the premises?"
        />
        <FormConditionalCollapse name={getFieldName('portableHeaters')}>
           <FormTextBox
             name={getFieldName('portableHeatersDetails')}
             label-subElement={true}
             label-text="Please provide details"
             />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep20);
