import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading } from 'components';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean,
  FormRadioButtonListGeneric,
  FormTextBox
} from 'components/controls';
import { optionsCC } from 'config/options';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep26Component,
  commercialCombinedStep26Schema,
  CommercialCombinedStep26ViewModel
} from './Step26.models';

const FormRadioButtonList = FormRadioButtonListGeneric<number>();
const getFieldName = nameOf<CommercialCombinedStep26ViewModel>;

const CommercialCombinedStep26: CommercialCombinedStep26Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep26ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep26Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Security" />
        <p>
          <strong>
            Do the premises have the following levels of physical security that
            are in use:
          </strong>
        </p>
        <FormRadioButtonListBoolean
          name={getFieldName('windowsGrilledFittedWithLocks')}
          label-text={
            <>
              <p>
                All external entry/exit doors are fitted with at least 5 lever
                mortice deadlock complying to BS3621.
              </p>
              <p className="m-0">
                All windows at ground floor and basement levels and windows that
                are readily accessible either barred, grilled or fitted with key
                operated window locks?
              </p>
            </>
          }
          label-info={{
            header: 'Security Measures',
            modal: true,
            content:
              '<strong>A. </strong> The external doors of your premises or internal doors which provide access to any part of the buildings not occupied by you are secured with at least one of the following security measures appropriate to the door type:' +
              '<ol type=\'i\'> <li>	a mortice deadlock which has a minimum of 5 levers which conforms to British Standard BS3621 with a metal striking box plate for timber or steel framed doors;</li>' +
              '<li>	 a multi-point locking system which has a minimum of three locking  points with key operated cylinder deadlock which conforms to British Standard PAS 3621:2011 for UPVC, composite, aluminium framed and sliding doors;</li>' +
              '<li>	a high security padlock of at least British Standard BS EN 12320 security grade 3 or Central European Norm (CEN) grade 3 with a minimum shackle thickness of 10mm and manufacturer’s corresponding locking bar or keep for other doors or roller shutters manufactured to LPS 1175 Security Rating 1 or above;</li>' +
              '<li>	 two key operated security bolts fitted internally and shooting vertically one at the top and the other at the bottom of the door for double or multiple folding leaf doors,  other than where any leaf  door is required to be opened  externally , in which case appropriate locks to the standards specified in either  (i) to (iii) are in place. </li> </ol>' +
              '<strong>B </strong>. Windows/skylights capable of opening at ground floor, basement or other floor levels (which are “easily accessible”) are secured with key operated locking devices with keys removed. This requirement does not apply to windows/skylights which are protected by solid steel bars, grilles, locked gates, shutters, expanded metal or weld mesh.'
          }}
        />
        <FormConditionalCollapse
          name={getFieldName('windowsGrilledFittedWithLocks')}
          is={false}
        >
          <FormTextBox
            name={getFieldName('windowsGrilledFittedWithLocksDetails')}
            label-text="What physical security do you have?"
            label-subElement
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('hasIntruderAlarm')}
          label-text="Are the premises protected by an intruder alarm?"
        />
        <FormConditionalCollapse name={getFieldName('hasIntruderAlarm')}>
          <FormRadioButtonList
            name={getFieldName('haveIntruderAlarmTypeId')}
            label-text="Please advise type of alarm signalling"
            label-subElement
            options={optionsCC.intruderAlarmTypes}
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName(
            'haveAlarmMaintenanceContractWithCompanyAccreditedSSAIBorNSI'
          )}
          label-text="Do you have an alarm maintenance contract in force with a security company accredited by the SSAIB or NSI?"
        />
        <FormRadioButtonListBoolean
          name={getFieldName('haveSafeVaultInstalled')}
          label-text="Do you have a safe or vault installed at the premises?"
        />
        <FormConditionalCollapse name={getFieldName('haveSafeVaultInstalled')}>
          <FormTextBox
            name={getFieldName('safeVaultMake')}
            label-text="Make"
            label-subElement
          />
          <FormTextBox
            name={getFieldName('safeVaultModel')}
            label-text="Model"
            label-subElement
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep26);
