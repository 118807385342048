import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, object, SchemaOf } from 'yup';
const requiredIf = requiredIfTyped<CommercialCombinedStep22ItemViewModel>();

export interface CommercialCombinedStep22Props {
  initialValue: CommercialCombinedStep22ViewModel;
}

export type CommercialCombinedStep22Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep22Props
>;

export interface CommercialCombinedStep22ViewModel {
  locations: Array<CommercialCombinedStep22ItemViewModel>;
}

export interface CommercialCombinedStep22ItemViewModel {
  hasWholeElectricalSystemInspected?: boolean | null;
  electricalConditionReport?: boolean | null;
  portableApplianceTesting?: boolean | null;
}

const commercialCombinedStep22ItemSchema: SchemaOf<CommercialCombinedStep22ItemViewModel> =
  object({
    hasWholeElectricalSystemInspected: boolean().nullable().required().label('It'),
    electricalConditionReport: requiredIf(
      boolean().nullable().label('It'),
      'hasWholeElectricalSystemInspected'
    ),
    portableApplianceTesting: boolean().nullable().required().label('It')
  });
export const commercialCombinedStep22Schema: SchemaOf<CommercialCombinedStep22ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep22ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep22ViewModelInitialValue: CommercialCombinedStep22ViewModel =
  { locations: [] };
export const CommercialCombinedStep22ViewModelInitialValueDev: CommercialCombinedStep22ViewModel =
  {
    locations: [
      {
        hasWholeElectricalSystemInspected: true,
        electricalConditionReport: false,
        portableApplianceTesting: true
      }
    ]
  };

export function useCommercialCombinedStep22InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep22ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep22ViewModelInitialValueDev
        : CommercialCombinedStep22ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
