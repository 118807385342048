import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
// import BuyIndex from 'pages/buy';
import BackOfficeBuyIndex from 'pages/back-office/buy';
import BrokerBuyIndex from 'pages/broker/buy';
import CommercialCombinedIndex, {
  storeNameData,
  storeNameStep,
  storeQuoteData
} from 'pages/buy/CommercialCombined';
import ExamplesIndex from 'pages/Examples';
import FormExample from 'pages/FormExample';
import MultiStepExample from 'pages/MultiStepExample';
import AxiosRequestManagerProvider from 'common/axios-request-manager/AxiosRequestManagerContextProvider';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import { CommercialCombinedPayIndex } from 'pages/buy/CommercialCombined/pay/Index';
import { CommercialCombinedConfirmationIndex } from 'pages/buy/CommercialCombined/confirmation/Index';
import { CommercialCombinedReferIndex } from 'pages/buy/CommercialCombined/refer';
import { BrokerBuyReferral } from 'pages/broker/buy/Referral';
import BackOfficeRoute from 'common/auth/backOfficeRoute';
import BrokerRoute from 'common/auth/brokerRoute';
import BackOfficeNewQuoteIndex from 'pages/back-office/new-quote/buy';

moment.locale('en-GB');

export default function App() {
  return (
    <AxiosRequestManagerProvider defaultDelay={1000}>
      {/* <NavBar /> */}
      <Container bsPrefix="container-fluid">
        <div className="d-flex align-items-center mt-3">
          <div className="logo">
            <img src={require('./assets/images/cg_logo.png')} alt="CG logo" />
          </div>
          <div className="exit ms-auto">
            <a
              href="https://www.google.com"
              className="btn btn-sm p-0 border-0 text-body"
            >
              Exit
            </a>
          </div>
        </div>
      </Container>
      <div className="App mt-3">
        <Routes>
          <Route index element={<Home />} />
          {/* <Route path="/buy" element={<BuyIndex />}>
            <Route
              path="commercial-combined"
              element={<CommercialCombinedIndex />}
            />
          </Route> */}
          <Route path="/pay/:guid" element={<CommercialCombinedPayIndex />} />
          <Route
            path="/confirmation/:guid"
            element={<CommercialCombinedConfirmationIndex />}
          />
          <Route
            path="/refer/:guid"
            element={<CommercialCombinedReferIndex />}
          />
          <Route path="/examples" element={<ExamplesIndex />} />
          <Route path="/form-example" element={<FormExample />} />
          <Route path="/multi-step" element={<MultiStepExample />} />
          <Route path="/back-office" element={<BackOfficeRoute />}>
            <Route path="clean">
              <Route path="*" element={<BrokerBackOfficeClean />} />
            </Route>
            <Route path="buy" element={<BackOfficeBuyIndex />}>
              <Route path="commercial-combined">
                <Route
                  index
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
                <Route
                  path=":guid"
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
              </Route>
            </Route>
            <Route path="new-quote/buy" element={<BackOfficeNewQuoteIndex />}>
              <Route path="commercial-combined">
                <Route
                  index
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
                <Route
                  path=":brokerId/:brokerName"
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
              </Route>
            </Route>
            <Route
              path="new-quote-client/buy"
              element={<BackOfficeNewQuoteIndex />}
            >
              <Route path="commercial-combined">
                <Route
                  index
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
                <Route
                  path=":brokerId/:brokerName/:clientId"
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
                <Route
                  path=":brokerId/:brokerName/:clientId/:guid"
                  element={<CommercialCombinedIndex isBackOffice={true} />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="/broker" element={<BrokerRoute />}>
            <Route path="clean">
              <Route path="*" element={<BrokerBackOfficeClean />} />
            </Route>
            <Route path="refer/:guid" element={<BrokerBuyReferral />} />
            <Route path="buy" element={<BrokerBuyIndex />}>
              <Route path="commercial-combined">
                <Route
                  index
                  element={<CommercialCombinedIndex isBroker={true} />}
                />
                <Route
                  path=":guid"
                  element={<CommercialCombinedIndex isBroker={true} />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
    </AxiosRequestManagerProvider>
  );
}

const BrokerBackOfficeClean = () => {
  sessionStorage.removeItem(storeNameStep);
  sessionStorage.removeItem(storeNameData);
  sessionStorage.removeItem(storeQuoteData);

  window.location.href = window.location.href
    .replace('/back-office/clean/', '/back-office/')
    .replace('/broker/clean/', '/broker/');
  return null;
};
