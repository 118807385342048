import { PropsWithChildren, FC } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField } from 'formik';

type FormCheckBoxProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      checked?: boolean;
    }
>;

type FormCheckBoxComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormCheckBox: FormCheckBoxComponent<FormCheckBoxProps> = (props) => {
  const splitProps = separateFormLabelProps(props);
  const { id, name, checked, children, className, ...otherProps } =
    splitProps[0];
  const [labels, others] = filterChildren(children, 'FormLabel');
  const [{ checked: fieldChecked, ...field }, meta] = useField({
    name: props.name,
    type: 'checkbox'
  });

  return (
    <FormControlWrapper
      className={`${className ? className : ''} form-check`.trim()}
      {...otherProps}
    >
      <input
        id={id ? id : name}
        type="checkbox"
        className={`form-check-input ${meta?.error ? 'is-invalid' : ''}`.trim()}
        {...field}
        checked={checked === undefined ? fieldChecked : checked}
      />
      {labels.length ? (
        labels
      ) : (
        <FormLabel
          htmlFor={field.name}
          className="form-check-label pointer-cursor fw-normal"
          {...splitProps[1]}
        />
      )}
      {others}
      <FormControlError msg={meta?.error} />
    </FormControlWrapper>
  );
};
FormCheckBox.Label = FormLabel;

FormCheckBox.displayName = 'FormCheckBox';
export default FormCheckBox;
