import { backOfficeUrl } from 'common/useRouterArea';
import { backOfficeBaseURL } from 'config';
import { useGetLoggedUserAsync } from 'features/auth/backoffice';
import { useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import {
  storeNameBackOfficeAuth,
  useAuthBackOffice
} from './useAuthBackOffice';

const BackOfficeRoute = () => {
  const [authed, setAuthed] = useState(!!useAuthBackOffice());
  const [loaded, setLoaded] = useState(authed === true);
  const isParentBackOffice = useMatch(backOfficeUrl);
  const navigate = useNavigate();

  const getAsync = useGetLoggedUserAsync();

  useEffect(() => {
    async function checkIfLoggedInBackOffice() {
      const user = await getAsync();
      setLoaded(true);
      setAuthed(user !== null);
      if (user !== null) {
        sessionStorage.setItem(storeNameBackOfficeAuth, JSON.stringify(user));
      }
    }

    if (isParentBackOffice) {
      navigate('/', { replace: true });
    } else if (!loaded) {
      checkIfLoggedInBackOffice();
    }
  }, [isParentBackOffice, getAsync, loaded, navigate]);

  if (!loaded) {
    return <div>Authenticating...</div>;
  }

  if (!authed) {
    document.location.href = `${backOfficeBaseURL}/back-office/login`;
    return <div>Redirecting...</div>;
  }

  return <Outlet />;
};

export default BackOfficeRoute;
