import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean
} from 'components/controls';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep22Component,
  CommercialCombinedStep22ItemViewModel,
  commercialCombinedStep22Schema,
  CommercialCombinedStep22ViewModel
} from './Step22.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';

const CommercialCombinedStep22: CommercialCombinedStep22Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep22ViewModel>(ref);

  const { getFieldName, locationHeadingText } = usePremisesExtraQuestionsHelper<
    CommercialCombinedStep22ItemViewModel,
    CommercialCombinedStep22ViewModel
  >();
  if (!locationHeadingText) return null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep22Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <FormRadioButtonListBoolean
          name={getFieldName('hasWholeElectricalSystemInspected')}
          label-text="Has the whole electrical system at the premises been inspected by a NAPIT, NICEIC, ELECSA or ECA registered contractor and a satisfactory electrical condition report been issued?"
        />
        <FormConditionalCollapse name={getFieldName('hasWholeElectricalSystemInspected')}>
          <FormRadioButtonListBoolean
            name={getFieldName('electricalConditionReport')}
            label-subElement={true}
            label-text="Will you ensure that you possess a satisfactory electrical condition report issued by a registered contractor that it is never more than three years old at the commencement and throughout the currency of this insurance?"
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('portableApplianceTesting')}
          label-text="Do you undertake periodical Portable Appliance Testing?"
        />
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep22);
