import { brokerUrl } from 'common/useRouterArea';
import { brokerBaseURL } from 'config';
import { BrokerRibbon } from 'features/auth/BrokerRibbon';
import { FC, useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

const BrokerBuyIndex: FC<{}> = () => {
  const isBuyParentBroker = useMatch(`${brokerUrl}/buy`);
  const navigate = useNavigate();

  useEffect(() => {
    if (isBuyParentBroker) {
      document.location.href = `${brokerBaseURL}/broker/login`;
    }
  }, [isBuyParentBroker, navigate]);

  return (
    <div>
      <BrokerRibbon />
      {/* <div className="instantQuoteBrokerInfo">
        <div>
          <span>
            Your are on a <strong>broker portal quote process</strong> and
            currently logged in as {email}
          </span>
          <a href={`${brokerBaseURL}/broker/dashboard/`}>Return to Dashboard</a>
        </div>
      </div> */}
      <Outlet />
    </div>
  );
};

export default BrokerBuyIndex;
