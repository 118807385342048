import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep28Props {
  initialValue: CommercialCombinedStep28ViewModel;
}

export type CommercialCombinedStep28Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep28Props
>;

export interface CommercialCombinedStep28ViewModel {
  policyNumber: string;
}

export const commercialCombinedStep28Schema: SchemaOf<CommercialCombinedStep28ViewModel> =
  object({ policyNumber: string().default('').notRequired() });

export const CommercialCombinedStep28ViewModelInitialValue: CommercialCombinedStep28ViewModel =
  { policyNumber: '' };
export const CommercialCombinedStep28ViewModelInitialValueDev: CommercialCombinedStep28ViewModel =
  { policyNumber: 'ABC' };

export function useCommercialCombinedStep28InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep28ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep28ViewModelInitialValueDev
        : CommercialCombinedStep28ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
