import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep17Props {
  initialValue: CommercialCombinedStep17ViewModel;
}

export type CommercialCombinedStep17Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep17Props
>;

export interface CommercialCombinedStep17ViewModel {
  identifyTheCustomer?: boolean | null;
  reasonWhy?: string | null;
}

export const commercialCombinedStep17Schema: SchemaOf<CommercialCombinedStep17ViewModel> =
  object({
    identifyTheCustomer: boolean().nullable().required(),
    reasonWhy: requiredIf(
      string().nullable().label('It'),
      'identifyTheCustomer',
      (identifyTheCustomer: boolean) => !identifyTheCustomer
    )
  });

export const CommercialCombinedStep17ViewModelInitialValue: CommercialCombinedStep17ViewModel =
  {
    identifyTheCustomer: null
  };
export const CommercialCombinedStep17ViewModelInitialValueDev: CommercialCombinedStep17ViewModel =
  { identifyTheCustomer: true };

export function useCommercialCombinedStep17InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep17ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep17ViewModelInitialValueDev
        : CommercialCombinedStep17ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
