// @flow
import * as React from 'react';
type Props = {
  title: string;
  color?: string;
};
type State = {};
export class Heading extends React.Component<Props, State> {
  render() {
    return (
      <h2 className={`${this.props.color ? 'text' + this.props.color : ''}`}>
        {this.props.title}
      </h2>
    );
  }
}

