import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import ReferralViewModel, {
  mocked_ReferralViewModel_Get
} from './referralViewModel';
import { useRouterArea } from 'common/useRouterArea';

export function useGetReferralAsync(): (
  quoteGuid: string
) => Promise<ReferralViewModel | null> {
  const { getAsync } = useAxiosRequestManager();
  const { urlPrefix } = useRouterArea();

  return async (quoteGuid) =>
    (
      await getAsync(
        `${urlPrefix}referral/${quoteGuid}`,
        undefined,
        mocked_ReferralViewModel_Get
      )
    ).data!;
}
