import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep18Props {
  initialValue: CommercialCombinedStep18ViewModel;
}

export type CommercialCombinedStep18Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep18Props
>;

export interface CommercialCombinedStep18ViewModel {
  followingPractices?: boolean | null;
  followingPracticesDetails?: string | null;
}

export const commercialCombinedStep18Schema: SchemaOf<CommercialCombinedStep18ViewModel> =
  object({
    followingPractices: boolean().nullable().required(),
    followingPracticesDetails: requiredIf(
      string().nullable().label('It'),
      'followingPractices',
      (followingPractices: boolean) => !followingPractices
    )
  });
export const CommercialCombinedStep18ViewModelInitialValue: CommercialCombinedStep18ViewModel =
  {};
export const CommercialCombinedStep18ViewModelInitialValueDev: CommercialCombinedStep18ViewModel =
  { followingPractices: true };

export function useCommercialCombinedStep18InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep18ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep18ViewModelInitialValueDev
        : CommercialCombinedStep18ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
