import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Formik } from 'formik';
import { forwardRef, useMemo } from 'react';
import {
  CommercialCombinedStep4Component,
  commercialCombinedStep4Schema,
  CommercialCombinedStep4ViewModel
} from './Step4.models';
import { FormRadioButtonListGeneric } from 'components/controls';
import { optionsCC } from 'config/options';
import { Heading } from 'components/Heading';
import { useQuoteAsync } from 'features/commercialCombined/quote';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import {
  contactDetailsStepIndex,
  coverRequiredStepIndex,
  coverTypeSelectionStepIndex
} from './index';
import { CommercialCombinedStep2ViewModel } from './Step2.models';

const FormRadioButtonList = FormRadioButtonListGeneric<string>();

const CommercialCombinedStep4: CommercialCombinedStep4Component = (
  { initialValue },
  ref
) => {
  const quoteAsync = useQuoteAsync();
  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep4ViewModel>(ref, {
      onBeforeNextAsync: async (data: any[]) => {
        try {
          if (step2ViewModel.selectedCoverType === 'PLEL') {
            const quoteResult = await quoteAsync({
              step: 4,
              data
            });
            if (multiStepContext.quotePubSub) {
              multiStepContext.quotePubSub.setQuote(quoteResult);
            }
          }
          return true;
        } catch {
          return false;
        }
      },
      nextStepIndex: () => {
        return step2ViewModel.selectedCoverType === 'PLEL'
          ? contactDetailsStepIndex
          : coverRequiredStepIndex + 1;
      }
    });

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep4Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Cover Required" />
        <FormRadioButtonList
          id="plIndemnityLevel"
          name="plIndemnityLevel"
          label-text="Public Liability Cover Required"
          options={optionsCC.plIndemnityLevels}
        />
        <FormRadioButtonList
          id="elIndemnityLevel"
          name="elIndemnityLevel"
          label-text="Employers Liability Cover Required"
          options={optionsCC.elIndemnityLevels}
        />
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep4);
