import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className="container container-body-sm">
      <h3>Products</h3>
      <ul>
        <li>
          <Link to="/broker/buy/commercial-combined">Commercial combined</Link>
        </li>
      </ul>
    </div>
  );
}
