import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { excessSchema } from 'features/commercialCombined/commercialCombinedExcessViewModel';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, number, object, SchemaOf } from 'yup';

export interface CommercialCombinedStep1Props {
  initialValue: CommercialCombinedStep1ViewModel;
}

export type CommercialCombinedStep1Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep1Props
>;

export interface CommercialCombinedStep1ViewModel {
  activity?: number | null;
}

export const commercialCombinedStep1Schema: SchemaOf<CommercialCombinedStep1ViewModel> =
  object().shape({
    refer: boolean().default(false).required(),
    activity: number()
      .nullable()
      .required()
      .label('What is your business activity'),

    quotePrice: number().nullable(),
    excesses: array().of(excessSchema).nullable().optional()   
  });

export const CommercialCombinedStep1ViewModelInitialValue: CommercialCombinedStep1ViewModel =
  {
    activity: null
  };
export const CommercialCombinedStep1ViewModelInitialValueDev: CommercialCombinedStep1ViewModel =
  {
    activity: 2
  };

export function useCommercialCombinedStep1InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep1ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep1ViewModelInitialValueDev
        : CommercialCombinedStep1ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, thisStep === currentStep]);
}
