import CircularProgress from '@mui/material/CircularProgress';
import { Heading } from 'components';
import { brokerBaseURL } from 'config';
import { BrokerRibbon } from 'features/auth/BrokerRibbon';
import { useGetReferralAsync } from 'features/commercialCombined/referral';
import ReferralViewModel from 'features/commercialCombined/referralViewModel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const BrokerBuyReferral = () => {
  const { guid } = useParams();
  const getReferralAsync = useGetReferralAsync();
  const [referralDetails, setReferralDetails] =
    useState<ReferralViewModel | null>(null);

  useEffect(() => {
    async function loadAsync() {
      setReferralDetails(await getReferralAsync(guid || ''));
    }
    loadAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrokerRibbon />
      <div className="container container-body-md mt-4">
        <Heading title="Thank You for Your Recent Submission" />
        {referralDetails === null && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
        {referralDetails && (
          <>
            <p>
              The risk is now with our team for final review and completion of
              relevant underwriting checks.
            </p>
            <p>
              If you should require an immediate response please call the team
              on 0203 9464 210.
            </p>
            <p>
              Or alternatively email{' '}
              <a href="mailto:londonmarket@cginsurance.com">
                londonmarket@cginsurance.com
              </a>
            </p>
            <p>Reference: {referralDetails.quoteRef}</p>
            <div className="text-center">
              <a
                className="btn btn-primary"
                href={`${brokerBaseURL}/broker/dashboard/`}
              >
                Return to broker Dashboard
              </a>
              {/* <button className="btn btn-primary">Return to Broker Portal Dashboard</button> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};
