import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, number, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep19Props {
  initialValue: CommercialCombinedStep19ViewModel;
}

export type CommercialCombinedStep19Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep19Props
>;

export interface CommercialCombinedStep19ViewModel {
  locations: Array<CommercialCombinedStep19ItemViewModel>;
}

export interface CommercialCombinedStep19ItemViewModel {
  buildingMaterial?: boolean | null;
  buildingMaterialDetails?: string | null;
  maintained?: boolean | null;
  maintainedDetails?: string | null;
  categoryListed?: boolean | null;
  premisesCategoryId?: number | null;
  heatedSolelyBy?: boolean | null;
  heatedSolelyByDetails?: string | null;
  selfContained?: boolean | null;
  selfContainedDetails?: string | null;
  flatRoof?: boolean | null;
  flatRoofPercentage?: number | null;
}

const commercialCombinedStep19ItemSchema: SchemaOf<CommercialCombinedStep19ItemViewModel> =
  object({
    buildingMaterial: boolean().nullable().required().label('It'),
    buildingMaterialDetails: requiredIf(
      string().nullable().label('It'),
      'buildingMaterial',
      (buildingMaterial: boolean) => !buildingMaterial
    ),
    maintained: boolean().nullable().required().label('It'),
    maintainedDetails: requiredIf(
      string().nullable().label('It'),
      'maintained ',
      (maintained: boolean) => !maintained
    ),
    categoryListed: boolean().nullable().required().label('It'),
    premisesCategoryId: requiredIf(
      number().nullable().label('It'),
      'categoryListed'
    ),
    heatedSolelyBy: boolean().nullable().required().label('It'),
    heatedSolelyByDetails: requiredIf(
      string().nullable().label('It'),
      'heatedSolelyBy',
      (heatedSolelyBy: boolean) => !heatedSolelyBy
    ),
    selfContained: boolean().nullable().required().label('It'),
    selfContainedDetails: requiredIf(
      string().nullable().label('It'),
      'selfContained',
      (selfContained: boolean) => !selfContained
    ),
    flatRoof: boolean().nullable().required().label('It'),
    flatRoofPercentage: requiredIf(number().nullable().label('It'), 'flatRoof')
  });

export const commercialCombinedStep19Schema: SchemaOf<CommercialCombinedStep19ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep19ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep19ViewModelInitialValue: CommercialCombinedStep19ViewModel =
  { locations: [] };
export const CommercialCombinedStep19ViewModelInitialValueDev: CommercialCombinedStep19ViewModel =
  {
    locations: [
      {
        buildingMaterial: true,
        maintained: true,
        categoryListed: false,
        heatedSolelyBy: true,
        selfContained: true,
        flatRoof: false,
      }
    ]
  };

export function useCommercialCombinedStep19InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep19ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];
    
    return {
      ...(devForcePp
        ? CommercialCombinedStep19ViewModelInitialValueDev
        : CommercialCombinedStep19ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
