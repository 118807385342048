function isHidden(el: HTMLElement): boolean {
  return window.getComputedStyle(el).display === 'none';
}

function cumulativeOffset(element: any) {
  let top = 0;
  let left = 0;
  if (element) {
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);
  }

  return {
    top: top,
    left: left
  };
}

export function recalculateTargets(
  container: HTMLElement,
  element: Element,
  offset: number
) {
  return (
    cumulativeOffset(element).top - cumulativeOffset(container).top + offset
  );
}

function findAncestor(el: HTMLElement | null, cls: string) {
  while ((el = el?.parentElement || null) && !el.classList.contains(cls));
  return el;
}

export function scrollToFirstError(
  container?: HTMLElement,
  offset?: number,
  errorElementClassName?: string,
  reducer?: null | ((hel: HTMLElement) => HTMLElement | null),
  setTimeoutInterval?: number
) {
  const elements = document.getElementsByClassName(
    errorElementClassName || 'invalidErrorMsg'
  );
  offset = offset || -10;
  container = container || document.body;
  setTimeoutInterval = setTimeoutInterval || 10;

  if (reducer === undefined) {
    reducer = (rel: HTMLElement) => findAncestor(rel, 'errorParentWrapper');
  }

  for (let i = 0; i < elements.length; i++) {
    let hel: HTMLElement | null = elements[i] as HTMLElement;
    if (hel && !isHidden(hel)) {
      if (reducer !== null) {
        hel = reducer(hel);
      }

      if (hel) {
        setTimeout(() => {
          window.scrollTo({
            top: recalculateTargets(container!, hel!, offset!),
            behavior: 'smooth'
          });
        }, setTimeoutInterval!);
      }
      return;
    }
  }
}

export function scrollToTop(
  container?: HTMLElement,
  clearActiveElement: boolean = true
) {
  container = container || document.documentElement;
  container.scrollTop = 0;

  if (clearActiveElement) {
    if (document.activeElement && document.activeElement !== document.body) {
      const activeHtmlElement = document.activeElement as HTMLElement;
      if (activeHtmlElement) {
        activeHtmlElement.blur();
      }
    }
  }
}
