import { Add, Check } from '@mui/icons-material';
import { FormCheckBoxListItemModel } from 'components/controls/form/form-check-box-list';
import { FC, useRef } from 'react';

const CommercialCombinedStep2OptionItemGeneric = <
  T extends string | number
>() => {
  const Instance: FC<{
    item: FormCheckBoxListItemModel<T>;
    extra?: string;
    disabled?: boolean;
  }> = ({ item, extra, disabled }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const inputToggle = () => {
      if (inputRef && inputRef.current) {
        inputRef.current.click();
      }
    };

    return (
      <div
        className={`d-flex mt-3 p-0 border rounded-5 customCheck ${
          item.hasError ? 'is-invalid' : ''
        } ${
          disabled
            ? 'border-grey bg-light'
            : item.isChecked
            ? 'border-primary'
            : 'border-grey'
        }`.trim()}
        role="button"
        style={{ cursor: disabled ? 'default' : undefined }}
        onClick={() => inputToggle()}
      >
        <div className="col p-4">
          <p className={`h6 m-0 ${disabled ? 'color-dark' : ''}`}>
            {item.option.text}
          </p>
          {extra ? <p className="small m-0 text-muted">{extra}</p> : null}
        </div>
        <div className="col-auto text-center p-0 d-inline-flex">
          <div
            className="pe-4"
            style={{
              display: 'flex',
              width: 'min-content',
              height: '100%',
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            <input
              ref={inputRef}
              type="checkbox"
              value={item.option.value}
              checked={item.isChecked}
              className="d-none"
              disabled={disabled}
              {...item.field}
            />
            <label
              className={`customCheckIcon ${disabled ? 'disabled' : ''} ${
                item.isChecked ? 'bg-primary' : ''
              }`}
            >
              {!item.isChecked && (
                <Add
                  className={disabled ? 'text-white' : 'text-secondary'}
                  sx={{ fontSize: 16 }}
                />
              )}
              {item.isChecked && (
                <Check className="text-white" sx={{ fontSize: 16 }} />
              )}
            </label>
          </div>
        </div>
      </div>
    );
  };

  return Instance;
};

export default CommercialCombinedStep2OptionItemGeneric;
