export type addPrefixToObject<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
};

type ObjectWithRestProperties<BT, T extends BT> = Omit<T, keyof BT>;

export function getSeparationFunction<BT, P extends string>(
  prefix: string
): <T extends addPrefixToObject<BT, P>>(
  obj: T
) => [ObjectWithRestProperties<addPrefixToObject<BT, P>, T>, BT] {
  return <T extends addPrefixToObject<BT, P>>(obj: T) => {
    const objRet = Object.assign({}, obj);
    const objLabel: Record<string, any> = {};
    for (const key in obj) {
      if (key.toString().startsWith(prefix)) {
        objLabel[key.toString().substring(prefix.length)] = obj[key];
        delete objRet[key];
      }
    }
    return [
      objRet as ObjectWithRestProperties<addPrefixToObject<BT, P>, T>,
      objLabel as BT
    ];
  };
}
