import { CommercialCombinedExcessViewModel } from './commercialCombinedExcessViewModel';

export interface CommercialCombinedQuoteResultViewModel {
  refer: boolean;
  quotePrice?: number | null;
  excesses?: Array<CommercialCombinedExcessViewModel> | null;
  guid?: string | null;
  paymentRef?: string | null;
}

export const mocked_CommercialCombinedQuoteResultViewModel_Quote: (
  data: any[]
) => CommercialCombinedQuoteResultViewModel = () => {
  return {
    refer: false,
    quotePrice: 155.43,
    excesses: [
      { name: 'Excess 1', value: 249 },
      { name: 'Excess 2', value: 250 },
      { name: 'Excess 3', value: 251 }
    ]
  };
};
