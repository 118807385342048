import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormCheckBox,
  FormConditionalCollapse,
  FormTextBox
} from 'components/controls';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep12Component,
  commercialCombinedStep12Schema,
  CommercialCombinedStep12ViewModel
} from './Step12.models';

const getFieldName = nameOf<CommercialCombinedStep12ViewModel>;

const CommercialCombinedStep12: CommercialCombinedStep12Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep12ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep12Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Risk Details" />
        <SubHeading title="Do you undertake any work involving" />
        <div className="form-group mb-4">
          <FormCheckBox
            name={getFieldName('radioactiveSubstancesOrDevices')}
            label-text="radioactive substances or devices"
          />
          <FormConditionalCollapse
            name={getFieldName('radioactiveSubstancesOrDevices')}
          >
            <FormTextBox
              name={getFieldName('radioactiveSubstancesOrDevicesDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('explosiveSubstances')}
            label-text="explosive substances"
          />
          <FormConditionalCollapse name={getFieldName('explosiveSubstances')}>
            <FormTextBox
              name={getFieldName('explosiveSubstancesDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('asbestosOrSilica')}
            label-text="asbestos or silica or materials containing these substances"
          />
          <FormConditionalCollapse name={getFieldName('asbestosOrSilica')}>
            <FormTextBox
              name={getFieldName('asbestosOrSilicaDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('toxicOrHazardousChemicals')}
            label-text="toxic or hazardous chemicals"
          />
          <FormConditionalCollapse
            name={getFieldName('toxicOrHazardousChemicals')}
          >
            <FormTextBox
              name={getFieldName('toxicOrHazardousChemicalsDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('noiseLevelInExcess85db')}
            label-text="processes involving a noise level in excess of 85db"
          />
          <FormConditionalCollapse
            name={getFieldName('noiseLevelInExcess85db')}
          >
            <FormTextBox
              name={getFieldName('noiseLevelInExcess85dbDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('workOutsideUK')}
            label-text="work outside of the United Kingdom"
          />
          <FormConditionalCollapse name={getFieldName('workOutsideUK')}>
            <FormTextBox
              name={getFieldName('workOutsideUKDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('workInAirportsRailways')}
            label-text="work in, on or about aircraft/airports,railways or quarries"
          />
          <FormConditionalCollapse
            name={getFieldName('workInAirportsRailways')}
          >
            <FormTextBox
              name={getFieldName('workInAirportsRailwaysDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('workInAboutVesselsDamsDocks')}
            label-text="work in, on or about vessels, dams, reservoirs, docks, harbours"
          />
          <FormConditionalCollapse
            name={getFieldName('workInAboutVesselsDamsDocks')}
          >
            <FormTextBox
              name={getFieldName('workInAboutVesselsDamsDocksDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('workOffshoreInstallations')}
            label-text="work in, on or about offshore installations / oil refineries/oil, gas, nuclear power Stations or petrol storage depots"
          />
          <FormConditionalCollapse
            name={getFieldName('workOffshoreInstallations')}
          >
            <FormTextBox
              name={getFieldName('workOffshoreInstallationsDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('undergroundWorkInExcess1Meter')}
            label-text="underground work in excess of 1 metre"
          />
          <FormConditionalCollapse
            name={getFieldName('undergroundWorkInExcess1Meter')}
          >
            <FormTextBox
              name={getFieldName('undergroundWorkInExcess1MeterDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('steelErectionScaffolding')}
            label-text="steel erection, scaffolding, demolition or underwater work"
          />
          <FormConditionalCollapse
            name={getFieldName('steelErectionScaffolding')}
          >
            <FormTextBox
              name={getFieldName('steelErectionScaffoldingDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('workAtHeightExceeding10Meters')}
            label-text="any work at a height exceeding 10 metres"
          />
          <FormConditionalCollapse
            name={getFieldName('workAtHeightExceeding10Meters')}
          >
            <FormTextBox
              name={getFieldName('workAtHeightExceeding10MetersDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
        </div>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep12);
