import { Heading } from 'components';
import { isDevMode, stripePK } from 'config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe, StripeError } from '@stripe/stripe-js';
import { MouseEvent } from 'react';
import {
  useGetPaymentAsync,
  usePayAsync
} from 'features/commercialCombined/payment';
import CommercialCombinedIndicator from '../Indicator';
import { FormMultiStepGroupContextProps } from 'components/controls/form/form-multi-step-group.models';
import { CommercialCombinedQuoteResultViewModel } from 'features/commercialCombined/quoteResultViewModel';
import { CommercialCombinedStep2ViewModel, CoverTypes } from '../Step2.models';
import { PaymentViewModel } from 'features/commercialCombined/paymentViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';

const stripe = loadStripe(stripePK, { locale: 'en-GB' });

const CheckoutForm = (props: { data: PaymentViewModel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState<StripeError | null>(null);
  const navigate = useNavigate();
  const payAsync = usePayAsync();
  const { guid } = useParams();
  const [loading, setLoading] = useState(false);

  const style = useMemo(
    () => ({
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }),
    []
  );

  if (!guid) return null;

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setErrors(null);
    try {
      const paymentResult = await stripe.confirmCardPayment(
        props.data.paymentRef!,
        {
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: {
              name: props.data.clientName,
              address: {
                postal_code: props.data.postCode
              }
            }
          }
        }
      );
      if (paymentResult.error) {
        setErrors(paymentResult.error);
        return;
      }
      if (paymentResult.paymentIntent?.status !== 'succeeded') {
        return;
      }

      await payAsync(guid, paymentResult.paymentIntent!.id);
      navigate(`/confirmation/${guid}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        open={loading}
        sx={{ zIndex: 10, color: '#fff', position: 'absolute' }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mb-4">
        <div className="p-1 border border-primary rounded-1">
          <CardElement options={{ hidePostalCode: true, style }} />
        </div>
        {errors && <div className="text-danger">{errors.message}</div>}
      </div>
      <div className="mb-4 text-center d-flex flex-column">
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Pay
        </button>
      </div>
    </>
  );
};

export const CommercialCombinedPayIndex = () => {
  const { guid } = useParams();
  const navigate = useNavigate();
  const getPaymentAsync = useGetPaymentAsync();
  const [response, setResponse] = useState<{
    data?: PaymentViewModel;
    loading: boolean;
  }>({ loading: true });

  useEffect(() => {
    async function get() {
      const response = await getPaymentAsync(guid || '');
      setResponse({ data: response, loading: true });
      setQuoteResult({ refer: false, quotePrice: response.quotePrice });
      setTimeout(() => {
        setResponse((v) => ({ data: v.data, loading: false }));
      }, 500);
    }

    if (!response.data?.paymentRef) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [quoteResult, setQuoteResult] =
    useState<CommercialCombinedQuoteResultViewModel>({
      refer: false,
      quotePrice: null
    });

  const getQuote = useCallback<() => CommercialCombinedQuoteResultViewModel>(
    () => quoteResult,
    [quoteResult]
  );

  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => ({
      coverTypeIds: response.data?.coverTypeIds || [1],
      selectedCoverType:
        response.data?.coverTypeIds.length === 1
          ? response.data.coverTypeIds[0] === CoverTypes.PLEL
            ? 'PLEL'
            : 'PC'
          : 'CC',
      selectedCoverName:
        response.data?.coverTypeIds.length === 1
          ? response.data?.coverTypeIds[0] === CoverTypes.PLEL
            ? 'Public & Employers Liability'
            : 'Property & Contents'
          : response.data?.coverTypeIds.length === 2
          ? 'Commercial Combined'
          : undefined
    }),
    [response.data?.coverTypeIds]
  );

  const contextProps: FormMultiStepGroupContextProps = useMemo(
    () => ({
      data: [null, step2ViewModel],
      setData: () => {},
      stepItems: [],
      activeStep: { type: '', props: { title: '' }, key: null },
      step: -1,
      selectedIndexIsFirst: false,
      selectedIndexIsLast: false,
      stepChanging: () => {},
      quotePubSub: {
        getQuote,
        setQuote: () => {},
        subscribeQuote: () => () => {}
      },

      goToStepIndex: () => {},
      prevStep: () => {},
      nextStep: () => {},
      finish: () => {},
      startNavigating: () => {},
      finishNavigating: () => {},
      navigating: false
    }),
    [getQuote, step2ViewModel]
  );

  if (!guid) {
    navigate('/');
    return null;
  }

  return (
    <>
      <CommercialCombinedIndicator
        titles={['']}
        step={28}
        totalSteps={29}
        contextProps={contextProps}
      />
      <div className="container container-body-sm">
        {isDevMode && (
          <div className="alert alert-info">
            <h4 className="text-center">No payments will be taken</h4>
            <div className="small">
              <p className="mb-0">
                <strong>Test data:</strong>
              </p>
              <p className="mb-0">Card: 4242 4242 4242 4242</p>
              <p className="mb-0">Date: 04/24</p>
              <p className="mb-0">CVC: 242</p>
            </div>
          </div>
        )}
        <Heading title="Payment" />
        {response.loading && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
        {!response.loading && response.data && (
          <Elements stripe={stripe}>
            <CheckoutForm data={response.data} />
          </Elements>
        )}
      </div>
    </>
  );
};
