export const postcodeResults = {
  Result: '1',
  ErrorText: '',
  Item: [
    {
      '@value': '1',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Thai Square City, 136-138 Minories, London',
      Key: '8189887.1',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '1',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '2',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Clegg Gifford Insurance, 128-129 Minories, London',
      Key: '8189888',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '3',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Clarendon Serviced Apartments, 122 Minories, London',
      Key: '8189883.1',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '1',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '4',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Centre Point Software, 122 Minories, London',
      Key: '8189880',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '5',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Cole Group, 122 Minories, London',
      Key: '51283472',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '6',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Capricorn Shipbrokers Ltd, 123 Minories, London',
      Key: '51456408',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '7',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Cibsys Ltd, 123 Minories, London',
      Key: '8189873',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '8',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'International Association of Dry Cargo Shipowners, 123 Minories, London',
      Key: '50914735',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    },
    {
      '@value': '9',
      Postcode: 'EC3N 1NT',
      PostcodeFrom: '',
      List: 'Mayfair Chippy, 140 Minories, London',
      Key: '8189881',
      CountryISO: 'GBR',
      MultipleResidencyIndicator: '',
      NotYetBuilt: '0',
      BusinessUsed: '0',
      DXNumber: '',
      DXExchange: '',
      DXOrganisation: '',
      DXProfession: ''
    }
  ]
};
