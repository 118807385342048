import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { SubHeading } from 'components';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean,
  FormRadioButtonListGeneric,
  FormTextarea
} from 'components/controls';
import FormTextBoxPercentage from 'components/controls/form/form-text-box-percentage';
import { Heading } from 'components/Heading';
import { optionsCC } from 'config/options';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep19Component,
  CommercialCombinedStep19ItemViewModel,
  commercialCombinedStep19Schema,
  CommercialCombinedStep19ViewModel
} from './Step19.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';
import {
  coverTypeSelectionStepIndex,
  operationsStepIndexBegin,
  premisesExtraQuestionsStepIndexBegin,
  premisesExtraQuestionsStepIndexEnd
} from '.';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { CommercialCombinedStep2ViewModel } from './Step2.models';

const FormRadioButtonList = FormRadioButtonListGeneric<number>();

const CommercialCombinedStep19: CommercialCombinedStep19Component = (
  { initialValue },
  ref
) => {
  const { getFieldName, locationHeadingText, goPrevNextAndReturnIfMoved } =
    usePremisesExtraQuestionsHelper<
      CommercialCombinedStep19ItemViewModel,
      CommercialCombinedStep19ViewModel
    >();

  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel: CommercialCombinedStep2ViewModel =
    multiStepContext.data[coverTypeSelectionStepIndex];

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep19ViewModel>(
      ref,
      {
        prevStepIndex: (data) =>
          goPrevNextAndReturnIfMoved('prev', data)
            ? premisesExtraQuestionsStepIndexEnd
            : step2ViewModel.selectedCoverType === 'PC'
            ? operationsStepIndexBegin - 1
            : premisesExtraQuestionsStepIndexBegin - 1
      }
    );

  if (!locationHeadingText) return null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep19Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <p>
          <strong>Are all the buildings at the premises to be insured:</strong>
        </p>
        <FormRadioButtonListBoolean
          name={getFieldName('buildingMaterial')}
          label-text="Built of brick, stone or concrete and roofed with slates, tiles, concrete, metal or asbestos?"
        />
        <FormConditionalCollapse
          is={false}
          name={getFieldName('buildingMaterial')}
        >
          <FormTextarea
            name={getFieldName('buildingMaterialDetails')}
            label-subElement={true}
            label-text="Please provide details"
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('maintained')}
          label-text="In a good state of repair and maintained regularly?"
        />
        <FormConditionalCollapse is={false} name={getFieldName('maintained')}>
          <FormTextarea
            name={getFieldName('maintainedDetails')}
            label-subElement={true}
            label-text="Please provide details"
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('categoryListed')}
          label-text="Grade 1, Grade 2* and Grade 2 or Category A, B, or C listed?"
        />
        <FormConditionalCollapse name={getFieldName('categoryListed')}>
          <FormRadioButtonList
            name={getFieldName('premisesCategoryId')}
            label-text="Please select"
            label-subElement
            options={optionsCC.premisesCategories}
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('heatedSolelyBy')}
          label-text="Heated solely by electricity or mains gas?"
        />
        <FormConditionalCollapse
          is={false}
          name={getFieldName('heatedSolelyBy')}
        >
          <FormTextarea
            name={getFieldName('heatedSolelyByDetails')}
            label-subElement={true}
            label-text="Please provide details"
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('selfContained')}
          label-text="Entirely self-contained with its own means of access?"
        />
        <FormConditionalCollapse
          is={false}
          name={getFieldName('selfContained')}
        >
          <FormTextarea
            name={getFieldName('selfContainedDetails')}
            label-subElement={true}
            label-text="Please provide details"
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('flatRoof')}
          label-text="Constructed with a flat roof (other than concrete) and covered with felt?"
        />
        <FormConditionalCollapse name={getFieldName('flatRoof')}>
          <FormTextBoxPercentage
            name={getFieldName('flatRoofPercentage')}
            label-subElement={true}
            label-text="What percentage of the total roof area is flat?"
            label-info={{
              header: 'What percentage of the total roof area is flat?',
              content: 'What percentage of the total roof area is flat?'
            }}
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep19);
