import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep14Props {
  initialValue: CommercialCombinedStep14ViewModel;
}

export type CommercialCombinedStep14Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep14Props
>;

export interface CommercialCombinedStep14ViewModel {
  declarationBankrupt: boolean;
  declarationBankruptDetails?: string | null;
  becomeInsolvent: boolean;
  becomeInsolventDetails?: string | null;
  liquidation: boolean;
  liquidationDetails?: string | null;
  ccj: boolean;
  ccjDetails?: string | null;
  criminalOffence: boolean;
  criminalOffenceDetails?: string | null;
  disqualified: boolean;
  disqualifiedDetails?: string | null;
  healthAndSafetyBreach: boolean;
  healthAndSafetyBreachDetails?: string | null;
  insuranceDeclined: boolean;
  insuranceDeclinedDetails?: string | null;
  legalDisputes: boolean;
  legalDisputesDetails?: string | null;
  changedName: boolean;
  changedNameDetails?: string | null;
  changePerson: boolean;
  changePersonDetails?: string | null;
}

export const commercialCombinedStep14Schema: SchemaOf<CommercialCombinedStep14ViewModel> =
  object({
    declarationBankrupt: boolean().defined(),
    declarationBankruptDetails:
      requiredIfTyped<CommercialCombinedStep14ViewModel>()(
        string().nullable().optional().label('It'),
        'declarationBankrupt'
      ),
    becomeInsolvent: boolean().defined(),
    becomeInsolventDetails:
      requiredIfTyped<CommercialCombinedStep14ViewModel>()(
        string().nullable().optional().label('It'),
        'becomeInsolvent'
      ),
    liquidation: boolean().defined(),
    liquidationDetails: requiredIfTyped<CommercialCombinedStep14ViewModel>()(
      string().nullable().optional().label('It'),
      'liquidation'
    ),
    ccj: boolean().defined(),
    ccjDetails: requiredIfTyped<CommercialCombinedStep14ViewModel>()(
      string().nullable().optional().label('It'),
      'ccj'
    ),
    criminalOffence: boolean().defined(),
    criminalOffenceDetails:
      requiredIfTyped<CommercialCombinedStep14ViewModel>()(
        string().nullable().optional().label('It'),
        'criminalOffence'
      ),
    disqualified: boolean().defined(),
    disqualifiedDetails:
      requiredIfTyped<CommercialCombinedStep14ViewModel>()(
        string().nullable().optional().label('It'),
        'disqualified'
      ),
    healthAndSafetyBreach: boolean().defined(),
    healthAndSafetyBreachDetails:
      requiredIfTyped<CommercialCombinedStep14ViewModel>()(
        string().nullable().optional().label('It'),
        'healthAndSafetyBreach'
      ),
    insuranceDeclined: boolean().defined(),
    insuranceDeclinedDetails:
      requiredIfTyped<CommercialCombinedStep14ViewModel>()(
        string().nullable().optional().label('It'),
         'insuranceDeclined'
    ),
    legalDisputes: boolean().defined(),
    legalDisputesDetails: requiredIfTyped<CommercialCombinedStep14ViewModel>()(
      string().nullable().optional().label('It'),
      'legalDisputes'
    ),
    changedName: boolean().defined(),
    changedNameDetails: requiredIfTyped<CommercialCombinedStep14ViewModel>()(
      string().nullable().optional().label('It'),
      'changedName'
    ),
    changePerson: boolean().defined(),
    changePersonDetails: requiredIfTyped<CommercialCombinedStep14ViewModel>()(
      string().nullable().optional().label('It'),
      'changePerson'
    )
  });

export const CommercialCombinedStep14ViewModelInitialValue: CommercialCombinedStep14ViewModel =
  {
    declarationBankrupt: false,
    declarationBankruptDetails: '',
    becomeInsolvent: false,
    becomeInsolventDetails: '',
    liquidation: false,
    liquidationDetails: '',
    ccj: false,
    ccjDetails: '',
    criminalOffence: false,
    criminalOffenceDetails: '',
    disqualified: false,
    disqualifiedDetails: '',
    healthAndSafetyBreach: false,
    healthAndSafetyBreachDetails: '',
    insuranceDeclined: false,
    insuranceDeclinedDetails: '',
    legalDisputes: false,
    legalDisputesDetails: '',
    changedName: false,
    changedNameDetails: '',
    changePerson: false,
    changePersonDetails: ''
  };
export const CommercialCombinedStep14ViewModelInitialValueDev: CommercialCombinedStep14ViewModel =
  {
    declarationBankrupt: false,
    declarationBankruptDetails: '',
    becomeInsolvent: false,
    becomeInsolventDetails: '',
    liquidation: false,
    liquidationDetails: '',
    ccj: false,
    ccjDetails: '',
    criminalOffence: false,
    criminalOffenceDetails: '',
    disqualified: false,
    disqualifiedDetails: '',
    healthAndSafetyBreach: false,
    healthAndSafetyBreachDetails: '',
    insuranceDeclined: false,
    insuranceDeclinedDetails: '',
    legalDisputes: false,
    legalDisputesDetails: '',
    changedName: false,
    changedNameDetails: '',
    changePerson: false,
    changePersonDetails: ''
  };

export function useCommercialCombinedStep14InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep14ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep14ViewModelInitialValueDev
        : CommercialCombinedStep14ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
