import nameOf from 'common/functions/NameOfHelper';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import {
  postcodeLookupSchema,
  PostcodeLookupViewModel,
  postcodeLookupViewModelInitialValue,
  postcodeLookupViewModelInitialValueDev1
} from 'components/postcode-lookup.models';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useCallback, useMemo } from 'react';
import { object, SchemaOf, array, boolean, number } from 'yup';
import { locationsStepIndex } from '.';

export interface CommercialCombinedStep5Props {
  initialValue: CommercialCombinedStep5ViewModel;
}

export type CommercialCombinedStep5Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep5Props
>;

export interface CommercialCombinedStep5ViewModel {
  locations: Array<PostcodeLookupViewModel>;
  premisesExtraQuestionsLocationIndex: number;
  operatesAnotherLocation?: boolean | null;
  addAnother?: boolean | null;
}

export const maxNumberOfLocations = 5;

export const commercialCombinedStep5Schema: SchemaOf<CommercialCombinedStep5ViewModel> =
  object().shape({
    locations: array()
      .of(postcodeLookupSchema)
      .min(1)
      .max(maxNumberOfLocations),
    premisesExtraQuestionsLocationIndex: number().default(0).required(),
    operatesAnotherLocation: boolean()
      .nullable()
      .required()
      .label('Do you operate from more than one location'),
    addAnother: boolean()
      .nullable()
      .notRequired()
      .when([nameOf<CommercialCombinedStep5ViewModel>('locations')], {
        is: (locations: Array<PostcodeLookupViewModel>) =>
          locations.length > 1 && locations.length < maxNumberOfLocations,
        then: (schema) => schema.required()
      })
      .label('Do you want to add another location')
  });

const commercialCombinedStep5ViewModelInitialValue: CommercialCombinedStep5ViewModel =
  {
    locations: [postcodeLookupViewModelInitialValue],
    premisesExtraQuestionsLocationIndex: 0,
    operatesAnotherLocation: null,
    addAnother: null
  };
export const commercialCombinedStep5ViewModelInitialValueDev: CommercialCombinedStep5ViewModel =
  {
    locations: [postcodeLookupViewModelInitialValueDev1],
    premisesExtraQuestionsLocationIndex: 0,
    operatesAnotherLocation: true,
    addAnother: false
  };

export function useCommercialCombinedStep5InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  const isThisStep = useMemo(() => {
    return thisStep === currentStep;
  }, [thisStep, currentStep]);

  return useMemo<CommercialCombinedStep5ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? commercialCombinedStep5ViewModelInitialValueDev
        : commercialCombinedStep5ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isThisStep]);
}

export const usePremisesExtraQuestionsHelper = <
  I,
  T extends { locations: Array<I> }
>() => {
  const multiStepContext = useFormMultiStepGroupContext();

  const { viewModel, currentLocationIndex, hasPrevLocation, hasNextLocation } =
    useMemo(() => {
      const viewModel: CommercialCombinedStep5ViewModel | null | undefined =
        multiStepContext.data[locationsStepIndex];

      const currentLocationIndex =
        viewModel?.premisesExtraQuestionsLocationIndex || 0;

      return {
        viewModel,
        currentLocationIndex,
        hasPrevLocation: currentLocationIndex > 0,
        hasNextLocation:
          currentLocationIndex < (viewModel?.locations.length || 0) - 1
      };
    }, [multiStepContext.data]);

  const goPrevNextAndReturnIfMoved = useCallback(
    (action: 'prev' | 'next', data: any[]): boolean => {
      if (
        viewModel &&
        ((action === 'prev' && hasPrevLocation) ||
          (action === 'next' && hasNextLocation))
      ) {
        const newViewModel = { ...viewModel };

        if (action === 'prev')
          newViewModel.premisesExtraQuestionsLocationIndex--;
        else newViewModel.premisesExtraQuestionsLocationIndex++;

        multiStepContext.setData(() => {
          data[locationsStepIndex] = newViewModel;
          return data;
        });
        return true;
      }
      return false;
    },
    [multiStepContext, viewModel, hasPrevLocation, hasNextLocation]
  );

  return {
    locationHeadingText: viewModel
      ? `Location ${currentLocationIndex + 1}: ${
          viewModel.locations[currentLocationIndex].postcode
        }`
      : null,
    hasPrevLocation,
    hasNextLocation,
    getFieldName: (name: Extract<keyof I, string>) =>
      `${nameOf<T>('locations')}[${currentLocationIndex}].${nameOf<I>(name)}`,
    goPrevNextAndReturnIfMoved
  };
};
