import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, object, SchemaOf, string } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep24ItemViewModel>();

export interface CommercialCombinedStep24Props {
  initialValue: CommercialCombinedStep24ViewModel;
}

export type CommercialCombinedStep24Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep24Props
>;

export interface CommercialCombinedStep24ViewModel {
  locations: Array<CommercialCombinedStep24ItemViewModel>;
}

export interface CommercialCombinedStep24ItemViewModel {
  burningWasteCarriedOutAtPremises: boolean;
  burningWasteCarriedOutAtPremisesDetails?: string | null;

  isBuildingExposedToStormOrImpactDamage: boolean;
  isBuildingExposedToStormOrImpactDamageDetails?: string | null;

  haveBeenFloodedInTheLast10Years: boolean;
  haveBeenFloodedInTheLast10YearsDetails?: string | null;

  are400metersAwayFromWatercourse: boolean;
  are400metersAwayFromWatercourseDetails?: string | null;

  floodRisk: boolean;
  floodRiskDetails?: string | null;
}

const commercialCombinedStep24ItemSchema: SchemaOf<CommercialCombinedStep24ItemViewModel> =
  object({
    burningWasteCarriedOutAtPremises: boolean().defined(),
    burningWasteCarriedOutAtPremisesDetails: requiredIf(
      string().nullable().optional().label('It'),
      'burningWasteCarriedOutAtPremises'
    ),

    isBuildingExposedToStormOrImpactDamage: boolean().defined(),
    isBuildingExposedToStormOrImpactDamageDetails: requiredIf(
      string().nullable().optional().label('It'),
      'isBuildingExposedToStormOrImpactDamage'
    ),

    haveBeenFloodedInTheLast10Years: boolean().defined(),
    haveBeenFloodedInTheLast10YearsDetails: requiredIf(
      string().nullable().optional().label('It'),
      'haveBeenFloodedInTheLast10Years'
    ),

    floodRisk: boolean().defined(),
    floodRiskDetails: requiredIf(
      string().nullable().optional().label('It'),
      'floodRisk'
    ),

    are400metersAwayFromWatercourse: boolean().defined(),
    are400metersAwayFromWatercourseDetails: requiredIf(
      string().nullable().optional().label('It'),
      'are400metersAwayFromWatercourse'
    )
  });

export const commercialCombinedStep24Schema: SchemaOf<CommercialCombinedStep24ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep24ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep24ViewModelInitialValue: CommercialCombinedStep24ViewModel =
  { locations: [] };
export const CommercialCombinedStep24ViewModelInitialValueDev: CommercialCombinedStep24ViewModel =
  {
    locations: [
      {
        burningWasteCarriedOutAtPremises: false,
        burningWasteCarriedOutAtPremisesDetails: '',
        isBuildingExposedToStormOrImpactDamage: false,
        isBuildingExposedToStormOrImpactDamageDetails: '',
        haveBeenFloodedInTheLast10Years: false,
        haveBeenFloodedInTheLast10YearsDetails: '',
        are400metersAwayFromWatercourse: false,
        are400metersAwayFromWatercourseDetails: '',
        floodRisk: false,
        floodRiskDetails: ''
      }
    ]
  };

export function useCommercialCombinedStep24InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep24ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep24ViewModelInitialValueDev
        : CommercialCombinedStep24ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
