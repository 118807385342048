import { scrollToTop } from 'common/functions/ScrollToFirstError';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { FormMultiStepGroupContextProps } from './form-multi-step-group.models';

type FormMultiStepNavigatorComponent = FC<
  HTMLAttributes<HTMLElement> & {
    contextProps?: FormMultiStepGroupContextProps | undefined;
  }
>;

const FormMultiStepNavigator: FormMultiStepNavigatorComponent = ({
  contextProps,
  children,
  ...otherProps
}) => {
  const [nextButtonText, setNextButtonText] = useState('');
  const [hideBackButton, setHideBackButton] = useState<boolean | null>(null);

  useEffect(() => {
    if (contextProps?.activeStep.props.handler) {
      setNextButtonText(
        contextProps.activeStep.props.handler()?.nextButtonText || ''
      );
      const iHideBackButton =
        contextProps.activeStep.props.handler()?.hideBackButton;
      setHideBackButton(iHideBackButton == null ? null : iHideBackButton);
    }
  }, [contextProps?.activeStep.props]);

  if (!contextProps) {
    return <>{children}</>;
  }

  async function onPrevHandlerAsync() {
    if (contextProps && contextProps.activeStep.props.handler) {
      const handler = contextProps.activeStep.props.handler();
      let data = contextProps.data.slice(0);
      if (handler && handler.getValues) {
        data[contextProps.step - 1] = await handler.getValues();
      }
      contextProps.stepChanging(data, 'prev');

      if (handler && handler.prevStepIndex) {
        contextProps.goToStepIndex(
          Math.min(
            Math.max(0, handler.prevStepIndex(data)),
            contextProps.stepItems.length - 1
          )
        );
        scrollToTop();
        return;
      }

      if (!contextProps.selectedIndexIsFirst) {
        contextProps.prevStep();
        scrollToTop();
      }
    }
  }

  async function onNextHandlerAsync() {
    if (contextProps && contextProps.activeStep.props.handler) {
      contextProps.startNavigating();

      const handler = contextProps.activeStep.props.handler();
      let data = contextProps.data.slice(0);
      if (handler && handler.getValues) {
        data[contextProps.step - 1] = await handler.getValues();
      }

      if (handler && handler.onBeforeNext) {
        if (!(await Promise.resolve(handler.onBeforeNext(data)))) {
          contextProps.finishNavigating();
          return;
        }
      }

      contextProps.stepChanging(data, 'next');

      if (handler && handler.nextStepIndex) {
        contextProps.goToStepIndex(
          Math.min(
            Math.max(0, handler.nextStepIndex(data)),
            contextProps.stepItems.length - 1
          )
        );
        scrollToTop();
        return;
      }

      if (contextProps.selectedIndexIsLast) {
        contextProps.finish();
      } else {
        contextProps.nextStep();
        scrollToTop();
      }
    }
  }

  let showBackButton = false;
  if (hideBackButton != null) {
    showBackButton = !hideBackButton;
  } else {
    showBackButton =
      !contextProps.selectedIndexIsFirst && !contextProps.selectedIndexIsLast;
  }

  return (
    <div className="mb-4 text-center d-flex flex-column" {...otherProps}>
      <button
        className="btn btn-primary"
        onClick={contextProps.navigating ? undefined : onNextHandlerAsync}
        disabled={contextProps.navigating}
      >
        {nextButtonText
          ? nextButtonText
          : contextProps.selectedIndexIsLast
          ? 'Finish'
          : 'Next'}
      </button>
      {showBackButton && (
        <button
          className="p-0 border-0 bg-transparent text-decoration-underline d-flex mx-auto my-3 h6"
          onClick={contextProps.navigating ? undefined : onPrevHandlerAsync}
          disabled={contextProps.navigating}
        >
          <strong>Go Back</strong>
        </button>
      )}
    </div>
  );
};

FormMultiStepNavigator.displayName = 'FormMultiStepNavigator';
export default FormMultiStepNavigator;
export type { FormMultiStepNavigatorComponent };
