import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, number, object, SchemaOf } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep10ViewModel>();

export interface CommercialCombinedStep10Props {
  initialValue: CommercialCombinedStep10ViewModel;
}

export type CommercialCombinedStep10Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep10Props
>;

export interface CommercialCombinedStep10ViewModel {
  employBonaFide?: boolean | null;
  bonaFideAnnualPct?: number | null;
}

export const commercialCombinedStep10Schema: SchemaOf<CommercialCombinedStep10ViewModel> =
  object({
    employBonaFide: boolean()
      .nullable()
      .required()
      .label('Do you operate from more than one location'),
    bonaFideAnnualPct: requiredIf(
      number()
        .nullable()
        .optional()
        .label('Percentage of Annual Turnover by Bona Fide Subcontractors'),
      'employBonaFide'
    )
  });

export const CommercialCombinedStep10ViewModelInitialValue: CommercialCombinedStep10ViewModel =
  {
    employBonaFide: null,
    bonaFideAnnualPct: null
  };
export const CommercialCombinedStep10ViewModelInitialValueDev: CommercialCombinedStep10ViewModel =
  {
    employBonaFide: false,
    bonaFideAnnualPct: null
  };

export function useCommercialCombinedStep10InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep10ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep10ViewModelInitialValueDev
        : CommercialCombinedStep10ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
