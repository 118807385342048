import { backOfficeUrl } from 'common/useRouterArea';
import { backOfficeBaseURL } from 'config';
import { BackOfficeRibbon } from 'features/auth/BackOfficeRibbon';
import { FC, useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

const BackOfficeBuyIndex: FC<{}> = () => {
  const isBuyParentBackOffice = useMatch(`${backOfficeUrl}/buy`);
  const navigate = useNavigate();

  useEffect(() => {
    if (isBuyParentBackOffice) {
      document.location.href = `${backOfficeBaseURL}/back-office/login`;
    }
  }, [isBuyParentBackOffice, navigate]);

  return (
    <div>
      <BackOfficeRibbon />
      {/* <div className="instantQuoteBrokerInfo">
        <div>
          <span>
            Your are on a <strong>broker portal quote process</strong> and
            currently logged in as {email}
          </span>
          <a href={`${brokerBaseURL}/broker/dashboard/`}>Return to Dashboard</a>
        </div>
      </div> */}
      <Outlet />
    </div>
  );
};

export default BackOfficeBuyIndex;
