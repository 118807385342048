import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading } from 'components';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean,
  FormSelect as FormSelectGeneric,
  FormTextBox,
  FormTextBoxDate
} from 'components/controls';
import { Formik } from 'formik';
import { forwardRef, useMemo } from 'react';
import {
  CommercialCombinedStep3Component,
  commercialCombinedStep3Schema,
  CommercialCombinedStep3ViewModel
} from './Step3.models';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import {
  coverRequiredStepIndex,
  locationsStepIndex,
  coverTypeSelectionStepIndex
} from './index';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
import { optionsCC } from 'config/options';
import moment from 'moment';
const getFieldName = nameOf<CommercialCombinedStep3ViewModel>;
const FormSelect = FormSelectGeneric<string>();

const CommercialCombinedStep3: CommercialCombinedStep3Component = (
  { initialValue },
  ref
) => {
  const multiStepContext = useFormMultiStepGroupContext();

  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep3ViewModel>(
      ref,
      {
        nextStepIndex: () =>        
          step2ViewModel.selectedCoverType === 'PC'
          ? locationsStepIndex
          : coverRequiredStepIndex,               
      },      
    );

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep3Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ values: { tradingStartDate } }) => {
        return (
          <>
            <Heading title="Trading Information" />
              <FormTextBoxDate
                name={getFieldName('tradingStartDate')}
                label-text="When did the business start Trading?"
                maxDate={moment().startOf('day').toDate()}
              >
              </FormTextBoxDate>
            <FormRadioButtonListBoolean
              name={getFieldName('hasInsurance')}
              label-text="Does the business currently have Insurance?" />
            <FormConditionalCollapse is={true} name={getFieldName('hasInsurance')}>
              <FormSelect
                id={getFieldName('currentInsurerId')}
                name={getFieldName('currentInsurerId')}
                label-text="Who is the current insurer?"
                validateOnChange={validateOnChange}
               options={optionsCC.currentInsurers} />
               <FormConditionalCollapse name={getFieldName('currentInsurerId')} is={78}>
                 <FormTextBox name={getFieldName('currentInsurerOther')} label-subElement={true} label-text="Please provide details"/>
               </FormConditionalCollapse>
             </FormConditionalCollapse>            
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep3);

