import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading, SubHeading } from 'components';
import {
  FormCheckBox,
  FormConditionalCollapse,
  FormTextBox
} from 'components/controls';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep24Component,
  CommercialCombinedStep24ItemViewModel,
  commercialCombinedStep24Schema,
  CommercialCombinedStep24ViewModel
} from './Step24.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';

const CommercialCombinedStep24: CommercialCombinedStep24Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep24ViewModel>(ref);

  const { getFieldName, locationHeadingText } = usePremisesExtraQuestionsHelper<
    CommercialCombinedStep24ItemViewModel,
    CommercialCombinedStep24ViewModel
  >();
  if (!locationHeadingText) return null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep24Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <SubHeading title="Does any of these apply to you?" />
        <div className="form-group mb-4">
          <FormCheckBox
            name={getFieldName('burningWasteCarriedOutAtPremises')}
            label-text="is burning of waste carried out at the premises"
          />
          <FormConditionalCollapse
            name={getFieldName('burningWasteCarriedOutAtPremises')}
          >
            <FormTextBox
              name={getFieldName('burningWasteCarriedOutAtPremisesDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('isBuildingExposedToStormOrImpactDamage')}
            label-text="is the building in an area unduly exposed to storm or impact damage"
          />
          <FormConditionalCollapse
            name={getFieldName('isBuildingExposedToStormOrImpactDamage')}
          >
            <FormTextBox
              name={getFieldName(
                'isBuildingExposedToStormOrImpactDamageDetails'
              )}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('haveBeenFloodedInTheLast10Years')}
            label-text="have the premises been flooded in the last 10 years"
          />
          <FormConditionalCollapse
            name={getFieldName('haveBeenFloodedInTheLast10Years')}
          >
            <FormTextBox
              name={getFieldName('haveBeenFloodedInTheLast10YearsDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('are400metersAwayFromWatercourse')}
            label-text="are the premises within 400 metres of any watercourse, river or the sea"
          />
          <FormConditionalCollapse
            name={getFieldName('are400metersAwayFromWatercourse')}
          >
            <FormTextBox
              name={getFieldName('are400metersAwayFromWatercourseDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('floodRisk')}
            label-text="have you been informed that the premises are in a potential flood risk area"
          />
          <FormConditionalCollapse name={getFieldName('floodRisk')}>
            <FormTextBox
              name={getFieldName('floodRiskDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
        </div>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep24);
