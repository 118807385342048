import nameOf from 'common/functions/NameOfHelper';
import { requiredIfBlockTyped, requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import {
  postcodeLookupSchema,
  PostcodeLookupViewModel,
  postcodeLookupViewModelInitialValue,
  postcodeLookupViewModelInitialValueDev1
} from 'components/postcode-lookup.models';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, date, number, object, string } from 'yup';
import { MethodSchemaType } from 'components/controls/form/form-multi-step-group.models';
import { coverTypeSelectionStepIndex } from '.';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
import { businessTypes_IsCompany } from 'config/options';

const requiredIf = requiredIfTyped<CommercialCombinedStep7ViewModel>();
const requiredIfBlock =
  requiredIfBlockTyped<CommercialCombinedStep7ViewModel>();

export interface CommercialCombinedStep7ViewModel {
  businessInterruptionCoverAdded: boolean;
  businessInterruptionSum?: Array<number | null> | null;
  businessInterruptionIndemnityPeriodId?: number | null;
  businessInterruptionBasisIfCoverId?: number | null;

  moneyCoverAdded: boolean;
  moneyAnnualCarrying?: number | null;
  moneyInTransitToFromBank?: number | null;
  moneyAtAllPremisesBusinessHour?: number | null;
  moneyAtAllPremisesOutBusinessHourAndNotInSafe?: number | null;
  moneyAtAllPremisesOutBusinessHourAndInSafe?: number | null;

  goodsInTransitCoverAdded: boolean;
  goodsInTransitTypes: Array<{
    id: number;
    text: string;
    selected: boolean;
    sumInsured?: number | null;
  }>;
  goodsInTransitAnnualValue?: number | null;
  goodsInTransitMaxValueSingleTransit?: number | null;

  lossOfLicenceCoverAdded: boolean;
  lossOfLicenceType?: string | null;
  lossOfLicenceSumInsured?: number | null;
  lossOfLicenceDateOfIssue?: Date | null;
  lossOfLicenceIssuingAuthority?: string | null;
  lossOfLicencePreviousWarningsOrNotices?: boolean | null;
  lossOfLicencePreviousWarningsOrNoticesDetails?: string | null;
  lossOfLicencePreviousPendingConvictions?: boolean | null;
  lossOfLicencePreviousPendingConvictionsDetails?: string | null;

  personalAccidentCoverAdded: boolean;
  personalAccidentUnitsRequired?: number | null;
  personalAccidentAnyInsuredOver70?: boolean | null;
  personalAccidentAnyInsuredPreExistingConditions?: string | null;
  personalAccidentAnyInsuredApplicationDeclinedOrImposed?: boolean | null;
  personalAccidentAnyInsuredApplicationDeclinedOrImposedDetails?: string | null;

  detoriationOfStockCoverAdded: boolean;
  detoriationOfStockSum?: Array<number | null> | null;

  specifiedAllRisksCoverAdded: boolean;
  specifiedAllRisksTypes: Array<{
    id: number;
    text: string;
    selected: boolean;
    sumInsured?: number | null;
  }>;

  specifiedAllRisksMaxValueSingle?: number | null;
  businessTypeId?: number | null;
  policyHolderTitleId?: number | null;
  policyHolderFirstName: string;
  policyHolderSurname: string;
  tradingAs?: string | null;
  contactAddress: PostcodeLookupViewModel;
}

export interface CommercialCombinedStep7Props {
  initialValue: CommercialCombinedStep7ViewModel;
}

export type CommercialCombinedStep7Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep7Props
>;
export const commercialCombinedStep7Schema: MethodSchemaType<
  CommercialCombinedStep7ViewModel
> = (multiStepContext) => {
  const step2ViewModel: CommercialCombinedStep2ViewModel | null =
    multiStepContext?.data[coverTypeSelectionStepIndex] || null;

  const shouldValidate = step2ViewModel?.selectedCoverType !== 'PLEL';

  return object({
    businessInterruptionCoverAdded: boolean()
      .required()
      .test('TestValidate_BusinessInterruptionSum', (self, ctx) => {
        if (!shouldValidate) {
          return true;
        }

        const v = ctx.parent.businessInterruptionSum;

        if (
          self &&
          (v == null ||
            !Array.isArray(v) ||
            v.reduce((a, c) => (a || 0) + (c || 0), 0) === 0)
        ) {
          return ctx.createError({
            message: 'You must enter at least one value greater than zero'
          });
        }

        return true;
      }),
    ...requiredIfBlock(
      {
        businessInterruptionSum: array()
          .of(number().nullable().notRequired())
          .nullable()
          .optional()
          .label('Sum Insured'),
        businessInterruptionIndemnityPeriodId: number().nullable(),
        businessInterruptionBasisIfCoverId: number().nullable()
      },
      'businessInterruptionCoverAdded',
      (businessInterruptionCoverAdded: boolean) =>
        shouldValidate && businessInterruptionCoverAdded,
      (key) => {
        if (
          key ===
          nameOf<CommercialCombinedStep7ViewModel>('businessInterruptionSum')
        ) {
          return (s) => s;
        }

        return undefined;
      }
    ),

    moneyCoverAdded: boolean().required(),
    ...requiredIfBlock(
      {
        moneyAnnualCarrying: number().nullable(),
        moneyInTransitToFromBank: number().nullable(),
        moneyAtAllPremisesBusinessHour: number().nullable(),
        moneyAtAllPremisesOutBusinessHourAndNotInSafe: number().nullable(),
        moneyAtAllPremisesOutBusinessHourAndInSafe: number().nullable()
      },
      'moneyCoverAdded',
      (moneyCoverAdded: boolean) => shouldValidate && moneyCoverAdded
    ),

    goodsInTransitCoverAdded: boolean().required(),
    ...requiredIfBlock(
      {
        goodsInTransitTypes: array()
          .of(
            object({
              id: number().default(0).required(),
              text: string().default('').required(),
              selected: boolean().default(false).required(),
              sumInsured: number()
                .nullable()
                .when('selected', {
                  is: true,
                  then: (schema) =>
                    schema.required('Please enter the sum insured')
                })
            }).required()
          )
          .default([])
          .required(),
        goodsInTransitAnnualValue: number().nullable(),
        goodsInTransitMaxValueSingleTransit: number().nullable()
      },
      'goodsInTransitCoverAdded',
      (goodsInTransitCoverAdded: boolean) =>
        shouldValidate && goodsInTransitCoverAdded
    ),

    lossOfLicenceCoverAdded: boolean().required(),
    ...requiredIfBlock(
      {
        lossOfLicenceType: string().nullable().label('Type of Licence'),
        lossOfLicenceSumInsured: number()
          .nullable()
          .min(0.01)
          .label('Sum insured'),
        lossOfLicenceDateOfIssue: date().nullable(),
        lossOfLicenceIssuingAuthority: string().nullable(),
        lossOfLicencePreviousWarningsOrNotices: boolean().nullable(),
        lossOfLicencePreviousWarningsOrNoticesDetails: () => [
          string().nullable(),
          (schema) =>
            requiredIf(schema, 'lossOfLicencePreviousWarningsOrNotices')
        ],
        lossOfLicencePreviousPendingConvictions: boolean().nullable(),
        lossOfLicencePreviousPendingConvictionsDetails: () => [
          string().nullable(),
          (schema) =>
            requiredIf(schema, 'lossOfLicencePreviousPendingConvictions')
        ]
      },
      'lossOfLicenceCoverAdded',
      (lossOfLicenceCoverAdded: boolean) =>
        shouldValidate && lossOfLicenceCoverAdded
    ),

    personalAccidentCoverAdded: boolean().required(),
    ...requiredIfBlock(
      {
        personalAccidentUnitsRequired: number().nullable(),
        personalAccidentAnyInsuredOver70: boolean().nullable()
      },
      'personalAccidentCoverAdded',
      (personalAccidentCoverAdded: boolean) =>
        shouldValidate && personalAccidentCoverAdded
    ),
    personalAccidentAnyInsuredPreExistingConditions: string().nullable(),
    ...requiredIfBlock(
      {
        personalAccidentAnyInsuredApplicationDeclinedOrImposed:
          boolean().nullable(),
        personalAccidentAnyInsuredApplicationDeclinedOrImposedDetails: () => [
          string().nullable(),
          (schema) =>
            requiredIf(
              schema,
              'personalAccidentAnyInsuredApplicationDeclinedOrImposed'
            )
        ]
      },
      'personalAccidentCoverAdded',
      (personalAccidentCoverAdded: boolean) =>
        shouldValidate && personalAccidentCoverAdded
    ),

    detoriationOfStockCoverAdded: boolean()
      .required()
      .test('TestValidate_DetoriationOfStockSum', (self, ctx) => {
        if (!shouldValidate) {
          return true;
        }

        const v = ctx.parent.detoriationOfStockSum;

        if (
          self &&
          (v == null ||
            !Array.isArray(v) ||
            v.reduce((a, c) => (a || 0) + (c || 0), 0) === 0)
        ) {
          return ctx.createError({
            message: 'You must enter at least one value greater than zero'
          });
        }

        return true;
      }),
    ...requiredIfBlock(
      {
        detoriationOfStockSum: array()
          .of(number().nullable().notRequired())
          .nullable()
          .optional()
          .label('Sum Insured')
      },
      'detoriationOfStockCoverAdded',
      (detoriationOfStockCoverAdded: boolean) =>
        shouldValidate && detoriationOfStockCoverAdded,
      (key) => {
        if (
          key ===
          nameOf<CommercialCombinedStep7ViewModel>('detoriationOfStockSum')
        ) {
          return (s) => s;
        }

        return undefined;
      }
    ),
    specifiedAllRisksCoverAdded: boolean().required(),
    ...requiredIfBlock(
      {
        specifiedAllRisksTypes: array()
          .of(
            object({
              id: number().default(0).required(),
              text: string().default('').required(),
              selected: boolean().default(false).required(),
              sumInsuredUK: number()
                .nullable()
                .when('selected', {
                  is: true,
                  then: (schema) =>
                    schema.required('Please enter the sum insured')
                }),
              sumInsuredEU: number()
                .nullable()
                .when('selected', {
                  is: true,
                  then: (schema) =>
                    schema.required('Please enter the sum insured')
                }),
              sumInsuredWW: number()
                .nullable()
                .when('selected', {
                  is: true,
                  then: (schema) =>
                    schema.required('Please enter the sum insured')
                })
            }).required()
          )
          .default([])
          .required(),
        specifiedAllRisksMaxValueSingle: number().nullable()
      },
      'specifiedAllRisksCoverAdded',
      (specifiedAllRisksCoverAdded: boolean) =>
        shouldValidate && specifiedAllRisksCoverAdded
    ),
    businessTypeId: number().required().label('Business type'),
    policyHolderTitleId: number().required().label('Policyholder title'),
    policyHolderFirstName: string()
      .required()
      .max(50)
      .label('Policyholder first name'),
    policyHolderSurname: string()
      .required()
      .max(50)
      .label('Policyholder surname'),
    tradingAs: string()
      .when('businessTypeId', {
        is: (businessTypeId: number | null | undefined) => {
          if (businessTypeId == null) return false;
          return businessTypes_IsCompany.includes(businessTypeId);
        },
        then: (x) => x.required(),
        otherwise: (x) => x.nullable()
      })
      .label('Trading Name'),
    contactAddress: postcodeLookupSchema.required()
  });
};

export const CommercialCombinedStep7ViewModelInitialValue: CommercialCombinedStep7ViewModel =
  {
    businessInterruptionCoverAdded: false,
    moneyCoverAdded: false,
    goodsInTransitCoverAdded: false,
    goodsInTransitTypes: [
      {
        id: 1,
        text: 'Portable Hand Tools',
        selected: false
      },
      {
        id: 2,
        text: 'Diagnostic Equipment',
        selected: false
      },
      {
        id: 3,
        text: 'Stock',
        selected: false
      },
      {
        id: 4,
        text: 'Vehicles being Conveyed',
        selected: false
      }
    ],
    lossOfLicenceCoverAdded: false,
    personalAccidentCoverAdded: false,
    detoriationOfStockCoverAdded: false,
    specifiedAllRisksCoverAdded: false,
    specifiedAllRisksTypes: [
      {
        id: 1,
        text: 'Portable Hand Tools',
        selected: false
      },
      {
        id: 2,
        text: 'Diagnostic Equipment',
        selected: false
      },
      {
        id: 3,
        text: 'Stock',
        selected: false
      },
      {
        id: 4,
        text: 'Vehicles being Conveyed',
        selected: false
      }
    ],
    businessTypeId: null,
    policyHolderTitleId: null,
    policyHolderFirstName: '',
    policyHolderSurname: '',
    contactAddress: postcodeLookupViewModelInitialValue
  };

export const CommercialCombinedStep7ViewModelInitialValueDev: CommercialCombinedStep7ViewModel =
  {
    businessInterruptionCoverAdded: false,
    // businessInterruptionSum: [101, 102],
    // businessInterruptionIndemnityPeriodId: 1,
    // businessInterruptionBasisIfCoverId: 1,
    moneyCoverAdded: false,
    // moneyAnnualCarrying: 201,
    // moneyInTransitToFromBank: 202,
    // moneyAtAllPremisesBusinessHour: 203,
    // moneyAtAllPremisesOutBusinessHourAndNotInSafe: 204,
    // moneyAtAllPremisesOutBusinessHourAndInSafe: 205,
    goodsInTransitCoverAdded: false,
    goodsInTransitTypes: [
      {
        id: 1,
        text: 'Portable Hand Tools',
        selected: false
      },
      {
        id: 2,
        text: 'Diagnostic Equipment',
        selected: false
      },
      {
        id: 3,
        text: 'Stock',
        selected: false
      },
      {
        id: 4,
        text: 'Vehicles being Conveyed',
        selected: false
      }
    ],
    // goodsInTransitAnnualValue: 302,
    // goodsInTransitMaxValueSingleTransit: 303,
    lossOfLicenceCoverAdded: false,
    // lossOfLicenceType: '401',
    // lossOfLicenceSumInsured: 402,
    // lossOfLicenceDateOfIssue: new Date(),
    // lossOfLicenceIssuingAuthority: '403',
    // lossOfLicencePreviousWarningsOrNotices: true,
    // lossOfLicencePreviousWarningsOrNoticesDetails: '404',
    // lossOfLicencePreviousPendingConvictions: true,
    // lossOfLicencePreviousPendingConvictionsDetails: '405',
    personalAccidentCoverAdded: false,
    // personalAccidentUnitsRequired: 501,
    // personalAccidentAnyInsuredOver70: true,
    // personalAccidentAnyInsuredPreExistingConditions: '502',
    // personalAccidentAnyInsuredApplicationDeclinedOrImposed: true,
    // personalAccidentAnyInsuredApplicationDeclinedOrImposedDetails: '503',
    detoriationOfStockCoverAdded: false,
    // detoriationOfStockSum: [10000, 11000],
    specifiedAllRisksCoverAdded: false,
    specifiedAllRisksTypes: [
      {
        id: 1,
        text: 'Portable Hand Tools',
        selected: false
      },
      {
        id: 2,
        text: 'Diagnostic Equipment',
        selected: false
      },
      {
        id: 3,
        text: 'Stock',
        selected: false
      },
      {
        id: 4,
        text: 'Vehicles being Conveyed',
        selected: false
      }
    ],
    businessTypeId: 1,
    policyHolderTitleId: 1,
    policyHolderFirstName: 'John',
    policyHolderSurname: 'Smith',
    contactAddress: postcodeLookupViewModelInitialValueDev1
  };

export function useCommercialCombinedStep7InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep7ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep7ViewModelInitialValueDev
        : CommercialCombinedStep7ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}

