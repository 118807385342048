import nameOf from 'common/functions/NameOfHelper';
import { FormAutocomplete as FormAutocompleteGeneric } from 'components/controls';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading } from 'components/Heading';
import { optionsCC } from 'config/options';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep1Component,
  commercialCombinedStep1Schema,
  CommercialCombinedStep1ViewModel
} from './Step1.models';
const FormAutocomplete = FormAutocompleteGeneric<number>();

const CommercialCombinedStep1: CommercialCombinedStep1Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep1ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep1Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Your Cover" />
        <FormAutocomplete
          id={nameOf<CommercialCombinedStep1ViewModel>('activity')}
          name={nameOf<CommercialCombinedStep1ViewModel>('activity')}
          label-text="What is your business activity?"
          placeholder="Start typing"
          options={optionsCC.activities}
        />
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep1);
