import { PropsWithChildren, FC, ReactElement, ChangeEvent } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { FieldHookConfig, FieldInputProps, useField } from 'formik';
import { DropdownItem } from 'common';

export type FormCheckBoxListItemModel<T extends string | number> = {
  internalType?: 'string';
  option: DropdownItem<T>;
  idx: number;
  isChecked: boolean;
  hasError: boolean;
  field: Omit<FieldInputProps<Array<T>>, 'value' | 'checked'>;
};

type FormCheckBoxListProps<T extends string | number> = PropsWithChildren<
  Omit<FormControlWrapperProps, 'id' | 'name'> &
    FormLabelProps & {
      id?: string;
      name: string;
      validateOnChange: boolean;
      options: Array<DropdownItem<T>>;
      item?: (item: FormCheckBoxListItemModel<T>) => ReactElement;
    }
>;

type FormCheckBoxListComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormCheckBoxList = <T extends string | number>() => {
  const Instance: FormCheckBoxListComponent<
    FieldHookConfig<Array<T>> & FormCheckBoxListProps<T>
  > = (props) => {
    const splitProps = separateFormLabelProps(props);
    const {
      id,
      name,
      validateOnChange,
      options,
      item,
      children,
      ...otherProps
    } = splitProps[0];
    const [labels, others] = filterChildren(children, 'FormLabel');

    const [field, meta, fieldHelpers] = useField<Array<T>>({
      name: props.name,
      type: 'checkbox',
      multiple: true
    });

    const { value, checked, onChange, ...otherFields } = field;
    const hasError = !!meta?.error;

    const onChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
      const newValue = [...value];

      const castValue: T = (
        options.length > 0 && typeof options[0].value === 'number'
          ? Number(ev.currentTarget.value)
          : ev.currentTarget.value
      ) as T;
      const indexOf = newValue.indexOf(castValue);

      if (ev.currentTarget.checked && indexOf < 0) {
        newValue.push(castValue);
        fieldHelpers.setValue(
          newValue.sort((i1, i2) => {
            if (typeof i1 === 'number' && typeof i2 === 'number') {
              return i1 - i2;
            } else return i1 > i2 ? 1 : i1 < i2 ? -1 : 0;
          }),
          validateOnChange
        );
      } else if (!ev.currentTarget.checked && indexOf >= 0) {
        newValue.splice(indexOf, 1);
        fieldHelpers.setValue(newValue, validateOnChange);
      }
    };

    return (
      <FormControlWrapper {...otherProps}>
        {labels.length ? (
          labels
        ) : (
          <FormLabel htmlFor={field.name} {...splitProps[1]} />
        )}
        {options.map((opt, idx) => {
          const isChecked = value.includes(opt.value);

          if (item) {
            return item({
              option: opt,
              idx,
              isChecked,
              hasError,
              field: { onChange: onChangeHandler, ...otherFields }
            });
          } else {
            return (
              <div
                key={`i${idx}`}
                className={`form-check ${hasError ? 'is-invalid' : ''}`.trim()}
              >
                <input
                  id={`${id}_opt_${idx}`}
                  type="checkbox"
                  value={opt.value}
                  checked={isChecked}
                  className={`form-check-input ${
                    hasError ? 'is-invalid' : ''
                  }`.trim()}
                  onChange={onChangeHandler}
                  {...otherFields}
                />
                <FormLabel
                  text={opt.text}
                  htmlFor={`${id}_opt_${idx}`}
                  className="form-check-label"
                />
              </div>
            );
          }
        })}
        {others}
        <FormControlError msg={meta?.error} />
      </FormControlWrapper>
    );
  };
  Instance.Label = FormLabel;

  Instance.displayName = 'FormCheckBoxList';
  return Instance;
};

export default FormCheckBoxList;
