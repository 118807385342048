import { HTMLAttributes } from 'react';

export default interface AxiosRequestManagerContextProps
  extends HTMLAttributes<HTMLElement> {
  defaultDelay: number;
  incRequest: () => void;
  decRequest: () => void;
  errors: string[];
  setErrors: (errors: any) => void;
  resetState: () => void;
}

export const axiosRequestManagerContextPropsDefaultValue: AxiosRequestManagerContextProps =
  {
    defaultDelay: 2000,
    incRequest: () => null,
    decRequest: () => null,
    errors: [],
    setErrors: () => null,
    resetState: () => null
  };
