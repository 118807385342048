import { Lock } from '@mui/icons-material';
import { currencyFormat } from 'common/functions/formats/currency';
import { FormMultiStepGroupContextProps } from 'components/controls/form/form-multi-step-group.models';
import { FC, useSyncExternalStore } from 'react';
import { Container, ProgressBar } from 'react-bootstrap';
import { coverTypeSelectionStepIndex } from '.';
import {
  CommercialCombinedStep2ViewModel,
  SelectedCoverType
} from './Step2.models';

function getCalculatedStepPLEL(step: number) {
  switch (true) {
    case step === 4:
      return 1;
    case step >= 7 && step <= 12:
      return 2;
    case step === 13:
      return 3;
    case step === 14:
      return 4;
    case step >= 15 && step <= 17:
      return 5;
    case step >= 26 && step <= 29:
      return 6;
    default:
      return 0;
  }
}
function getCalculatedStepPC(step: number) {
  switch (true) {
    case step >= 7 && step <= 12:
      return 1;
    case step === 13:
      return 2;
    case step === 14:
      return 3;
    case step >= 18 && step <= 24:
      return 4;
    case step === 25:
      return 5;
    case step >= 26 && step <= 29:
      return 6;
    default:
      return 0;
  }
}
function getCalculatedStepCC(step: number) {
  switch (true) {
    case step >= 7 && step <= 12:
      return 1;
    case step === 13:
      return 2;
    case step === 14:
      return 3;
    case step >= 15 && step <= 17:
      return 4;
    case step >= 18 && step <= 24:
      return 5;
    case step === 25:
      return 6;
    case step >= 26 && step <= 29:
      return 7;
    default:
      return 0;
  }
}

function getCalculatedStepByCoverType(
  coverType: SelectedCoverType | undefined,
  step: number
) {
  if (coverType === undefined) return 0;
  switch (coverType) {
    case 'PLEL':
      return getCalculatedStepPLEL(step);
    case 'PC':
      return getCalculatedStepPC(step);
    case 'CC':
      return getCalculatedStepCC(step);
  }
}

const CommercialCombinedIndicator: FC<{
  titles: string[];
  step: number;
  totalSteps: number;
  contextProps: FormMultiStepGroupContextProps;
}> = ({ step, contextProps }) => {
  const step2ViewModel =
    (contextProps.data?.[
      coverTypeSelectionStepIndex
    ] as CommercialCombinedStep2ViewModel) || {};

  const { refer, quotePrice: price } = useSyncExternalStore(
    contextProps.quotePubSub!.subscribeQuote,
    contextProps.quotePubSub!.getQuote
  );

  if (!step2ViewModel) {
    return null;
  }

  const { selectedCoverType: coverType, selectedCoverName: coverName } =
    step2ViewModel;

  const calculatedTotalSteps = coverType === 'CC' ? 7 : 6;
  const calculatedStep = getCalculatedStepByCoverType(coverType, step);

  return (
    <div className="mb-4">
      <ProgressBar
        now={
          calculatedStep === 0
            ? 10
            : Math.round((calculatedStep / calculatedTotalSteps) * 100)
        }
        className="mx-n3 mx-sm-0"
      />
      {calculatedStep > 0 && (
        <>
          <div className="ps-3 mt-1 small">
            Step {calculatedStep} of {calculatedTotalSteps}
          </div>

          <Container bsPrefix="container container-body-sm">
            <div className="mt-2 rounded-5 bg-primary text-white">
              {coverName && (
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-fill justify-content-center align-items-center px-3">
                    <Lock sx={{ fontSize: 14 }} className="me-2" />
                    <small className="fw-medium">{coverName}</small>
                  </div>
                  <div className="d-flex flex-fill justify-content-center align-items-center border-start border-white border-dashed px-3">
                    {refer && <span>Referral</span>}
                    {!refer && price == null && <span>Loading...</span>}
                    {!refer && price != null && (
                      <>
                        <span className="fw-medium">
                          {currencyFormat(price)}/
                        </span>
                        <small>year</small>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default CommercialCombinedIndicator;
