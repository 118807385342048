const params: {
  [prop: string]: Array<string>;
} = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.getAll(prop.toString())
}) as any;

let apiBaseURL: string = process.env.REACT_APP_APIBASEURL || '';
if (apiBaseURL.startsWith('#{')) apiBaseURL = '';

let backOfficeBaseURL: string = process.env.REACT_APP_BACKOFFICEBASEURL || '';
if (backOfficeBaseURL.startsWith('#{')) backOfficeBaseURL = '';

let brokerBaseURL: string = process.env.REACT_APP_BROKERBASEURL || '';
if (brokerBaseURL.startsWith('#{')) brokerBaseURL = '';

let stripePK: string = process.env.REACT_APP_STRIPEPK || '';
if (stripePK.startsWith('#{')) stripePK = '';

let useMockedResponse: string | boolean =
  process.env.REACT_APP_USEMOCKEDRESPONSE || 'false';
useMockedResponse = useMockedResponse === 'true' || useMockedResponse === '1';

let isDevMode: string | boolean = process.env.REACT_APP_ISDEV || 'false';
isDevMode = isDevMode === 'true' || isDevMode === '1';

let devForceDebug: string | boolean =
  process.env.REACT_APP_DEV_FORCEDEBUG || 'false';
devForceDebug =
  isDevMode && (devForceDebug === 'true' || devForceDebug === '1');

if (isDevMode && !devForceDebug && params.debug.length) {
  devForceDebug = params.debug.includes('1');
}

let devForcePp: string | boolean = process.env.REACT_APP_DEV_FORCEPP || 'false';
devForcePp = isDevMode && (devForcePp === 'true' || devForcePp === '1');

if (isDevMode && !devForcePp && params.pp.length) {
  devForcePp = params.pp.includes('1');
}

export {
  apiBaseURL,
  backOfficeBaseURL,
  brokerBaseURL,
  stripePK,
  useMockedResponse,
  isDevMode,
  devForceDebug,
  devForcePp
};
