import { formatDate } from 'common/functions/Date';
import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading } from 'components';
import {
  FormFieldReadonly,
  FormTextarea,
  FormTextBoxDate
} from 'components/controls';
import { Formik } from 'formik';
import moment from 'moment';
import { forwardRef, useMemo } from 'react';
import {
  CommercialCombinedStep27Component,
  commercialCombinedStep27Schema,
  CommercialCombinedStep27ViewModel
} from './Step27.models';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import {
  coverTypeSelectionStepIndex,
  defaultDataValue,
  operationsStepIndexEnd,
  startDateStepIndex,
  storeNameStep,
  storeQuoteData
} from './index';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
import { useSubmitAsync, useNewFromBackOfficeSubmitAsync } from 'features/commercialCombined/quote';
import { useNavigate, useParams } from 'react-router-dom';
import { useRouterArea } from 'common/useRouterArea';
import { backOfficeBaseURL } from 'config';

const getFieldName = nameOf<CommercialCombinedStep27ViewModel>;

const CommercialCombinedStep27: CommercialCombinedStep27Component = (
  { initialValue },
  ref
) => {
  const { guid, brokerId, clientId } = useParams();
  const multiStepContext = useFormMultiStepGroupContext();
  const submitAsync = useSubmitAsync();
  const submitForBroker = useNewFromBackOfficeSubmitAsync()

  const navigate = useNavigate();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  const routerArea = useRouterArea();

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep27ViewModel>(
      ref,
      {
        prevStepIndex: () =>
          step2ViewModel.selectedCoverType === 'PLEL'
            ? operationsStepIndexEnd
            : startDateStepIndex - 1,
        onBeforeNextAsync: async (data) => {
          try {
            const result = !brokerId? await submitAsync(guid, {
              step: 26,
              data
            }): await submitForBroker(brokerId, clientId, {
              step: 26,
              data
            });

            // from backOffice
            if (result.id !== undefined) {
              window.location.href = !brokerId ? `${backOfficeBaseURL}/back-office/quote-policies/adjustments/?quotePolicyID=${result.id}`:`${backOfficeBaseURL}/back-office/quote-policies/workflow/?quotePolicyID=${result.id}`;
              if (brokerId) {
                multiStepContext.setData(defaultDataValue());
                sessionStorage.removeItem(storeNameStep);
                sessionStorage.removeItem(storeQuoteData);
              }
              return false;
            }

            if (result.referred) {
              multiStepContext.setData(defaultDataValue());
              sessionStorage.removeItem(storeNameStep);
              sessionStorage.removeItem(storeQuoteData);
              navigate(`${routerArea.urlPrefix}refer/${result.guid}`);

              return false;
            }
          } catch {
            return false;
          }

          return true;
        }
      }
    );

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep27Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ values: { startDate, endDate, renewalStartDate } }) => (
        <>
          <Heading title="Date" />
          {!renewalStartDate ?
          <FormTextBoxDate
            name={getFieldName('startDate')}
            label-text="Please choose the start date"
            minDate={moment().startOf('day').toDate()}
            maxDate={moment().startOf('day').add(30, 'day').toDate()}
          >
            <p className="small text-muted mt-1 mb-0">
              Please note start date cannot be more than 30 days in advance
            </p>
            </FormTextBoxDate>
            
            :  <FormFieldReadonly
            title="Renewal Date"
            value = { formatDate(renewalStartDate) }            
          />
          }
          {!renewalStartDate &&<FormFieldReadonly
            title="End date"
            value={
              endDate ?
              formatDate(endDate) :              
              startDate
                ? formatDate(
                    moment(startDate).add(1, 'year').subtract(1, 'day')
                  )
                : undefined
            }
            valueIfUndefined="N/A"
          />
          }
          
          {routerArea.isBroker && (
            <FormTextarea
              name={getFieldName('referralNotes')}
              label-text="Broker referral notes"
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep27);

