import {
  PropsWithChildren,
  FC,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { IMaskInput } from 'react-imask';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { getIn, useFormikContext } from 'formik';
import { InputGroup } from 'react-bootstrap';

const currencySymbol = '£';

type FormTextBoxCurrencyProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      hideThousandsSeparator?: boolean;
      blockDecimals?: boolean;
      showCurrencySymbol?: boolean;
      forceInvalid?: boolean;
    }
>;

type FormTextBoxCurrencyComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const renderInput = (
  id: string | undefined,
  value: string,
  placeholder: string | undefined,
  showError: boolean,
  hideThousandsSeparator: boolean,
  blockDecimals: boolean,
  showCurrencySymbol: boolean,
  onChange: (strVal: string, numVal: number | null | undefined) => void,
  otherFields: any
) => {
  return (
    <InputGroup className={`mb-0 $ ${showError ? 'is-invalid' : ''}`}>
      <InputGroup.Text id="sumInsured" className="bg-primary text-white px-3">
        {currencySymbol}
      </InputGroup.Text>
      <IMaskInput
        id={id}
        mask={`${showCurrencySymbol ? currencySymbol : ''}num`}
        blocks={{
          num: {
            mask: Number,
            radix: '.',
            thousandsSeparator: hideThousandsSeparator ? '' : ',',
            scale: blockDecimals ? 0 : 2
          }
        }}
        unmask={false}
        placeholder={placeholder}
        className={`form-control ${showError ? 'is-invalid' : ''}`}
        value={value}
        onAccept={(v: string, el, ev) => {
          if (ev && ev.inputType && ev.inputType.startsWith('delete')) {
            if (!blockDecimals && v.endsWith('.')) {
              v = v.slice(0, -1);
            }
            if (showCurrencySymbol && v === currencySymbol) {
              v = '';
            }
          }

          const numVal = Number(el.unmaskedValue);
          onChange(
            v,
            !el.unmaskedValue.trim() || isNaN(numVal) ? undefined : numVal
          );
        }}
        {...otherFields}
      />
    </InputGroup>
  );
};

const FormTextBoxCurrency: FormTextBoxCurrencyComponent<
  FormTextBoxCurrencyProps
> = (props) => {
  const [internalValue, setInternalValue] = useState('');
  const internallyTriggered = useRef(false);
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    inputOtherWrapperClassName,
    placeholder,
    hideThousandsSeparator,
    blockDecimals,
    showCurrencySymbol,
    forceInvalid,
    children,
    ...otherProps
  } = splitProps[0];

  const [labels, others] = filterChildren(children, 'FormLabel');

  const { getFieldProps, getFieldHelpers, getFieldMeta, values } =
    useFormikContext();
  const {
    value: ignoredValue,
    onChange,
    ...otherFields
  } = getFieldProps(props.name);
  const { setValue } = getFieldHelpers(props.name);
  const { error } = getFieldMeta(props.name);

  const value = useCallback((): number | null | undefined => {
    return getIn(values, props.name);
  }, [props.name, values])();

  useEffect(() => {
    if (internallyTriggered.current) {
      internallyTriggered.current = false;
    } else {
      setInternalValue(
        value != null
          ? blockDecimals
            ? Math[value < 0 ? 'ceil' : 'floor'](value).toString()
            : value.toString()
          : ''
      );
    }
  }, [blockDecimals, value]);

  const placeholderFix = () => {
    if (!placeholder || !placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  const handleOnChange = (
    strVal: string,
    numVal: number | null | undefined
  ) => {
    setInternalValue(strVal);
    if (value !== numVal) {
      internallyTriggered.current = true;
      setValue(numVal);
    }
  };

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? labels : <FormLabel htmlFor={name} {...splitProps[1]} />}
      {inputOtherWrapperClassName ? (
        <div className={inputOtherWrapperClassName}>
          {renderInput(
            id,
            internalValue,
            placeholderFix(),
            !!error || forceInvalid || false,
            hideThousandsSeparator || false,
            blockDecimals || false,
            showCurrencySymbol || false,
            handleOnChange,
            otherFields
          )}
          {others}
        </div>
      ) : (
        <>
          {renderInput(
            id,
            internalValue,
            placeholderFix(),
            !!error || forceInvalid || false,
            hideThousandsSeparator || false,
            blockDecimals || false,
            showCurrencySymbol || false,
            handleOnChange,
            otherFields
          )}
          {others}
        </>
      )}
      <FormControlError msg={error} />
    </FormControlWrapper>
  );
};
FormTextBoxCurrency.Label = FormLabel;

FormTextBoxCurrency.displayName = 'FormTextBoxCurrency';
export default FormTextBoxCurrency;
