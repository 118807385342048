import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import moment from 'moment';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, date, number, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep3Props {
  initialValue: CommercialCombinedStep3ViewModel;
}

export type CommercialCombinedStep3Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep3Props
>;

export interface CommercialCombinedStep3ViewModel {
  tradingStartDate?: Date | null;
  hasInsurance?: boolean | null;
  currentInsurerId?: number | null;
  currentInsurerOther?: string | null;
}

export const commercialCombinedStep3Schema: SchemaOf<CommercialCombinedStep3ViewModel> =
  object({
    tradingStartDate: date()
      .nullable()
      .required()    
      .max(
        moment().startOf('day').toDate(),
        (m) =>
          `${m.label} field must be earlier than ${moment(m.max).format(
            'DD/MM/YYYY'
          )}`
      )
      .label('Start date'),
      hasInsurance: boolean().nullable().required(),
      currentInsurerId: requiredIf(
        number().nullable().label('It'),
        'hasInsurance'
      ),
      currentInsurerOther: requiredIf(
        string().nullable().label('It'),
         'currentInsurerId',
         (currentInsurerId: number, hasInsurance: boolean) => currentInsurerId === 78 && hasInsurance
        )
  });

export const CommercialCombinedStep3ViewModelInitialValue: CommercialCombinedStep3ViewModel =
  {};
export const CommercialCombinedStep3ViewModelInitialValueDev: CommercialCombinedStep3ViewModel =
  {
    tradingStartDate: moment().startOf('day').add(-1).toDate()
  };

export function useCommercialCombinedStep3InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep3ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep3ViewModelInitialValueDev
        : CommercialCombinedStep3ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
