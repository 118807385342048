import { PropsWithChildren, FC, ChangeEvent } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField, useFormikContext } from 'formik';

type FormTextBoxNumberProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      disabled?: boolean;
    }
>;

type FormTextBoxNumberComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormTextBoxNumber: FormTextBoxNumberComponent<FormTextBoxNumberProps> = (
  props
) => {
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    inputOtherWrapperClassName,
    placeholder,
    disabled,
    children,
    ...otherProps
  } = splitProps[0];
  const [labels, others] = filterChildren(children, 'FormLabel');
  const [fieldProps, meta, helper] = useField<number | null | undefined>(props);
  const { validateOnChange } = useFormikContext();

  const placeholderFix = () => {
    if (!placeholder || !placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  const onChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.currentTarget.value.replace(/\D/g, '').trim() === '') {
      helper.setValue(undefined, validateOnChange);
      return;
    }

    const numVal = Number(ev.currentTarget.value.replace(/\D/g, ''));
    helper.setValue(numVal, validateOnChange);
  };

  const customInput = (
    <input
      id={id}
      name={fieldProps.name}
      type="text"
      placeholder={disabled ? undefined : placeholderFix()}
      className={`form-control ${meta?.error ? 'is-invalid' : ''}`}
      disabled={disabled}
      value={fieldProps.value?.toString() || ''}
      onChange={onChangeHandler}
      maxLength={8}
      // {...field}
    />
  );

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? labels : <FormLabel htmlFor={name} {...splitProps[1]} />}
      {inputOtherWrapperClassName ? (
        <div
          className={`${inputOtherWrapperClassName} ${
            meta?.error ? 'is-invalid' : ''
          }`}
        >
          {customInput}
          {others}
        </div>
      ) : (
        <>
          {customInput}
          {others}
        </>
      )}
      <FormControlError msg={meta?.error} />
    </FormControlWrapper>
  );
};
FormTextBoxNumber.Label = FormLabel;

FormTextBoxNumber.displayName = 'FormTextBoxNumber';
export default FormTextBoxNumber;
