import { useAuthBackOffice } from 'common/auth/useAuthBackOffice';
import { useMemo } from 'react';
import { backOfficeBaseURL } from 'config';
import { useParams } from 'react-router-dom';

export const BackOfficeRibbon = () => {
  const email = useMemo(useAuthBackOffice, []);
  const { brokerName } = useParams();

  return (
    <div className="instantQuoteBackOfficeInfo">
      <div>
        {/*  <span>
          Your are on a <strong>back-office quote process</strong> and currently
          logged in as {email} 
          {brokerName && <span>New Quote from back office {brokerName}</span> }         
        </span> */}

        <span>
          {brokerName ? (
            <span>
              {' '}
              Logged in as <strong>{email}</strong> . Creating a quote for{' '}
              <strong>
                {decodeURIComponent(brokerName.replace(/\+/g, ' '))}
              </strong>{' '}
            </span>
          ) : (
            <span>
              {' '}
              Your are on a <strong>back-office quote process</strong> and
              currently logged in as {email}{' '}
            </span>
          )}
        </span>

        <a href={`${backOfficeBaseURL}/back-office/dashboard/`}>
          Return to Dashboard
        </a>
      </div>
    </div>
  );
};
