import { formatDate } from 'common/functions/Date';
import { currencyFormat } from 'common/functions/formats/currency';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import moment from 'moment';
import { forwardRef, useSyncExternalStore } from 'react';
import { coverTypeSelectionStepIndex, startDateStepIndex } from '.';
// policyNumberStepIndex,
// storeNameDevSessionId
import { CommercialCombinedStep27ViewModel } from './Step27.models';
import {
  CommercialCombinedStep28Component,
  commercialCombinedStep28Schema,
  CommercialCombinedStep28ViewModel
} from './Step28.models';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
// import { useSubmitAsync } from 'features/commercialCombined/quote';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import policyWording from 'docs/PolicyWording.pdf';
import tob from 'docs/TermsOfBusiness.pdf';

const CommercialCombinedStep28: CommercialCombinedStep28Component = (
  { initialValue },
  ref
) => {
  // const submitAsync = useSubmitAsync();
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep28ViewModel>(
      ref,
      {
        nextButtonText: 'Next',
        hideBackButton: false
        // onBeforeNextAsync: async (data: any[]) => {
        //   try {
        //     const result = await submitAsync({
        //       key: sessionStorage.getItem(storeNameDevSessionId),
        //       step: policyNumberStepIndex + 1,
        //       data
        //     });
        //     multiStepContext.quotePubSub!.setQuote({
        //       guid: result.guid,
        //       paymentRef: result.paymentRef
        //     });
        //     multiStepContext.setData(() => {
        //       data[policyNumberStepIndex] = {
        //         ...data[policyNumberStepIndex],
        //         policyNumber: result.policyNumber
        //       };
        //       return data;
        //     });
        //   } catch {
        //     return false;
        //   }
        // }
        // nextStepIndex: () => policyNumberStepIndex + 2
      }
    );
  const multiStepContext = useFormMultiStepGroupContext();

  const step2ViewModel = multiStepContext.data[
    coverTypeSelectionStepIndex
  ] as CommercialCombinedStep2ViewModel;

  const quoteResult = useSyncExternalStore(
    multiStepContext.quotePubSub!.subscribeQuote,
    multiStepContext.quotePubSub!.getQuote
  );
  const quotePrice = quoteResult.quotePrice || 0;
  const excesses = quoteResult.excesses;

  const hasDataStep27 = multiStepContext.data?.[startDateStepIndex] != null;
  const startDate: Date | null | undefined = hasDataStep27
    ? (
        multiStepContext.data[
          startDateStepIndex
        ] as CommercialCombinedStep27ViewModel
      ).startDate
    : null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep28Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Your quote" />
        <div className="bg-light rounded-5 p-3 mb-4">
          <div className="text-center">
            <div className="fw-bold h2 text-secondary my-2">
              {currencyFormat(quotePrice)} <span className="small">/year</span>{' '}
            </div>
            <p className="fw-medium">
              Your {step2ViewModel?.selectedCoverName} Quote
            </p>
          </div>
          <div className="border-top border-secondary border-dashed pt-3 mb-3">
            <table className="w-100">
              <tbody>
                <tr>
                  <td>Your price (incl. IPT)</td>
                  <td className="text-end">{currencyFormat(quotePrice)}</td>
                </tr>
                <tr>
                  <td>Premium (excl. IPT)</td>
                  <td className="text-end">
                    {currencyFormat(Math.round(quotePrice / 0.0112) / 100)}
                  </td>
                </tr>
                <tr>
                  <td>IPT</td>
                  <td className="text-end">
                    {currencyFormat(quotePrice - quotePrice / 1.12)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {excesses && !!excesses.length && (
            <div className="border-top border-secondary border-dashed pt-3 mb-3">
              <table className="w-100">
                <tbody>
                  {excesses.map((e, idx) => (
                    <tr key={`e${idx}`}>
                      <td>{e.name}</td>
                      <td className="text-end">{currencyFormat(e.value, 0)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <button className="btn btn-outline-light w-100 mb-4 text-primary">
          View/Amend
        </button>
        <div className="h2">Summary</div>
        <ul className="list-unstyled">
          <li className="mb-3">
            Start date
            <br />
            {formatDate(startDate)}
          </li>
          <li className="mb-3">
            End date
            <br />
            {startDate
              ? formatDate(moment(startDate).add(1, 'year').subtract(1, 'day'))
              : 'N/A'}
          </li>
        </ul>
        <div className="h2">Documents</div>
        <ul className="list-unstyled mb-4">
          <li>
            <div className="d-flex align-items-center mb-2">
              <DescriptionOutlinedIcon className="me-2" />
              <a
                href={policyWording}
                rel="noreferrer"
                target="_blank"
                className="text-body"
              >
                Policy Wording
              </a>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center mb-2">
              <DescriptionOutlinedIcon className="me-2" />
              <a
                href={tob}
                rel="noreferrer"
                target="_blank"
                className="text-body"
              >
                Terms of Business
              </a>
            </div>
          </li>
        </ul>
        <button
          className="btn btn-outline-light w-100 mb-3 text-primary"
          onClick={() => alert('WIP')}
        >
          Email me these documents
        </button>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep28);
