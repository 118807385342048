import { PropsWithChildren, FC, useContext } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { FormikContext } from 'formik';
import { DropdownItem } from 'common';
import Select from 'react-select';

type FormAutocompleteProps<
  T extends string | ReadonlyArray<string> | number | undefined
> = PropsWithChildren<
  Omit<FormControlWrapperProps, 'onChange' | 'name' | 'id' | 'placeholder'> &
    FormLabelProps & {
      id?: string;
      name?: string;
      options: Array<DropdownItem<T>>;
      placeholder?: string;
      onChange?: (
        value: T | undefined,
        option?: DropdownItem<T> | undefined
      ) => void;
    }
>;

type FormAutocompleteComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormAutocomplete = <
  T extends string | ReadonlyArray<string> | number | undefined = string
>() => {
  const Instance: FormAutocompleteComponent<FormAutocompleteProps<T>> = (
    props
  ) => {
    const splitProps = separateFormLabelProps(props);
    const {
      id,
      name,
      options,
      placeholder,
      onChange,
      children,
      ...otherProps
    } = splitProps[0];
    const [labels, others] = filterChildren(children, 'FormLabel');
    const formikContext = useContext(FormikContext);

    let value: T | undefined = undefined;
    let error: string | undefined = undefined;
    if (props.name) {
      value = formikContext.getFieldProps(props.name).value;
      error = formikContext.getFieldMeta(props.name).error;
    }

    const placeholderFix = () => {
      if (!placeholder || !placeholder.trim()) {
        return undefined;
      }

      return placeholder.trim();
    };

    const onChangeHandler = (item: DropdownItem<T> | null) => {
      if (onChange) {
        onChange(item?.value, item || undefined);
      }

      if (props.name) {
        formikContext.setFieldValue(props.name, item?.value);
      }
    };

    return (
      <FormControlWrapper {...otherProps}>
        {labels.length ? (
          labels
        ) : (
          <FormLabel htmlFor={props.name} {...splitProps[1]} />
        )}
        <Select
          inputId={id}
          className={`customSelect basic-single ${error ? 'is-invalid' : ''} ${
            value ? 'hasValue' : ''
          }`.trim()}
          classNamePrefix="select"
          openMenuOnFocus={false}
          options={options}
          value={(() => {
            const selectedOption = options.find((x) => x.value === value);
            if (selectedOption) {
              return selectedOption;
            }

            return undefined;
          })()}
          getOptionValue={(o) => {
            if (o.value === undefined) {
              return '';
            }

            return o.value.toString();
          }}
          name={props.name}
          onChange={onChangeHandler}
          getOptionLabel={(o) => o.text}
          placeholder={placeholderFix()}
        />
        {others}
        <FormControlError msg={error} />
      </FormControlWrapper>
    );
  };
  Instance.Label = FormLabel;

  Instance.displayName = 'FormAutocomplete';
  return Instance;
};
export default FormAutocomplete;
