import { FormikContext } from 'formik';
import { PropsWithChildren, useContext } from 'react';
import { Collapse } from 'react-bootstrap';

interface FormConditionalCollapseProps {
  name: string;
  is?: any;
}

interface FormConditionalCollapseShowProps {
  show: boolean | null | undefined | (() => boolean);
}

function instanceOfFormConditionalCollapseShowProps(
  object: any
): object is FormConditionalCollapseShowProps {
  return 'show' in object;
}

const FormConditionalCollapse = (
  props: PropsWithChildren<
    FormConditionalCollapseProps | FormConditionalCollapseShowProps
  >
) => {
  const formikContext = useContext(FormikContext);
  let show: any = undefined;

  if (instanceOfFormConditionalCollapseShowProps(props)) {
    show = typeof props.show === 'function' ? props.show() : props.show;
  } else {
    show =
      formikContext.getFieldProps(props.name).value ===
      (props.is === undefined ? true : props.is);
  }

  return (
    <Collapse in={!!show}>
      <div>{props.children}</div>
    </Collapse>
  );
};

export default FormConditionalCollapse;
