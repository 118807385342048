import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import { Counter } from 'features/counter/Counter';
import { Button } from 'react-bootstrap';
// import arm from 'common/axios-request-manager';

export default function ExamplesIndex() {
  const { loading, getAsync } = useAxiosRequestManager();

  async function testAxios() {
    const resp = await getAsync(
      '/2eac0aa6-827f-46f6-8802-ac708acff599',
      null,
      {
        success: true
        // , httpStatus: 400,
        // httpStatusData: {
        //   DuplicateEmail: ["Email 'athlete@anewicon.com' is already taken."],
        //   DuplicateUserName: ["Username 'athlete@anewicon.com' is already taken."]
        // }
      },
      {
        params: {
          'mocky-delay': '1500ms' // cspell:disable-line
        }
      }
    );
    console.log(resp.data?.success);
  }

  return (
    <>
      <main>
        <h2>Welcome to the examples page!</h2>
        <p>You can do this, I believe in you.</p>
      </main>
      <Counter />
      <div className="mt-4">{loading.toString()}</div>
      <div className="mt-4">
        <Button
          variant="outline-danger"
          disabled={loading}
          onClick={!loading ? testAxios : undefined}
        >
          {loading ? 'Loading...' : 'Test axios'}
        </Button>
      </div>
    </>
  );
}
