import { Backdrop, CircularProgress } from '@mui/material';
import filterChildren from 'common/functions/ReactChildrenFilter';
import { isDevMode } from 'config';
import { useMemo, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { useFormMultiStepGroupConfig } from './form-multi-step-group.hooks';
import {
  FormMultiStepGroupComponent,
  FormMultiStepGroupContext
} from './form-multi-step-group.models';
import FormMultiStepItem, {
  FormMultiStepItemComponent
} from './form-multi-step-item';
import FormMultiStepNavigator, {
  FormMultiStepNavigatorComponent
} from './form-multi-step-navigator';

const FormMultiStepGroup: FormMultiStepGroupComponent & {
  Item: FormMultiStepItemComponent;
  Navigator: FormMultiStepNavigatorComponent;
} = (props) => {
  const [show, setShow] = useState(true);

  const {
    indicator,
    config,
    steps,
    beforeActiveStep,
    onBeforeNavigate,
    onFinish,
    startOver,
    children,
    ...otherProps
  } = props;

  const { step, titles, activeStep, contextProps } =
    useFormMultiStepGroupConfig({
      config,
      steps,
      onBeforeNavigate,
      onFinish,
      children
    });

  const navigator = useMemo(() => {
    return filterChildren(children, 'FormMultiStepNavigator')[0];
  }, [children]);

  return (
    <FormMultiStepGroupContext.Provider value={contextProps}>
      <>
        <style type="text/css">
          {`
          .customAlertWarning.alert {
            padding: 1rem 2.3rem 1rem 1.5rem;
          }
          .customAlertWarning button.btn-close {
            font-size: 0.6rem;
            padding: 0.8rem 0.8rem;
          }

          .customAlertWarning button.btn-close:focus {
            outline: 0;
            box-shadow: none;
          }
          `}
        </style>
        {isDevMode && show && (
          <Alert
            variant="warning"
            dismissible
            className="customAlertWarning position-fixed bottom-0"
            style={{ right: '1rem', zIndex: 99999 }}
            onClose={() => setShow(false)}
          >
            <Alert.Heading as="h6" className="mb-0">
              For DEVs
            </Alert.Heading>
            <div style={{ fontSize: '14px' }}>Step: {step}</div>
            <div className="text-center mt-2 px-3">
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={() => {
                  if (startOver) startOver();
                }}
              >
                Start over
              </button>
            </div>
          </Alert>
        )}
        <div {...otherProps} style={{ position: 'relative' }}>
          {indicator ? (
            indicator({ titles, step, totalSteps: titles.length, contextProps })
          ) : (
            <ul className="form-multi-step-indicator">
              {titles.map((title, titleIdx) => {
                return (
                  <li
                    key={titleIdx}
                    className={`${
                      titleIdx + 1 < step
                        ? 'completed'
                        : titleIdx + 1 === step
                        ? 'active'
                        : ''
                    }`.trim()}
                  >
                    {title}
                  </li>
                );
              })}
            </ul>
          )}
          <Container bsPrefix="container container-body-sm">
            <Backdrop
              open={contextProps.navigating}
              sx={{ zIndex: 10, color: '#fff', position: 'absolute' }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {beforeActiveStep}
            {activeStep}
            {navigator.length ? (
              navigator
            ) : (
              <FormMultiStepNavigator contextProps={contextProps} />
            )}
          </Container>
        </div>
      </>
    </FormMultiStepGroupContext.Provider>
  );
};
FormMultiStepGroup.Item = FormMultiStepItem;
FormMultiStepGroup.Navigator = FormMultiStepNavigator;

export default FormMultiStepGroup;
