import { WarningRounded } from '@mui/icons-material';
import { FC, PropsWithChildren } from 'react';

type FormControlErrorProps = PropsWithChildren<{
  className?: string | null;
  mt?: number | null;
  mb?: number | null;
  msg?: string | null;
}>;

type FormControlErrorComponent = PropsWithChildren<FormControlErrorProps>;

const FormControlError: FC<FormControlErrorComponent> = ({
  className,
  mt,
  mb,
  msg
}) => {
  if (!msg) {
    return null;
  }

  return (
    <div
      className={`invalidErrorMsg${
        mt !== null ? ` mt-${mt === undefined ? 2 : mt}` : ''
      }${
        mb != null ? ` mb-${mb}` : ''
      } d-flex align-items-center py-2 px-3 bg-secondary text-secondary rounded-5 bg-opacity-10 ${className}`.trim()}
    >
      <WarningRounded />
      <span className="ms-3 small">{msg}</span>
    </div>
  );
};

export default FormControlError;
export type { FormControlErrorProps };
