import { PropsWithChildren, FC, ChangeEvent } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { DropdownItem } from 'common';

type FormSelectProps<
  T extends string | ReadonlyArray<string> | number | undefined
> = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id: string;
      name: string;
      validateOnChange: boolean;
      options: Array<DropdownItem<T>>;
      addFirstItem?: boolean;
      firstItemValue?: T;
      firstItemText?: string;
    }
>;

type FormSelectComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormSelect = <
  T extends string | ReadonlyArray<string> | number | undefined
>() => {
  const Instance: FormSelectComponent<FormSelectProps<T>> = (props) => {
    const splitProps = separateFormLabelProps(props);
    const {
      id,
      name,
      validateOnChange,
      options,
      addFirstItem,
      firstItemValue,
      firstItemText,
      children,
      ...otherProps
    } = splitProps[0];

    const [labels, others] = filterChildren(children, 'FormLabel');
    const [field, meta] = useField(props);

    function getSelectedValue(selectedIndex: number): T {
      let fixIndex = 0;
      if (addFirstItem !== false) {
        fixIndex = -1;
        if (selectedIndex === 0) {
          return firstItemValue as T;
        }
      }
      return options[selectedIndex + fixIndex].value;
    }

    const formikContext = useFormikContext();
    function handleChange({
      target: { selectedIndex }
    }: ChangeEvent<HTMLSelectElement>) {
      const value = getSelectedValue(selectedIndex);
      formikContext.setFieldValue(field.name, value, validateOnChange);
    }

    return (
      <FormControlWrapper {...otherProps}>
        {labels.length ? (
          labels
        ) : (
          <FormLabel htmlFor={name} {...splitProps[1]} />
        )}
        <Form.Select
          id={id}
          className={`form-control ${meta?.error ? 'is-invalid' : ''}`}
          {...field}
          onChange={handleChange}
        >
          {addFirstItem !== false && (
            <option value={firstItemValue}>
              {firstItemText || 'Select...'}
            </option>
          )}
          {options.map((item, i) => {
            return (
              <option key={i} value={item.value}>
                {item.text}
              </option>
            );
          })}
        </Form.Select>
        {others}
        <FormControlError msg={meta?.error} />
      </FormControlWrapper>
    );
  };
  Instance.Label = FormLabel;

  Instance.displayName = 'FormSelect';
  return Instance;
};

export default FormSelect;
