import React, { FunctionComponent } from 'react';
import { isValidElement, ReactNode } from 'react';

export default function filterChildren(
  children: ReactNode,
  ...types: string[]
) {
  const oRet: Array<Array<ReactNode>> = [];

  let childrenIsArrayOrSingleChildAdded = false;

  let clonedArray: any[] = [];
  if (children) {
    if (Array.isArray(children)) {
      childrenIsArrayOrSingleChildAdded = true;
      clonedArray = [...children];
    }
    if (isValidElement<any>(children) && children.type === React.Fragment) {
      childrenIsArrayOrSingleChildAdded = true;
      if (Array.isArray(children.props.children)) {
        clonedArray = [...children.props.children];
      } else {
        children = children.props.children;
      }
    }
  }

  for (let i = 0; i < types.length; i++) {
    const innerArray: Array<ReactNode> = [];
    if (clonedArray.length > 0) {
      for (let j = 0; j < clonedArray.length; j++) {
        const child = clonedArray[j];

        if (isValidElement(child)) {
          let strType = '';
          if (typeof child.type === 'string') {
            strType = child.type;
          } else {
            strType = (child.type as FunctionComponent)?.displayName || '';
          }

          if (strType === types[i]) {
            innerArray.push(child);
            clonedArray.splice(j--, 1);
          }
        }
      }
    } else {
      if (children && isValidElement(children)) {
        let strType = '';
        if (typeof children.type === 'string') {
          strType = children.type;
        } else {
          strType = (children.type as FunctionComponent)?.displayName || '';
        }

        if (strType === types[i]) {
          childrenIsArrayOrSingleChildAdded = true;
          innerArray.push(children);
        }
      }
    }

    oRet.push(innerArray);
  }

  const otherArray: Array<ReactNode> = [];
  if (childrenIsArrayOrSingleChildAdded) {
    for (let i = 0; i < clonedArray.length; i++) {
      otherArray.push(clonedArray[i]);
    }
  } else {
    otherArray.push(children);
  }
  oRet.push(otherArray);

  return oRet;
}
