import { CommercialCombinedQuoteResultViewModel } from 'features/commercialCombined/quoteResultViewModel';
import {
  createContext,
  Dispatch,
  FC,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SetStateAction
} from 'react';
import { SchemaOf } from 'yup';
import { FormMultiStepItemProps } from './form-multi-step-item';

type FormMultiStepGroupProps = {
  prevStep: () => void;
  nextStep: () => void;
  goToStepIndex: (stepIndex: number) => void;
  finish: () => void;
  startNavigating: () => void;
  finishNavigating: () => void;
  navigating: boolean;
};

const createFormMultiStepGroupProps = (
  setStep: Dispatch<SetStateAction<number>>,
  onFinish: (() => void | Promise<void>) | undefined,
  setNavigating: Dispatch<SetStateAction<boolean>>,
  navigating: boolean
): FormMultiStepGroupProps => {
  return {
    prevStep: () => {
      setStep((x) => Math.max(0, x - 1));
    },
    nextStep: () => {
      try {
        setStep((x) => x + 1);
      } finally {
        setNavigating(false);
      }
    },
    goToStepIndex: (stepIndex: number) => {
      setStep(stepIndex + 1);
      setNavigating(false);
    },
    finish: async () => {
      try {
        if (onFinish) {
          await Promise.resolve(onFinish());
        }
      } finally {
        setNavigating(false);
      }
    },
    startNavigating: () => {
      setNavigating(true);
    },
    finishNavigating: () => {
      setNavigating(false);
    },
    navigating
  };
};

type FormMultiStepGroupComponentPropSteps =
  | ((groupProps: FormMultiStepGroupProps) => ReactNode)
  | ReactNode;

type FormMultiStepGroupComponentProps = {
  indicator?: FC<{
    titles: string[];
    step: number;
    totalSteps: number;
    contextProps: FormMultiStepGroupContextProps;
  }>;
  config: {
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
    data: Array<any>;
    setData: Dispatch<SetStateAction<Array<any>>>;
    quotePubSub?: {
      getQuote: () => CommercialCombinedQuoteResultViewModel;
      setQuote: (data: Partial<CommercialCombinedQuoteResultViewModel>) => void;
      subscribeQuote: (callback: () => void) => () => void;
    };
  };
  steps?: FormMultiStepGroupComponentPropSteps;
  beforeActiveStep?: ReactNode;
  onBeforeNavigate?: (data: Array<any>, direction: 'prev' | 'next') => void;
  onFinish?: () => void | Promise<void>;
  startOver?: () => void;
};

type FormMultiStepGroupComponent = FC<
  HTMLAttributes<HTMLElement> & FormMultiStepGroupComponentProps
>;

type FormMultiStepGroupContextProps = FormMultiStepGroupProps & {
  data: Array<any>;
  setData: Dispatch<SetStateAction<Array<any>>>;
  stepItems: Array<ReactElement<FormMultiStepItemProps>>;
  activeStep: ReactElement<FormMultiStepItemProps>;
  step: number;
  selectedIndexIsFirst: boolean;
  selectedIndexIsLast: boolean;
  stepChanging: (value: any[], direction: 'prev' | 'next') => void;
  quotePubSub?: {
    getQuote: () => CommercialCombinedQuoteResultViewModel;
    setQuote: (data: Partial<CommercialCombinedQuoteResultViewModel>) => void;
    subscribeQuote: (callback: () => void) => () => void;
  };
};

export type MethodSchemaType<T extends any> = (
  multiStepContext: FormMultiStepGroupContextProps | null
) => SchemaOf<T>;

const FormMultiStepGroupContext = createContext<FormMultiStepGroupContextProps>(
  {
    data: [],
    setData: () => {},
    stepItems: [],
    activeStep: { type: '', props: { title: '' }, key: null },
    step: -1,
    selectedIndexIsFirst: false,
    selectedIndexIsLast: false,
    stepChanging: () => {},

    goToStepIndex: () => {},
    prevStep: () => {},
    nextStep: () => {},
    finish: () => {},
    startNavigating: () => {},
    finishNavigating: () => {},
    navigating: false
  }
);

const createFormMultiStepGroupContextProps = (
  data: Array<any>,
  setData: Dispatch<SetStateAction<Array<any>>>,
  stepItems: Array<ReactElement<FormMultiStepItemProps>>,
  activeStep: ReactElement<FormMultiStepItemProps>,
  quotePubSub:
    | {
        getQuote: () => CommercialCombinedQuoteResultViewModel;
        setQuote: (
          data: Partial<CommercialCombinedQuoteResultViewModel>
        ) => void;
        subscribeQuote: (callback: () => void) => () => void;
      }
    | undefined,
  setStep: Dispatch<SetStateAction<number>>,
  onBeforeNavigate:
    | ((data: Array<any>, direction: 'prev' | 'next') => void)
    | undefined,
  onFinish: (() => void | Promise<void>) | undefined,
  step: number,
  numOfSteps: number,
  setNavigating: Dispatch<SetStateAction<boolean>>,
  navigating: boolean
): FormMultiStepGroupContextProps => {
  return {
    data,
    setData,
    stepItems,
    activeStep,
    step,
    selectedIndexIsFirst: step === 1,
    selectedIndexIsLast: step === numOfSteps,
    stepChanging: (value: any[], direction: 'prev' | 'next') => {
      setData(() => {
        if (onBeforeNavigate) {
          onBeforeNavigate(value, direction);
        }
        return value;
      });
    },
    quotePubSub,

    ...createFormMultiStepGroupProps(
      setStep,
      onFinish,
      setNavigating,
      navigating
    )
  };
};

export {
  createFormMultiStepGroupProps,
  createFormMultiStepGroupContextProps,
  FormMultiStepGroupContext
};

export type {
  FormMultiStepGroupProps,
  FormMultiStepGroupComponentPropSteps,
  FormMultiStepGroupComponentProps,
  FormMultiStepGroupComponent,
  FormMultiStepGroupContextProps
};

