import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormLabel,
  FormRadioButtonListBoolean,
  FormTextarea
} from 'components/controls';
import { Heading } from 'components/Heading';
import { optionsCC } from 'config/options';
import { Formik } from 'formik';
import { forwardRef, useMemo } from 'react';
import {
  CommercialCombinedStep18Component,
  commercialCombinedStep18Schema,
  CommercialCombinedStep18ViewModel
} from './Step18.models';
import {
  coverTypeSelectionStepIndex,
  operationsStepIndexEnd,
  startDateStepIndex
} from './index';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { CommercialCombinedStep2ViewModel } from './Step2.models';

const getFieldName = nameOf<CommercialCombinedStep18ViewModel>;

const CommercialCombinedStep18: CommercialCombinedStep18Component = (
  { initialValue },
  ref
) => {
  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep18ViewModel>(
      ref,
      {
        nextStepIndex: () =>
          step2ViewModel.selectedCoverType === 'PLEL'
            ? startDateStepIndex
            : operationsStepIndexEnd + 1
      }
    );

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep18Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Operations" />
        <FormLabel text="Do you carry out and document the following practices?" />
        <ul className="custom-list mb-3">
          {optionsCC.followingPractice.map((detail: string) => (
            <li key={detail} className="mb-3">
              {detail}
            </li>
          ))}
        </ul>
        <FormRadioButtonListBoolean name={getFieldName('followingPractices')} />
        <FormConditionalCollapse
          is={false}
          name={getFieldName('followingPractices')}
        >
          <FormTextarea
            name={getFieldName('followingPracticesDetails')}
            label-subElement={true}
            label-text="Please provide full details"
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep18);
