import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import FormTextBoxPercentage from 'components/controls/form/form-text-box-percentage';
import {
  FormAutocomplete,
  FormControlError,
  FormRadioButtonListGeneric,
  FormTextBoxCurrency
} from 'components/controls';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { optionsCC } from 'config/options';
import { Formik } from 'formik';
import { forwardRef, Fragment, useEffect, useMemo, useState } from 'react';
import {
  CommercialCombinedStep8ActivityViewModel,
  CommercialCombinedStep8Component,
  commercialCombinedStep8Schema,
  CommercialCombinedStep8ViewModel,
  maxNumberOfOccupations
} from './Step8.models';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import {
  businessInterruptionCoverStepIndex,
  coverTypeSelectionStepIndex,
  firstOccupationStepIndex,
  locationsStepIndex,
  riskDetailsStepIndexBegin
} from './index';
import { CommercialCombinedStep1ViewModel } from './Step1.models';
import { DropdownItem } from 'common';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
import { CommercialCombinedStep7ViewModel } from './Step7.models';
const FormAutocompleteNumber = FormAutocomplete<number>();
const FormRadioButtonList = FormRadioButtonListGeneric<number>();

const getFieldName = nameOf<CommercialCombinedStep8ViewModel>;

const CommercialCombinedStep8: CommercialCombinedStep8Component = (
  { initialValue },
  ref
) => {
  const [showingOccupationAutocomplete, setShowingOccupationAutocomplete] =
    useState(false);

  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );
  const Step7ViewModel = useMemo<CommercialCombinedStep7ViewModel>(
    () => multiStepContext.data[businessInterruptionCoverStepIndex],
    [multiStepContext.data]
  );

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep8ViewModel>(ref, {
      prevStepIndex: () =>
        step2ViewModel.selectedCoverType === 'PLEL'
          ? locationsStepIndex
          : riskDetailsStepIndexBegin - 1
    });

  const getActivityFieldNameByIndex = (
    index: number,
    fieldName: Extract<keyof CommercialCombinedStep8ActivityViewModel, string>
  ) => `${getFieldName('activities')}[${index}].${fieldName}`;

  const firstActivityId = Number(
    useFormMultiStepGroupContext().data[firstOccupationStepIndex]?.[
      nameOf<CommercialCombinedStep1ViewModel>('activity')
    ]
  );

  useEffect(() => {
    if (isNaN(firstActivityId) || !formikForm.current) return;

    const firstItem: CommercialCombinedStep8ActivityViewModel = {
      id: firstActivityId,
      name:
        (optionsCC.activities as DropdownItem<number>[]).find(
          (x) => x.value === firstActivityId
        )?.text || '',
      value: null
    };

    const activityLength = formikForm.current.values.activities.length;
    if (activityLength === 0) {
      formikForm.current.setFieldValue(getFieldName('activities'), [firstItem]);
    }

    if (activityLength >= 1) {
      const currentList = formikForm.current.getFieldProps<
        Array<CommercialCombinedStep8ActivityViewModel>
      >(getFieldName('activities')).value;

      if (currentList[0].id !== firstItem.id) {
        const newList = [...currentList];
        newList[0].id = firstItem.id;
        newList[0].name = firstItem.name;

        formikForm.current.setFieldValue(getFieldName('activities'), newList);
      }
    }
  }, [firstActivityId, formikForm]);

  useEffect(() => {
    if (!formikForm.current) return;

    formikForm.current.setFieldValue(
      getFieldName('businessInterruptionCoverSumForValidation'),
      Step7ViewModel?.businessInterruptionCoverAdded
        ? Step7ViewModel.businessInterruptionSum != null
          ? Step7ViewModel.businessInterruptionSum.reduce(
              (a, c) => (a || 0) + (c || 0),
              0
            )
          : undefined
        : undefined
    );
  }, [formikForm, Step7ViewModel]);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep8Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ values: { activities }, getFieldMeta, setFieldValue }) => {
        const metaActivities = getFieldMeta(getFieldName('activities'));
        const validatorMsg = getFieldMeta('validator').error;
        return (
          <>
            <Heading title="Risk Details" />
            <FormControlError msg={validatorMsg} mb={3} />
            <div className="errorParentWrapper mb-4">
              {activities.map((activity, idx) => (
                <Fragment key={activity.id}>
                  <SubHeading
                    title={`Occupation ${idx + 1}: ${activity.name}`}
                    color="primary"
                  />
                  <FormTextBoxPercentage
                    className={`${idx === activities.length - 1 ? 'mb-0' : ''}`}
                    name={getActivityFieldNameByIndex(idx, 'value')}
                    label-text="Occupation Percentage of Total Annual Turnover"
                    forceInvalid={!!metaActivities?.error}
                  />
                  {!showingOccupationAutocomplete &&
                    idx > 0 &&
                    idx === activities.length - 1 && (
                      <div className="text-center mt-4">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            const newActivities = [...activities];
                            newActivities.splice(-1);
                            setFieldValue(
                              getFieldName('activities'),
                              newActivities
                            );
                          }}
                        >
                          Remove Occupation {idx + 1}
                        </button>
                      </div>
                    )}
                </Fragment>
              ))}
              <FormControlError mt={2} mb={0} msg={metaActivities?.error} />
            </div>
            <div className="mb-4">
              {showingOccupationAutocomplete && (
                <div>
                  <SubHeading
                    title={`Occupation ${activities.length + 1}`}
                    color="primary"
                  />
                  <FormAutocompleteNumber
                    label-text="What is your business activity?"
                    label-info={{
                      header: 'Business activity',
                      content: 'What is your business activity?'
                    }}
                    placeholder="Start typing"
                    options={optionsCC.activities}
                    onChange={(_, item) => {
                      if (item) {
                        const newActivities: Array<CommercialCombinedStep8ActivityViewModel> =
                          [...activities];

                        newActivities.push({
                          id: item.value as number,
                          name: item.text,
                          value: null
                        });

                        setFieldValue(
                          getFieldName('activities'),
                          newActivities
                        );
                        setShowingOccupationAutocomplete(false);
                      }
                    }}
                  />
                  <div className="text-center">
                    <button
                      className="btn btn-danger"
                      onClick={() => setShowingOccupationAutocomplete(false)}
                    >
                      Remove Occupation {activities.length + 1}
                    </button>
                  </div>
                </div>
              )}
              {!showingOccupationAutocomplete &&
                activities.length < maxNumberOfOccupations && (
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowingOccupationAutocomplete(true)}
                    >
                      Add another occupation
                    </button>
                  </div>
                )}
            </div>
            {/* {formikProps.values.occupation1TotalAnnual &&
            formikProps.values.occupation1TotalAnnual < 100 && (
              <>
                <SubHeading
                  title={`Occupation 2 ${
                    formikProps.values.occupation2BusinessActivity
                      ? optionsCC.activities
                          .filter(
                            (activity: any) =>
                              activity.value ===
                              formikProps.values.occupation2BusinessActivity
                          )
                          .map((x: any) => {
                            return ': ' + x.text;
                          })
                      : ''
                  }`}
                  color="primary"
                />
                {formikProps.values.occupation2BusinessActivity ? (
                  <FormTextBoxPercentage
                    name="occupation2TotalAnnual"
                    label-text="Occupation Percentage of Total Annual Turnover"
                  />
                ) : (
                  <FormAutocomplete
                    id={getFieldName('occupation2BusinessActivity')}
                    name={getFieldName('occupation2BusinessActivity')}
                    label-text="What is your business activity?"
                    label-info={{
                      header: 'Business activity',
                      content: 'What is your business activity?'
                    }}
                    placeholder="Start typing"
                    options={optionsCC.activities}
                  />
                )}
              </>
            )} */}
            <FormRadioButtonList
              name={getFieldName('insuredTradeTypeId')}
              label-text="Does the insured trade from premises or 100% mobile?"
              options={optionsCC.insuredTradeTypes}
              size={6}
            />
            <FormTextBoxCurrency
              name={getFieldName('annualTurnover')}
              label-text="Annual turnover"
            />
            <FormTextBoxCurrency
              name={getFieldName('annualManualWageRoll')}
              label-text="Annual manual wage roll"
            />
            <FormTextBoxCurrency
              name={getFieldName('annualClericalWageRoll')}
              label-text="Annual clerical wage roll"
            />
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep8);
