import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormControlError,
  FormLabel,
  FormRadioButtonListBoolean
} from 'components/controls';
import FormTextBoxPercentage from 'components/controls/form/form-text-box-percentage';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep11Component,
  commercialCombinedStep11Schema,
  CommercialCombinedStep11ViewModel
} from './Step11.models';

const getFieldName = nameOf<CommercialCombinedStep11ViewModel>;

const CommercialCombinedStep11: CommercialCombinedStep11Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep11ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep11Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ getFieldMeta }) => {
        const validatorMsg = getFieldMeta('validator').error;

        return (
          <>
            <Heading title="Risk Details" />
            <FormRadioButtonListBoolean
              name={getFieldName('outsideUK')}
              label-text="Do you Export any goods outside the UK?"
            />
            <FormConditionalCollapse name={getFieldName('outsideUK')}>
              <div>
                <FormLabel
                  text="Please confirm Percentage of Annual Turnover generated from following locations"
                  subElement={true}
                />

                <FormControlError msg={validatorMsg} mb={3} />
                <FormTextBoxPercentage
                  name={getFieldName('europe')}
                  label-text="Europe"
                  forceInvalid={!!validatorMsg}
                />
                <FormTextBoxPercentage
                  name={getFieldName('usaCanada')}
                  label-text="USA / Canada"
                  forceInvalid={!!validatorMsg}
                />
                <FormTextBoxPercentage
                  name={getFieldName('restOfTheWorld')}
                  label-text="Rest of the world"
                  forceInvalid={!!validatorMsg}
                />
              </div>
            </FormConditionalCollapse>          
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep11);
