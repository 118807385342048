import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { FormConditionalCollapse, FormRadioButtonListBoolean, FormTextarea, FormTextBox } from 'components/controls';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep16Component,
  commercialCombinedStep16Schema,
  CommercialCombinedStep16ViewModel
} from './Step16.models';
const getFieldName = nameOf<CommercialCombinedStep16ViewModel>;
const CommercialCombinedStep16: CommercialCombinedStep16Component = (
  { initialValue },
  ref
) => {
  
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep16ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep16Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Operations" />
        <FormRadioButtonListBoolean
          name={getFieldName('materialsAndServices')}
          label-text="Do you retain the rights of recovery against manufacturers and suppliers of goods, materials and services?"
        />
        <FormConditionalCollapse is={false} name={getFieldName('materialsAndServices')}>
          <FormTextBox name={getFieldName('materialsAndServicesDetails')} label-subElement={true} label-text="Please provide details"/>
        </FormConditionalCollapse>

         <FormRadioButtonListBoolean
          name={getFieldName('importProductOutside')}
          label-text="Do you import any Products and/or raw materials from Outside of the UK, EU or North America?"
          />
        <FormConditionalCollapse name={getFieldName('importProductOutside')}>
          <FormTextarea name={getFieldName('importProductOutsideDetails')} label-subElement={true} label-text="Please provide full details of">
          <ul className="custom-list mb-3">          
            <li className="mb-3">
            what goods,
            </li>
            <li className="mb-3">
            where they are imported from and how
            </li>
            <li className="mb-3">
            much, including details of quality control procedures once imported goods are in the UK
            </li>
        </ul>
        </FormTextarea>
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep16);
