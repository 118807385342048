import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, mixed, number, object, SchemaOf } from 'yup';

export interface CommercialCombinedStep2Props {
  initialValue: CommercialCombinedStep2ViewModel;
}

export type CommercialCombinedStep2Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep2Props
>;

export enum CoverTypes {
  PLEL = 1,
  PC = 2
}

export type SelectedCoverType = 'PLEL' | 'PC' | 'CC';
export type SelectedCoverName =
  | 'Public & Employers Liability'
  | 'Property & Contents'
  | 'Commercial Combined';
export interface CommercialCombinedStep2ViewModel {
  coverTypeIds: Array<number>;
  selectedCoverType?: SelectedCoverType;
  selectedCoverName?: SelectedCoverName;
}

export const commercialCombinedStep2Schema: SchemaOf<CommercialCombinedStep2ViewModel> =
  object().shape({
    coverTypeIds: array()
      .of(number().required())
      .min(1, 'You must select at least one'),
    selectedCoverType: mixed<SelectedCoverType>().oneOf(['PLEL', 'PC', 'CC']),
    selectedCoverName: mixed<SelectedCoverName>().oneOf([
      'Public & Employers Liability',
      'Property & Contents',
      'Commercial Combined'
    ])
  });

export const CommercialCombinedStep2ViewModelInitialValue: CommercialCombinedStep2ViewModel =
  { coverTypeIds: [] };
export const CommercialCombinedStep2ViewModelInitialValueDev: CommercialCombinedStep2ViewModel =
  {
    coverTypeIds: [1, 2],
    selectedCoverType: 'CC',
    selectedCoverName: 'Commercial Combined'
  };

export function useCommercialCombinedStep2InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep2ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep2ViewModelInitialValueDev
        : CommercialCombinedStep2ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, thisStep === currentStep]);
}
