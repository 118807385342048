import moment from 'moment';
export const customValidateDateFormat = 'DD/MM/YYYY';
export const customValidateDateTimeFormat = 'DD/MM/YYYY [at] HH:mm';
export const customValidateDateFormatInput = 'dd/MM/yyyy';

export function getValidDateOrNull(value: string | Date): moment.Moment | null {
  if (typeof value === 'string') {
    const mDate = moment(value, customValidateDateFormat);
    if (mDate.format(customValidateDateFormat) === value) {
      return mDate;
    }
  } else {
    const mDate = moment(value);
    if (mDate.isValid()) {
      return mDate;
    }
  }

  return null;
}

export function formatDate(
  value: moment.Moment | Date | null | undefined
): string {
  if (value == null) {
    return '';
  }

  if (moment.isMoment(value)) {
    return value.format(customValidateDateFormat);
  }

  return moment(value).format(customValidateDateFormat);
}

export function formatDateTime(
  value: moment.Moment | Date | null | undefined
): string {
  if (value == null) {
    return '';
  }

  if (moment.isMoment(value)) {
    return value.format(customValidateDateTimeFormat);
  }

  return moment(value).format(customValidateDateTimeFormat);
}
