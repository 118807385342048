import { formatDate } from 'common/functions/Date';
import { currencyFormat } from 'common/functions/formats/currency';
import { Heading } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CommercialCombinedIndicator from '../Indicator';
import { FormMultiStepGroupContextProps } from 'components/controls/form/form-multi-step-group.models';
import { CommercialCombinedQuoteResultViewModel } from 'features/commercialCombined/quoteResultViewModel';
import { CommercialCombinedStep2ViewModel, CoverTypes } from '../Step2.models';
import {
  useGetConfirmationAsync,
  useGetConfirmationDocsAsync
} from 'features/commercialCombined/confirmation';
import ConfirmationViewModel from 'features/commercialCombined/confirmationViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import { DocumentGenerationStatus } from 'features/commercialCombined/documentGenerationStatusEnum';

export const CommercialCombinedConfirmationIndex = () => {
  const navigate = useNavigate();
  const { guid } = useParams();
  const getConfirmationAsync = useGetConfirmationAsync();
  const getConfirmationDocsAsync = useGetConfirmationDocsAsync();
  const [response, setResponse] = useState<{
    data?: ConfirmationViewModel;
    loading: boolean;
  }>({ loading: true });

  const [docsStatus, setDocsStatus] = useState<DocumentGenerationStatus>(
    DocumentGenerationStatus.Error
  );

  const policyNumber = response.data?.policyNumber || '';
  const quotePrice = response.data?.quotePrice || 0;
  const hasTaxes = response.data?.hasTaxes || false;
  const quotePriceExclTaxes = response.data?.quotePriceExclTaxes || 0;

  useEffect(() => {
    let timeoutFunction: NodeJS.Timeout | null = null;
    async function getDocsAsync() {
      if (!guid) return;

      const docResponse = await getConfirmationDocsAsync(guid);
      if (!docResponse) return;

      setDocsStatus(docResponse.generationStatusID);
      if (
        docResponse.generationStatusID === DocumentGenerationStatus.Generated &&
        docResponse.documents
      ) {
        setResponse((resp) => {
          const newResp = { ...resp };
          if (newResp.data) {
            newResp.data.documents = docResponse.documents;
          }
          return newResp;
        });
      }

      if (docResponse.generationStatusID === DocumentGenerationStatus.Pending) {
        timeoutFunction = setTimeout(() => {
          getDocsAsync();
        }, 5000);
      }
    }
    async function get() {
      const resp = await getConfirmationAsync(guid || '');
      if (!resp) {
        navigate('/');
        return;
      }
      setResponse({ data: resp, loading: false });
      setQuoteResult({ refer: false, quotePrice: resp.quotePrice });
      if (resp.documents) {
        const hasError =
          resp.documents.findIndex(
            (x) => x.generationStatusID === DocumentGenerationStatus.Error
          ) >= 0;
        if (hasError) {
          setDocsStatus(DocumentGenerationStatus.Error);
        } else {
          const hasPending =
            resp.documents.findIndex(
              (x) => x.generationStatusID === DocumentGenerationStatus.Pending
            ) >= 0;
          if (hasPending) {
            setDocsStatus(DocumentGenerationStatus.Pending);
            timeoutFunction = setTimeout(getDocsAsync, 2000);
          } else {
            setDocsStatus(DocumentGenerationStatus.Generated);
          }
        }
      }
    }
    get();
    return () => {
      if (timeoutFunction) clearTimeout(timeoutFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [quoteResult, setQuoteResult] =
    useState<CommercialCombinedQuoteResultViewModel>({
      refer: false,
      quotePrice: null
    });

  const getQuote = useCallback<() => CommercialCombinedQuoteResultViewModel>(
    () => quoteResult,
    [quoteResult]
  );

  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => ({
      coverTypeIds: response.data?.coverTypeIds || [],
      selectedCoverType:
        (response.data?.coverTypeIds?.length || 0) === 1
          ? response.data!.coverTypeIds![0] === CoverTypes.PLEL
            ? 'PLEL'
            : 'PC'
          : 'CC',
      selectedCoverName:
        (response.data?.coverTypeIds?.length || 0) === 1
          ? response.data!.coverTypeIds![0] === CoverTypes.PLEL
            ? 'Public & Employers Liability'
            : 'Property & Contents'
          : (response.data?.coverTypeIds?.length || 0) === 2
          ? 'Commercial Combined'
          : undefined
    }),
    [response.data]
  );

  const contextProps: FormMultiStepGroupContextProps = useMemo(
    () => ({
      data: [null, step2ViewModel],
      setData: () => {},
      stepItems: [],
      activeStep: { type: '', props: { title: '' }, key: null },
      step: -1,
      selectedIndexIsFirst: false,
      selectedIndexIsLast: false,
      stepChanging: () => {},
      quotePubSub: {
        getQuote,
        setQuote: () => {},
        subscribeQuote: () => () => {}
      },

      goToStepIndex: () => {},
      prevStep: () => {},
      nextStep: () => {},
      finish: () => {},
      startNavigating: () => {},
      finishNavigating: () => {},
      navigating: false
    }),
    [getQuote, step2ViewModel]
  );

  if (!guid) {
    navigate('/');
    return null;
  }

  return (
    <>
      <CommercialCombinedIndicator
        titles={['']}
        step={29}
        totalSteps={29}
        contextProps={contextProps}
      />
      {response.loading && (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
      {!response.loading && (
        <div className="container container-body-sm">
          <Heading title="Congratulations" />
          <p className="mb-0 h4">
            <strong>Policy number</strong>
          </p>
          <p className="h5 fw-normal">{policyNumber}</p>
          <div className="bg-light rounded-5 p-3 mb-4">
            <div className="text-center">
              <div className="fw-bold h2 text-secondary my-2">
                {currencyFormat(quotePrice)}{' '}
                <span className="small">/year</span>{' '}
              </div>
              <p className="fw-medium">
                Your {step2ViewModel?.selectedCoverName} Quote
              </p>
            </div>
            <div className="border-top border-secondary border-dashed pt-3 mb-3">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td>
                      Your price
                      {hasTaxes
                        ? ` (incl. ${response.data?.taxNames || ''})`
                        : ''}
                    </td>
                    <td className="text-end">{currencyFormat(quotePrice)}</td>
                  </tr>
                  {hasTaxes && (
                    <>
                      <tr>
                        <td>Premium (excl. {response.data?.taxNames})</td>
                        <td className="text-end">
                          {currencyFormat(quotePriceExclTaxes)}
                        </td>
                      </tr>
                      {response.data!.taxes!.map((t, tI) => (
                        <tr key={tI}>
                          <td>{t.name}</td>
                          <td className="text-end">
                            {currencyFormat(t.value)}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {!!response.data?.excesses?.length && (
              <div className="border-top border-secondary border-dashed pt-3 mb-3">
                <table className="w-100">
                  <tbody>
                    {response.data!.excesses!.map((e, idx) => (
                      <tr key={`e${idx}`}>
                        <td>{e.name}</td>
                        <td className="text-end">
                          {currencyFormat(e.value, 0)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="h2">Summary</div>
          <ul className="list-unstyled">
            <li className="mb-3">
              Start date
              <br />
              {formatDate(response.data?.startDate)}
            </li>
            <li className="mb-3">
              End date
              <br />
              {formatDate(response.data?.endDate)}
            </li>
          </ul>
          {docsStatus !== DocumentGenerationStatus.Error &&
            response.data?.documents?.length && (
              <>
                <div className="h2">Documents</div>
                {docsStatus === DocumentGenerationStatus.Pending && (
                  <div>Generating documents...</div>
                )}
                {docsStatus === DocumentGenerationStatus.Generated && (
                  <ul className="list-unstyled mb-4">
                    {response.data!.documents!.map((d, dI) => (
                      <li key={`doc${dI}`}>
                        <div className="d-flex align-items-center mb-2">
                          <DescriptionOutlinedIcon className="me-2" />
                          <a
                            href={d.downloadURL}
                            rel="noreferrer"
                            className="text-body"
                          >
                            {d.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
        </div>
      )}
    </>
  );
};
