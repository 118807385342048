import {
  FC,
  LabelHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Info } from '@mui/icons-material';
import {
  addPrefixToObject,
  getSeparationFunction
} from 'common/functions/PrefixHelper';
import { Button, Modal } from 'react-bootstrap';

type FormLabelPropsInt = PropsWithChildren<
  LabelHTMLAttributes<HTMLLabelElement> & {
    text?: ReactNode;
    icon?: string;
    info?: InfoType;
    subElement?: boolean;
  }
>;
export type InfoType = {
  header: string;
  content: string;
  modal?: boolean;
};
type FormLabelComponent = PropsWithChildren<FormLabelPropsInt>;

const FormLabel: FC<FormLabelComponent> = ({
  text,
  children,
  icon,
  info,
  subElement,
  ...otherProps
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const popover: (infoData: InfoType) => ReactElement = (infoData) => (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{infoData.header}</Popover.Header>
      <Popover.Body>
        <div dangerouslySetInnerHTML={{ __html: infoData.content }} />
      </Popover.Body>
    </Popover>
  );

  if (children) {
    return <>{children}</>;
  } else {
    return text ? (
      <div
        className={`fw-semibold mb-3 d-flex align-items-center ${
          subElement ? 'label-border' : ''
        }`}
      >
        <label {...otherProps}>
          <span>{text}</span>
        </label>
        {info &&
          (info.modal ? (
            <>
              <Button
                variant=""
                onClick={handleShow}
                className="ms-auto text-secondary p-0 border-0 bg-transparent"
              >
                <Info sx={{ fontSize: 20 }} />
              </Button>
              <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>{info.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div dangerouslySetInnerHTML={{ __html: info.content }} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <OverlayTrigger trigger="focus" overlay={popover(info)}>
              <Button
                onClick={(e) => e.currentTarget.focus()}
                variant=""
                className="ms-auto text-secondary p-0 border-0 bg-transparent"
              >
                <Info sx={{ fontSize: 20 }} />
              </Button>
            </OverlayTrigger>
          ))}
      </div>
    ) : null;
  }
};

FormLabel.displayName = 'FormLabel';
export default FormLabel;

export type FormLabelProps = addPrefixToObject<FormLabelPropsInt, 'label-'>;
export const separateFormLabelProps = getSeparationFunction<
  FormLabelPropsInt,
  'label-'
>('label-');
