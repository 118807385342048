import { AnySchema } from 'yup';

export function requiredIf<T extends AnySchema>(
  schemaType: T | ((...arg: any[]) => T),
  propertyName: string | string[],
  is?: any | ((...values: any[]) => boolean)
) {
  return (typeof schemaType === 'function' ? schemaType() : schemaType)
    .nullable()
    .when(Array.isArray(propertyName) ? propertyName : [propertyName], {
      is: (...args: any[]) =>
        typeof is === 'function'
          ? is(...args)
          : args.every((arg) => (is === undefined ? !!arg : arg === is)),
      then: (schema: T) => schema.required()
    });
}

export function requiredIfTyped<VM extends any = any>() {
  return <T extends AnySchema>(
    schemaType: T | ((...arg: any[]) => T),
    propertyName: Extract<keyof VM, string> | Extract<keyof VM, string>[],
    is?: any | ((...values: any[]) => boolean)
  ) => requiredIf<T>(schemaType, propertyName, is);
}

type blockTShapeFunctionResultType<T extends AnySchema> = [T, (schema: T) => T];
type blockTShapeType<T extends AnySchema> =
  | T
  | (() => blockTShapeFunctionResultType<T>);

export function requiredIfBlock<
  TShape extends Record<string, blockTShapeType<Y>>,
  Y extends AnySchema = AnySchema
>(
  objectShape: TShape,
  propertyName: string | string[],
  is?: any | ((...values: any[]) => boolean),
  then?: (key: string) => ((schema: Y) => Y) | undefined
) {
  const oRet: { [prop in keyof TShape]: any } = { ...objectShape };

  for (let key in objectShape) {
    const objectProp: blockTShapeType<Y> = objectShape[key];

    let baseSchema: Y;
    if (typeof objectProp === 'function') {
      baseSchema = objectProp()[0];
    } else {
      baseSchema = objectProp;
    }

    let funcObjectProp: blockTShapeFunctionResultType<Y> | undefined =
      typeof objectProp === 'function' ? objectProp() : undefined;

    oRet[key] = baseSchema.when(
      Array.isArray(propertyName) ? propertyName : [propertyName],
      {
        is: (...args: any[]) =>
          typeof is === 'function'
            ? is(...args)
            : args.every((arg) => (is === undefined ? !!arg : arg === is)),
        then: (schema) => {
          let applyRequiredIfNothing = true;
          if (typeof objectProp === 'function' && funcObjectProp?.length) {
            schema = funcObjectProp[1](schema);
            applyRequiredIfNothing = false;
          }

          const customThem = then !== undefined ? then(key) : undefined;

          if (customThem) {
            return customThem(schema);
          }

          if (!applyRequiredIfNothing) {
            return schema;
          }

          return schema.required();
        }
      }
    );
  }

  return oRet;
}

export function requiredIfBlockTyped<VM extends any = any>() {
  return <
    TShape extends Record<string, blockTShapeType<Y>>,
    Y extends AnySchema = AnySchema
  >(
    objectShape: TShape,
    propertyName: Extract<keyof VM, string> | Extract<keyof VM, string>[],
    is?: any | ((...values: any[]) => boolean),
    then?: (key: Extract<keyof VM, string>) => ((schema: Y) => Y) | undefined
  ) =>
    requiredIfBlock<TShape, Y>(objectShape, propertyName, is, (key) =>
      then ? then(key as Extract<keyof VM, string>) : undefined
    );
}
