import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormCheckBox,
  FormConditionalCollapse,
  FormControlError,
  FormRadioButtonListBoolean,
  FormTextBox,
  FormTextBoxNumber
} from 'components/controls';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep9Component,
  commercialCombinedStep9Schema,
  CommercialCombinedStep9ViewModel
} from './Step9.models';

const getFieldName = nameOf<CommercialCombinedStep9ViewModel>;

const CommercialCombinedStep9: CommercialCombinedStep9Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep9ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep9Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ getFieldMeta, values: { paye, payeERNExempt }, setFieldValue }) => {
        const validatorMsg = getFieldMeta('validator').error;
        return (
          <>
            <Heading title="Risk Details" />

            <FormControlError msg={validatorMsg} mb={3} />
            <FormTextBoxNumber
              name={getFieldName('numberOfManualEmployees')}
              label-text="Number of Manual Employees Including Principals / Directors & Partners"
            />
            <FormTextBoxNumber
              name={getFieldName('numberOfClericalEmployees')}
              label-text="Number of Clerical Employees Including Principals / Directors & Partners"
            />
            <FormRadioButtonListBoolean
              name={getFieldName('paye')}
              label-text="Do you operate a PAYE system for your employees?"
            />
            <FormConditionalCollapse show={paye}>
              <div>
                <FormTextBox
                  name={getFieldName('payeERN')}
                  label-subElement
                  label-text="Employers Reference Number (ERN)"
                  placeholder="123/XX12345"
                  disabled={payeERNExempt}
                />
                <FormCheckBox
                  name={getFieldName('payeERNExempt')}
                  label-text="ERN exempt"
                  onChange={() => {
                    setFieldValue(getFieldName('payeERN'), '');
                  }}
                />
              </div>
            </FormConditionalCollapse>
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep9);
