import { getIn, useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { FormControlError, FormTextBox, FormTextBoxPostcode } from './controls';
import {
  PostcodeLookupComponent,
  PostcodeLookupResponseItemViewModel,
  PostcodeLookupResponseViewModel,
  PostcodeLookupRetrieveItemViewModel,
  postcodeLookupSchema,
  PostcodeLookupViewModel
} from './postcode-lookup.models';
import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import { postcodeResults } from 'features/postcode/postcode-response';
import { Backdrop, CircularProgress } from '@mui/material';
import { ValidationError } from 'yup';
import nameOf from 'common/functions/NameOfHelper';

const ResultItem: FC<{
  item: PostcodeLookupResponseItemViewModel;
  onClick?: () => void;
}> = ({ item, onClick }) => {
  return (
    <li
      onClick={() => onClick && onClick()}
      role="button"
      className="list-group-item postcode-item"
    >
      {item.text}
    </li>
  );
};

function mappedPostcodeResults() {
  return (postcodeResults.Item as any[]).map<
    PostcodeLookupResponseItemViewModel & PostcodeLookupRetrieveItemViewModel
  >((x) => {
    const breakDown = ((x.List as string) || '')
      .split(',')
      .map((x) => x.trim());     
    return {
      value: x.Key as string,
      text: x.List as string,
      postcode: x.Postcode as string,
      address1: breakDown[0],
      address2: breakDown[1],
      county: breakDown[2]
    };
  });
}

const PostcodeLookup: PostcodeLookupComponent = ({ name }) => {
  const { loading, getAsync } = useAxiosRequestManager();
  const { loading: loadingRetrieve, getAsync: getAsyncRetrieve } =
    useAxiosRequestManager();

  const [hasLookedUp, setHasLookedUp] = useState(false);
  const [lastActionWasLookup, setLastActionWasLookup] = useState(false);
  const [lookupResults, setLookupResults] = useState<
    PostcodeLookupResponseItemViewModel[]
  >([]);
  const { showAddressResultList, showAddressNotFound } = useMemo(
    () => ({
      showAddressResultList: !lastActionWasLookup && lookupResults.length > 0,
      showAddressNotFound: lastActionWasLookup && lookupResults.length === 0
    }),
    [lastActionWasLookup, lookupResults.length]
  );
  const getFieldName = useCallback(
    (field: Extract<keyof PostcodeLookupViewModel, string>) =>
      `${name}.${field}`,
    [name]
  );

  const {
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    validateOnChange
  } = useFormikContext<any>();

  const model = useMemo<PostcodeLookupViewModel>(() => {
    return getIn(values, name);
  }, [values, name]);

  async function getErrorMsgAsync(
    fieldName: Extract<keyof PostcodeLookupViewModel, string>,
    value?: Partial<PostcodeLookupViewModel>,
    type?: string | string[]
  ): Promise<string | undefined> {
    const abortEarly = Array.isArray(type)
      ? type.length === 0
      : !(type || '').trim();

    const result = await postcodeLookupSchema
      .validateAt(
        fieldName,
        {
          ...model,
          ...(value || {})
        },
        {
          abortEarly: abortEarly
        }
      )
      .then((a) => undefined)
      .catch((err) => {
        if (ValidationError.isError(err)) {
          if (!abortEarly) {
            if (Array.isArray(type)) {
              type.forEach((typeName) => {
                const innerItem = err.inner.find((x) => x.type === typeName);
                if (innerItem) {
                  return innerItem.message;
                }
              });
              return undefined;
            }

            return err.inner.find((x) => x.type === (type || ''))?.message;
          }

          return err.message;
        }
        return err as string;
      });

    return result;
  }

  async function lookup() {
    setFieldValue(getFieldName('showAddress'), false);

    let result: Array<PostcodeLookupResponseItemViewModel> = [];
    try {
      result =
        (
          await getAsync<PostcodeLookupResponseViewModel>(
            `/postcodes/lookup/${encodeURIComponent(
              model.postcode.replaceAll(' ', '')
            )}`,
            null,
            {
              result: mappedPostcodeResults()
                .filter(
                  (x) =>
                    x.postcode.replaceAll(' ', '') ===
                    model.postcode.replaceAll(' ', '')
                )
                .map<PostcodeLookupResponseItemViewModel>((x) => x)
            },
            {
              validateStatus: (status) =>
                (status >= 200 && status < 300) || status === 404
            }
          )
        ).data?.result || [];
    } catch {}

    setHasLookedUp(true);
    setLastActionWasLookup(result.length === 0);
    setLookupResults(result);
  }

  async function resultItemClickHandler(
    item: PostcodeLookupResponseItemViewModel
  ) {
    const { data } =
      await getAsyncRetrieve<PostcodeLookupRetrieveItemViewModel>(
        `/postcodes/retrieve/${encodeURIComponent(item.value)}`,
        null,
        mappedPostcodeResults().find((x) => x.value === item.value) || null
      );

    setLookupResults([]);

    if (data !== null) {
      const postcodeLookupResult: Partial<PostcodeLookupViewModel> = {
        postcode: data.postcode,
        address1: data.address1,
        address2: data.address2,
        town: data.town,
        city: data.city,
        county: data.county,
        showAddress: true
      };

      setFieldValue(name, { ...model, ...postcodeLookupResult });
    }
  }
  const pcList = useRef<HTMLDivElement>(null);
  const [pcPos, setPcPos] = useState(String);
  useEffect(() => {
    setPcPos('100%');
    if (pcList.current) {
      if (pcList.current?.getBoundingClientRect().bottom > window.innerHeight) {
        setPcPos(`-${pcList.current?.getBoundingClientRect().height - 30}px`);
      }
    }
  }, [showAddressResultList]);

  return (
    <div className="position-relative mb-4">
      <FormTextBoxPostcode
        name={getFieldName('postcode')}
        loading={loading}
        onChange={async (value: string) => {
          setLastActionWasLookup(false);

          if (!validateOnChange && hasLookedUp) {
            setFieldError(
              getFieldName('postcode'),
              await getErrorMsgAsync(
                'postcode',
                {
                  [nameOf<PostcodeLookupViewModel>('postcode')]: value
                },
                'required'
              )
            );
            setFieldTouched(getFieldName('postcode'), false, false);
          }
        }}
        onLookup={async () => {
          setLastActionWasLookup(false);

          const errMsg = await getErrorMsgAsync(
            'postcode',
            undefined,
            'required'
          );
          setFieldError(getFieldName('postcode'), errMsg);
          setFieldTouched(getFieldName('postcode'), false, false);
          setLookupResults([]);

          if (!errMsg) {
            lookup();
          }
        }}
      >
        {lookupResults.length === 0 && !model.showAddress && (
          <div className="text-center mt-2">
            <a
              href="/"
              className="link link-primary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                const resetModel: Partial<PostcodeLookupViewModel> = {
                  address1: '',
                  address2: '',
                  town: '',
                  city: '',
                  county: '',                
                  showAddress: true
                };

                setFieldValue(name, { ...model, ...resetModel });
              }}
            >
              Enter manually
            </a>
          </div>
        )}
        {hasLookedUp && !model.showAddress && (
          <>
            {showAddressNotFound && (
              <FormControlError msg="Address not found" />
            )}
            {showAddressResultList && (
              <div
                ref={pcList}
                className="mt-3 rounded-4 border overflow-hidden w-100"
                style={{ top: pcPos }}
              >
                <ul
                  className="list-group list-group-flush"
                  style={{ maxHeight: '250px', overflowY: 'auto' }}
                >
                  {lookupResults.map((item, rIdx) => (
                    <ResultItem
                      key={rIdx}
                      item={item}
                      onClick={() => resultItemClickHandler(item)}
                    />
                  ))}
                </ul>
                <Backdrop
                  open={loadingRetrieve}
                  sx={{
                    position: 'absolute',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                  }}
                  transitionDuration={loadingRetrieve ? 0 : 300}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            )}
          </>
        )}
      </FormTextBoxPostcode>

      <Collapse in={model.showAddress} timeout={model.showAddress ? 300 : 0}>
        <div>
          <FormTextBox
            label-text="House No/Building Name"
            name={getFieldName('address1')}
          />
          <FormTextBox label-text="Street" name={getFieldName('address2')} />
          <FormTextBox label-text="Town" name={getFieldName('town')} />
          <FormTextBox label-text="City" name={getFieldName('city')} />
          <FormTextBox label-text="County" name={getFieldName('county')} />
        </div>
      </Collapse>
      <Backdrop
        open={loading}
        sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
        transitionDuration={loading ? 0 : 300}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default PostcodeLookup;
