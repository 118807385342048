import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import moment from 'moment';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, number, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep21Props {
  initialValue: CommercialCombinedStep21ViewModel;
}

export type CommercialCombinedStep21Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep21Props
>;

export interface CommercialCombinedStep21ViewModel {
  locations: Array<CommercialCombinedStep21ItemViewModel>;
}

export interface CommercialCombinedStep21ItemViewModel {
  buildingOldestPart?: number | null;
  detached?: boolean | null;
  detachedDetails?: string | null;
}

const commercialCombinedStep21ItemSchema: SchemaOf<CommercialCombinedStep21ItemViewModel> =
  object({
    buildingOldestPart: number()
      .nullable()
      .required()
      .min(1000)
      .max(moment().year())
      .label('Approximate date the oldest part of the buildings'),
    detached: boolean().nullable().required(),
    detachedDetails: requiredIf(
      string().nullable().label('It'),
      'detached',
      (detached: boolean) => !detached
    )
  });
export const commercialCombinedStep21Schema: SchemaOf<CommercialCombinedStep21ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep21ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep21ViewModelInitialValue: CommercialCombinedStep21ViewModel =
  { locations: [] };
export const CommercialCombinedStep21ViewModelInitialValueDev: CommercialCombinedStep21ViewModel =
  {
    locations: [
      {
        buildingOldestPart: 2000,
        detached: true
      }
    ]
  };

export function useCommercialCombinedStep21InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep21ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep21ViewModelInitialValueDev
        : CommercialCombinedStep21ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
