import { PropsWithChildren, FC } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField } from 'formik';

type FormTextBoxProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      multiline?: number;
    }
>;

type FormTextBoxComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormTextarea: FormTextBoxComponent<FormTextBoxProps> = (props) => {
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    inputOtherWrapperClassName,
    placeholder,
    multiline,
    children,
    ...otherProps
  } = splitProps[0];
  const [labels, others] = filterChildren(children, 'FormLabel');
  const [{ value, ...field }, meta] = useField(props);

  const placeholderFix = () => {
    if (!placeholder || !placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? labels : <FormLabel htmlFor={name} {...splitProps[1]} />}
      {others}
      {inputOtherWrapperClassName ? (
        <div
          className={`${inputOtherWrapperClassName} ${
            meta?.error ? 'is-invalid' : ''
          }`}
        >
          <textarea
            id={id}
            rows={multiline || 5}
            placeholder={placeholderFix()}
            className={`form-control ${meta?.error ? 'is-invalid' : ''}`}
            value={value || ''}
            {...field}
          />
        </div>
      ) : (
        <>
          <textarea
            id={id}
            rows={multiline || 5}
            placeholder={placeholderFix()}
            className={`form-control ${meta?.error ? 'is-invalid' : ''}`}
            value={value || ''}
            {...field}
          />
        </>
      )}
      <FormControlError msg={meta?.error} />
    </FormControlWrapper>
  );
};
FormTextarea.Label = FormLabel;

FormTextarea.displayName = 'FormTextarea';
export default FormTextarea;
