import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading, SubHeading } from 'components';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean,
  FormTextarea
} from 'components/controls';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep25Component,
  CommercialCombinedStep25ItemViewModel,
  commercialCombinedStep25Schema,
  CommercialCombinedStep25ViewModel
} from './Step25.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';
import {
  premisesExtraQuestionsStepIndexBegin,
  premisesExtraQuestionsStepIndexEnd
} from '.';

const CommercialCombinedStep25: CommercialCombinedStep25Component = (
  { initialValue },
  ref
) => {
  const { getFieldName, locationHeadingText, goPrevNextAndReturnIfMoved } =
    usePremisesExtraQuestionsHelper<
      CommercialCombinedStep25ItemViewModel,
      CommercialCombinedStep25ViewModel
    >();

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep25ViewModel>(
      ref,
      {
        nextStepIndex: (data) =>
          goPrevNextAndReturnIfMoved('next', data)
            ? premisesExtraQuestionsStepIndexBegin
            : premisesExtraQuestionsStepIndexEnd + 1
      }
    );

  if (!locationHeadingText) return null;

  const declarations = [
    'Have the buildings had any occurrence of subsidence, ground heave or landslip?',
    'Are you aware of any signs of damage to the buildings which may be attributable to subsidence, ground heave or landslip?',
    'Are the buildings being, or have they ever been monitored for subsidence, ground heave or landslip?',
    'Are you aware of any neighbouring property having been damaged by subsidence, ground heave or landslip?',
    'Has any survey or inspection mentioned settlement or movement if the buildings?',
    'Has the premises been subject to any river or coastal erosion?',
    'Have trees or shrubs within 7 metres of the buildings which are more than 5 metres tall?',
    'Have known history or signs that the foundations have been repaired or underpinned?',
    'Are in the immediate vicinity of any river bank, railway embankment or cutting, cliff, quarry, mine, or other underground working or made up ground?'
  ];

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep25Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <FormRadioButtonListBoolean
          name={getFieldName('subsidenceGroundHeaveLandslipCoverAdded')}
          label-text="Is Subsidence, Ground Heave and Landslip cover requested?"
        />
        <FormConditionalCollapse
          name={getFieldName('subsidenceGroundHeaveLandslipCoverAdded')}
        >
          <>
            <FormRadioButtonListBoolean
              name={getFieldName(
                'subsidenceGroundHeaveLandslipCoverAddedRestrictionsApply'
              )}
              label-text="Do any of these apply?"
              controlsBtwLabelInput={() => (
                <ul className="custom-list mb-3">
                  {declarations.map((d, idx) => (
                    <li key={idx} className="mb-3">
                      {d}
                    </li>
                  ))}
                </ul>
              )}
            />
            <FormConditionalCollapse
              name={getFieldName(
                'subsidenceGroundHeaveLandslipCoverAddedRestrictionsApply'
              )}
            >
              <FormTextarea
                name={getFieldName(
                  'subsidenceGroundHeaveLandslipCoverAddedRestrictionsApplyDetails'
                )}
                label-subElement={true}
                label-text="Please provide full details"
              />
            </FormConditionalCollapse>
          </>
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep25);
