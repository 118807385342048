import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean,
  FormTextarea
} from 'components/controls';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep17Component,
  commercialCombinedStep17Schema,
  CommercialCombinedStep17ViewModel
} from './Step17.models';

const getFieldName = nameOf<CommercialCombinedStep17ViewModel>;

const CommercialCombinedStep17: CommercialCombinedStep17Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep17ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep17Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Operations" />
        <FormRadioButtonListBoolean
          name={getFieldName('identifyTheCustomer')}
          label-text="With regard to goods supplied, are records kept from the date of supply, which identify the customer to whom individual products or batches of products have been sent or received?"
        />
        <FormConditionalCollapse
          is={false}
          name={getFieldName('identifyTheCustomer')}
        >
          <FormTextarea
            name={getFieldName('reasonWhy')}
            label-subElement={true}
            label-text="Please provide the reason why"
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep17);
