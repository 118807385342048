export function fixPostcodeValue(value: string | null): string | null {
  if (!value) {
    return value;
  }
  let newValue = value.trim().toUpperCase();

  newValue = newValue.replace(/[^\da-z] /gi, '');
  if (newValue.match(/([ ]*[0-9][a-zA-Z])(?!.*[0-9][a-zA-Z])/)) {
    newValue = newValue.replace(/[ ]/g, '');
    newValue = newValue.replace(/([0-9][a-zA-Z])(?!.*[0-9][a-zA-Z])/g, ' $1');
  }

  return newValue;
}
