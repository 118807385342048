import { InfoType } from 'components/controls/form/form-label';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, number, object, SchemaOf, string } from 'yup';
import { commercialCombinedStep5ViewModelInitialValueDev } from './Step5.models';

export type ValuesType = Array<
  Array<number | null | undefined> | null | undefined
>;

export interface CommercialCombinedStep6Props {
  initialValue: CommercialCombinedStep6ViewModel;
}

export type CommercialCombinedStep6Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep6Props
>;

export interface CommercialCombinedStep6ViewModel {
  coverDetailsItems: CommercialCombinedStep6CoverDetailsItemViewModel[];
}

export interface CommercialCombinedStep6CoverDetailsItemViewModel {
  id: number;
  title: string;
  info?: InfoType;
  added: boolean;
  values: ValuesType;
}

const infoTypeSchema: SchemaOf<InfoType> = object({
  header: string().required(),
  content: string().required(),
  modal: boolean()
});

const commercialCombinedStep6CoverDetailsItemViewModelSchema: SchemaOf<CommercialCombinedStep6CoverDetailsItemViewModel> =
  object({
    id: number().required(),
    title: string().required(),
    info: infoTypeSchema.default(undefined),
    added: boolean()
      .required()
      .test(
        'commercialCombinedStep6CoverDetailsItemViewModelSchema-One-Value',
        '',
        (added, ctx) => {
          if (!added) {
            return true;
          }

          const isValid = ((ctx.parent.values || []) as ValuesType).some((av) =>
            (av || []).some((v) => (v || 0) > 0)
          );

          if (!isValid) {
            return ctx.createError({
              message: 'You must enter at least one value greater than zero'
            });
          }
          return true;
        }
      ),
    values: array()
      .of(array().of(number().nullable()).nullable())
      .default([])
      .defined()
      .required()
  });

const coverDetailsItems: CommercialCombinedStep6CoverDetailsItemViewModel[] = [
  {
    id: 1,
    title: 'Building cover',
    info: {
      header: 'Building cover',
      content:
        'Please enter the current rebuild value for the buildings if cover is required'
    },
    added: false,
    values: []
  },
  {
    id: 2,
    title: 'Contents/Plant & Machinery',
    added: false,
    values: []
  },
  { id: 13, title: 'Tenants Improvement', added: false, values: [] },
  { id: 14, title: 'Rent payable', added: false, values: [] },
  { id: 8, title: 'Computer/Office Equipment', added: false, values: [] },
  { id: 15, title: 'Glass Sum Insured', added: false, values: [] },
  { id: 3, title: 'Stock in Trade', added: false, values: [] },
  { id: 5, title: 'Portable Hand Tools', added: false, values: [] },
  { id: 4, title: 'Wine/Spirits/Tobacco', added: false, values: [] },
  { id: 7, title: 'Portacabins', added: false, values: [] },
  { id: 11, title: 'Vehicles at premises', added: false, values: [] }
];

export const commercialCombinedStep6Schema: SchemaOf<CommercialCombinedStep6ViewModel> =
  object({
    coverDetailsItems: array().of(
      commercialCombinedStep6CoverDetailsItemViewModelSchema
    )
  });

export const commercialCombinedStep6ViewModelInitialValue: CommercialCombinedStep6ViewModel =
  {
    coverDetailsItems
  };
export const commercialCombinedStep6ViewModelInitialValueDev: CommercialCombinedStep6ViewModel =
  {
    coverDetailsItems: [
      {
        ...coverDetailsItems[0],
        ...{
          added: true,
          values: commercialCombinedStep5ViewModelInitialValueDev.locations.map(
            (_, i) => (i === 0 ? [250000] : undefined)
            // () => undefined
          )
        }
      },
      ...[...coverDetailsItems].slice(1)
    ]
  };

export function useCommercialCombinedStep6InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep6ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? commercialCombinedStep6ViewModelInitialValueDev
        : commercialCombinedStep6ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
