import { FC, ReactNode } from 'react';

const FormFieldReadonly: FC<{
  title?: ReactNode | null;
  value?: ReactNode | null;
  valueIfUndefined?: ReactNode | null;
}> = ({ title, value, valueIfUndefined }) => {
  if (!value && !valueIfUndefined) {
    return null;
  }
  return (
    <dl className="form-group mb-4">
      <dt className="mb-1">{title}</dt>
      <dd className="m-0">{value || valueIfUndefined}</dd>
    </dl>
  );
};

export default FormFieldReadonly;
