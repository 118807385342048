import { useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAxiosRequestManagerContext } from './AxiosRequestManagerContext';
import { useMockedResponse, devForceDebug } from 'config/index';
import mockAxiosResponseAsync, {
  AxiosResponseType
} from './AxiosRequestManagerMock';

function fixConfig(
  config: AxiosRequestConfig | undefined,
  queryString?: { [prop: string]: string } | null
): AxiosRequestConfig | undefined {
  const hasConfig = config && Object.keys(config).length > 0;
  const hasQueryString = queryString && Object.keys(queryString).length > 0;

  if (!hasQueryString && !hasConfig) {
    return undefined;
  }

  const hasParams = (hasConfig && config.params) || hasQueryString;
  return {
    ...(config || {}),
    ...{
      params: !hasParams
        ? undefined
        : { ...(config?.params || {}), ...(queryString || {}) }
    }
  };
}

const useAxiosRequestManager = (props?: {
  loadingInitialState?: boolean;
  forceMocked?: boolean;
  delay?: number;
}) => {
  const [loading, setLoading] = useState(!!props?.loadingInitialState);
  const { incRequest, decRequest, setErrors, resetState, defaultDelay } =
    useAxiosRequestManagerContext();

  const instance = axios.create();

  async function getAsync<T extends AxiosResponseType>(
    url: string,
    queryString: { [prop: string]: string } | undefined | null,
    mockedResponse: T | null,
    config?: AxiosRequestConfig,
    setOnError = true
  ): Promise<AxiosResponse<T | null>> {
    resetState();

    const resolvedConfig = fixConfig(config, queryString);
    try {
      setLoading(true);
      incRequest();

      let oRet: AxiosResponse<T | null>;
      if (
        !(props?.forceMocked === false) &&
        (useMockedResponse || props?.forceMocked === true)
      ) {
        oRet = await mockAxiosResponseAsync(
          'GET',
          instance.getUri(
            Object.assign(resolvedConfig || {}, {
              url
            })
          ),
          undefined,
          mockedResponse,
          !!devForceDebug,
          props?.delay ?? defaultDelay
        );
      } else {
        oRet = await instance.get<T>(url, resolvedConfig);
      }

      decRequest();
      return oRet;
    } catch (err: any) {
      if (setOnError) setErrors(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function postAsync<T extends AxiosResponseType>(
    url: string,
    queryString: { [prop: string]: string } | undefined | null,
    data: any,
    mockedResponse: T | null,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T | null>> {
    resetState();

    const resolvedConfig = fixConfig(config, queryString);
    try {
      setLoading(true);
      incRequest();

      let oRet: AxiosResponse<T | null>;
      if (
        !(props?.forceMocked === false) &&
        (useMockedResponse || props?.forceMocked === true)
      ) {
        oRet = await mockAxiosResponseAsync(
          'POST',
          instance.getUri(
            Object.assign(resolvedConfig || {}, {
              url
            })
          ),
          data,
          mockedResponse,
          !!devForceDebug,
          props?.delay ?? defaultDelay
        );
      } else {
        oRet = await instance.post<T | null>(url, data, resolvedConfig);
      }

      decRequest();
      return oRet;
    } catch (err: any) {
      setErrors(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function putAsync<T extends AxiosResponseType>(
    url: string,
    queryString: { [prop: string]: string } | undefined | null,
    data: any,
    mockedResponse: T | null,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T | null>> {
    resetState();

    const resolvedConfig = fixConfig(config, queryString);
    try {
      setLoading(true);
      incRequest();

      let oRet: AxiosResponse<T | null>;
      if (
        !(props?.forceMocked === false) &&
        (useMockedResponse || props?.forceMocked === true)
      ) {
        oRet = await mockAxiosResponseAsync(
          'PUT',
          instance.getUri(
            Object.assign(resolvedConfig || {}, {
              url
            })
          ),
          data,
          mockedResponse,
          !!devForceDebug,
          props?.delay ?? defaultDelay
        );
      } else {
        oRet = await instance.put<T | null>(url, data, resolvedConfig);
      }

      decRequest();
      return oRet;
    } catch (err: any) {
      setErrors(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function deleteAsync<T extends AxiosResponseType>(
    url: string,
    queryString: { [prop: string]: string } | undefined | null,
    mockedResponse: T | null,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T | null>> {
    resetState();

    const resolvedConfig = fixConfig(config, queryString);
    try {
      setLoading(true);
      incRequest();

      let oRet: AxiosResponse<T | null>;
      if (
        !(props?.forceMocked === false) &&
        (useMockedResponse || props?.forceMocked === true)
      ) {
        oRet = await mockAxiosResponseAsync(
          'DELETE',
          instance.getUri(
            Object.assign(config || {}, {
              url
            })
          ),
          undefined,
          mockedResponse,
          !!devForceDebug,
          props?.delay ?? defaultDelay
        );
      } else {
        oRet = await instance.delete<T | null>(url, resolvedConfig);
      }

      decRequest();
      return oRet;
    } catch (err: any) {
      setErrors(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return { loading, getAsync, postAsync, putAsync, deleteAsync };
};

export default useAxiosRequestManager;
