import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep12ViewModel>();

export interface CommercialCombinedStep12Props {
  initialValue: CommercialCombinedStep12ViewModel;
}

export type CommercialCombinedStep12Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep12Props
>;

export interface CommercialCombinedStep12ViewModel {
  radioactiveSubstancesOrDevices: boolean;
  radioactiveSubstancesOrDevicesDetails?: string | null;

  explosiveSubstances: boolean;
  explosiveSubstancesDetails?: string | null;

  asbestosOrSilica: boolean;
  asbestosOrSilicaDetails?: string | null;

  toxicOrHazardousChemicals: boolean;
  toxicOrHazardousChemicalsDetails?: string | null;

  noiseLevelInExcess85db: boolean;
  noiseLevelInExcess85dbDetails?: string | null;

  workOutsideUK: boolean;
  workOutsideUKDetails?: string | null;

  workInAirportsRailways: boolean;
  workInAirportsRailwaysDetails?: string | null;

  workInAboutVesselsDamsDocks: boolean;
  workInAboutVesselsDamsDocksDetails?: string | null;

  workOffshoreInstallations: boolean;
  workOffshoreInstallationsDetails?: string | null;

  undergroundWorkInExcess1Meter: boolean;
  undergroundWorkInExcess1MeterDetails?: string | null;

  steelErectionScaffolding: boolean;
  steelErectionScaffoldingDetails?: string | null;

  workAtHeightExceeding10Meters: boolean;
  workAtHeightExceeding10MetersDetails?: string | null;
}

export const commercialCombinedStep12Schema: SchemaOf<CommercialCombinedStep12ViewModel> =
  object({
    radioactiveSubstancesOrDevices: boolean().defined(),
    radioactiveSubstancesOrDevicesDetails: requiredIf(
      string().nullable().optional().label('It'),
      'radioactiveSubstancesOrDevices'
    ),
    explosiveSubstances: boolean().defined(),
    explosiveSubstancesDetails: requiredIf(
      string().nullable().optional().label('It'),
      'explosiveSubstances'
    ),
    asbestosOrSilica: boolean().defined(),
    asbestosOrSilicaDetails: requiredIf(
      string().nullable().optional().label('It'),
      'asbestosOrSilica'
    ),
    toxicOrHazardousChemicals: boolean().defined(),
    toxicOrHazardousChemicalsDetails: requiredIf(
      string().nullable().optional().label('It'),
      'toxicOrHazardousChemicals'
    ),
    noiseLevelInExcess85db: boolean().defined(),
    noiseLevelInExcess85dbDetails: requiredIf(
      string().nullable().optional().label('It'),
      'noiseLevelInExcess85db'
    ),
    workOutsideUK: boolean().defined(),
    workOutsideUKDetails: requiredIf(
      string().nullable().optional().label('It'),
      'workOutsideUK'
    ),
    workInAirportsRailways: boolean().defined(),
    workInAirportsRailwaysDetails: requiredIf(
      string().nullable().optional().label('It'),
      'workInAirportsRailways'
    ),
    workInAboutVesselsDamsDocks: boolean().defined(),
    workInAboutVesselsDamsDocksDetails: requiredIf(
      string().nullable().optional().label('It'),
      'workInAboutVesselsDamsDocks'
    ),
    workOffshoreInstallations: boolean().defined(),
    workOffshoreInstallationsDetails: requiredIf(
      string().nullable().optional().label('It'),
      'workOffshoreInstallations'
    ),
    undergroundWorkInExcess1Meter: boolean().defined(),
    undergroundWorkInExcess1MeterDetails: requiredIf(
      string().nullable().optional().label('It'),
      'undergroundWorkInExcess1Meter'
    ),
    steelErectionScaffolding: boolean().defined(),
    steelErectionScaffoldingDetails: requiredIf(
      string().nullable().optional().label('It'),
      'steelErectionScaffolding'
    ),
    workAtHeightExceeding10Meters: boolean().defined(),
    workAtHeightExceeding10MetersDetails: requiredIf(
      string().nullable().optional().label('It'),
      'workAtHeightExceeding10Meters'
    )
  });

export const CommercialCombinedStep12ViewModelInitialValue: CommercialCombinedStep12ViewModel =
  {
    radioactiveSubstancesOrDevices: false,
    radioactiveSubstancesOrDevicesDetails: '',
    explosiveSubstances: false,
    explosiveSubstancesDetails: '',
    asbestosOrSilica: false,
    asbestosOrSilicaDetails: '',
    toxicOrHazardousChemicals: false,
    toxicOrHazardousChemicalsDetails: '',
    noiseLevelInExcess85db: false,
    noiseLevelInExcess85dbDetails: '',
    workOutsideUK: false,
    workOutsideUKDetails: '',
    workInAirportsRailways: false,
    workInAirportsRailwaysDetails: '',
    workInAboutVesselsDamsDocks: false,
    workInAboutVesselsDamsDocksDetails: '',
    workOffshoreInstallations: false,
    workOffshoreInstallationsDetails: '',
    undergroundWorkInExcess1Meter: false,
    undergroundWorkInExcess1MeterDetails: '',
    steelErectionScaffolding: false,
    steelErectionScaffoldingDetails: '',
    workAtHeightExceeding10Meters: false,
    workAtHeightExceeding10MetersDetails: ''
  };
export const CommercialCombinedStep12ViewModelInitialValueDev: CommercialCombinedStep12ViewModel =
  {
    radioactiveSubstancesOrDevices: false,
    radioactiveSubstancesOrDevicesDetails: '',
    explosiveSubstances: false,
    explosiveSubstancesDetails: '',
    asbestosOrSilica: false,
    asbestosOrSilicaDetails: '',
    toxicOrHazardousChemicals: false,
    toxicOrHazardousChemicalsDetails: '',
    noiseLevelInExcess85db: false,
    noiseLevelInExcess85dbDetails: '',
    workOutsideUK: false,
    workOutsideUKDetails: '',
    workInAirportsRailways: false,
    workInAirportsRailwaysDetails: '',
    workInAboutVesselsDamsDocks: false,
    workInAboutVesselsDamsDocksDetails: '',
    workOffshoreInstallations: false,
    workOffshoreInstallationsDetails: '',
    undergroundWorkInExcess1Meter: false,
    undergroundWorkInExcess1MeterDetails: '',
    steelErectionScaffolding: false,
    steelErectionScaffoldingDetails: '',
    workAtHeightExceeding10Meters: false,
    workAtHeightExceeding10MetersDetails: ''
  };

export function useCommercialCombinedStep12InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep12ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep12ViewModelInitialValueDev
        : CommercialCombinedStep12ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
