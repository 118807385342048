import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import moment from 'moment';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { date, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep27Props {
  initialValue: CommercialCombinedStep27ViewModel;
}

export type CommercialCombinedStep27Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep27Props
>;

export interface CommercialCombinedStep27ViewModel {
  startDate?: Date | null;
  endDate?: Date | null;
  referralNotes?: string | null;
  renewalStartDate?: Date | null;
}

export const commercialCombinedStep27Schema: SchemaOf<CommercialCombinedStep27ViewModel> =
  object({
    startDate:
       requiredIf(
       date().nullable().label('It')
       .min(
        moment().startOf('day').toDate(),
        (m) =>
          `${m.label} field must be later than ${moment(m.min).format(
            'DD/MM/YYYY'
          )}`
      )
      .max(
        moment().startOf('day').add(30, 'day').toDate(),
        (m) =>
          `${m.label} field must be earlier than ${moment(m.max).format(
            'DD/MM/YYYY'
          )}`
      ),
      'renewalStartDate',
      (renewalStartDate: Date) => renewalStartDate === null
      )      
      .label('Start date'),
      referralNotes: string().nullable(),
      endDate: date().nullable(),
      renewalStartDate: date().nullable()
  });

export const CommercialCombinedStep27ViewModelInitialValue: CommercialCombinedStep27ViewModel =
  {};
export const CommercialCombinedStep27ViewModelInitialValueDev: CommercialCombinedStep27ViewModel =
  {
    startDate: moment().startOf('day').toDate()
  };

export function useCommercialCombinedStep27InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep27ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep27ViewModelInitialValueDev
        : CommercialCombinedStep27ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
