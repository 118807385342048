import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, number, object, SchemaOf, string } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep26ViewModel>();

export interface CommercialCombinedStep26Props {
  initialValue: CommercialCombinedStep26ViewModel;
}

export type CommercialCombinedStep26Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep26Props
>;

export interface CommercialCombinedStep26ViewModel {
  windowsGrilledFittedWithLocks?: boolean | null;
  windowsGrilledFittedWithLocksDetails?: string | null;
  hasIntruderAlarm?: boolean | null;
  haveIntruderAlarmTypeId?: number | null;
  haveAlarmMaintenanceContractWithCompanyAccreditedSSAIBorNSI?: boolean | null;
  haveSafeVaultInstalled?: boolean | null;
  safeVaultMake?: string | null;
  safeVaultModel?: string | null;
}

export const commercialCombinedStep26Schema: SchemaOf<CommercialCombinedStep26ViewModel> =
  object({
    windowsGrilledFittedWithLocks: boolean().nullable().required(),
    windowsGrilledFittedWithLocksDetails: requiredIf(
      string().nullable(),
      'windowsGrilledFittedWithLocks',
      false
    ),
    hasIntruderAlarm: boolean().nullable().required(),
    haveIntruderAlarmTypeId: requiredIf(
      number().nullable(),
      'hasIntruderAlarm'
    ),
    haveAlarmMaintenanceContractWithCompanyAccreditedSSAIBorNSI: boolean()
      .nullable()
      .required(),
    haveSafeVaultInstalled: boolean().nullable().required(),
    safeVaultMake: requiredIf(string().nullable(), 'haveSafeVaultInstalled'),
    safeVaultModel: requiredIf(string().nullable(), 'haveSafeVaultInstalled')
  });

export const CommercialCombinedStep26ViewModelInitialValue: CommercialCombinedStep26ViewModel =
  {};
export const CommercialCombinedStep26ViewModelInitialValueDev: CommercialCombinedStep26ViewModel =
  {
    windowsGrilledFittedWithLocks: true,
    hasIntruderAlarm: true,
    haveIntruderAlarmTypeId: 3,
    haveAlarmMaintenanceContractWithCompanyAccreditedSSAIBorNSI: true,
    haveSafeVaultInstalled: false
  };

export function useCommercialCombinedStep26InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep26ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep26ViewModelInitialValueDev
        : CommercialCombinedStep26ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
