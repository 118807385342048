import { DropdownItem } from 'common';
import { FormCheckBoxList as FormCheckBoxListGeneric } from 'components/controls';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading } from 'components/Heading';
import CommercialCombinedStep2OptionItemGeneric from 'components/ui/funnel/commercial-combined/Step2-OptionItem';
import { optionsCC } from 'config/options';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep2Component,
  commercialCombinedStep2Schema,
  CommercialCombinedStep2ViewModel,
  CoverTypes
} from './Step2.models';
import { useLocation, useParams } from 'react-router-dom';
import { useRouterArea } from 'common/useRouterArea';

const FormCheckBoxList = FormCheckBoxListGeneric<number>();
const CommercialCombinedStep2OptionItem =
  CommercialCombinedStep2OptionItemGeneric<number>();

const options: Array<DropdownItem<number> & { extra: string }> =
  optionsCC.coverTypes;

const CommercialCombinedStep2: CommercialCombinedStep2Component = (
  { initialValue },
  ref
) => {
  const { pathname } = useLocation();
  const { guid } = useParams();
  const { urlPrefix } = useRouterArea();

  const isMta =
    pathname.toLowerCase().startsWith(`${urlPrefix}buy/`) && guid != null;

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep2ViewModel>(ref, {
      getValues: (values) => ({
        ...values,
        selectedCoverType:
          values.coverTypeIds.length === 1
            ? values.coverTypeIds[0] === CoverTypes.PLEL
              ? 'PLEL'
              : 'PC'
            : values.coverTypeIds.length === 2
            ? 'CC'
            : undefined,
        selectedCoverName:
          values.coverTypeIds.length === 1
            ? values.coverTypeIds[0] === CoverTypes.PLEL
              ? 'Public & Employers Liability'
              : 'Property & Contents'
            : values.coverTypeIds.length === 2
            ? 'Commercial Combined'
            : undefined
      })
    });

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep2Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Your Cover" />
        <FormCheckBoxList
          id="coverTypeIds"
          name="coverTypeIds"
          options={options}
          label-text="What insurance cover do you require?"
          label-subElement={false}
          validateOnChange={validateOnChange}
          item={(item) => (
            <CommercialCombinedStep2OptionItem
              key={`coverTypeIds_${item.idx}`}
              item={item}
              disabled={isMta}
              extra={options[item.idx].extra}
            />
          )}
        />
        {isMta && (
          <div className="mb-4 alert alert-dark">
            You cannot amend these options on an Adjustment.
            <br />
            <br />
            Please email or call the office on londonmarket@cginsurance.com or
            0203 9464210 if you need assistance.
          </div>
        )}
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep2);

