import { AxiosResponse } from 'axios';

export interface AxiosResponseType {
  httpStatus?: number;
  httpStatusData?: any;
  [prop: string]: any;
}

export default function mockAxiosResponseAsync<T extends AxiosResponseType>(
  method: string,
  url: string,
  data: any,
  mockedResponse: T | null,
  isDevDebugMode: boolean,
  delay: number
): Promise<AxiosResponse<T | null>> {
  if (isDevDebugMode) {
    printDebugMessage<T>(method, url, data, mockedResponse, delay);
  }

  function execute(
    resolve: (value: AxiosResponse<T | null>) => void,
    reject: (reason?: any) => void
  ) {
    const axiosResponse = { data: mockedResponse } as AxiosResponse<T | null>;

    if (mockedResponse && mockedResponse.httpStatus) {
      if (mockedResponse.httpStatusData) {
        reject({
          response: {
            status: mockedResponse.httpStatus,
            data: mockedResponse.httpStatusData
          }
        });
      } else {
        reject({ response: { status: mockedResponse.httpStatus } });
      }
    } else {
      resolve(axiosResponse);
    }
  }

  return new Promise<AxiosResponse<T | null>>((resolve, reject) => {
    if (delay) {
      setTimeout(() => {
        execute(resolve, reject);
      }, delay);
    } else {
      execute(resolve, reject);
    }
  });
}

function printDebugMessage<T>(
  method: string,
  url: string,
  data: any,
  response: T | null,
  delay: number
) {
  let msg = 'Mocked endpoint call';
  if (delay) {
    msg += ` (with a mocked delay of ${delay}ms)`;
  }
  msg += `\r\nURL (${method}): "${url}"`;

  if (data) {
    msg += `\r\nPayload: ${JSON.stringify(data)}`;
  }

  msg += `\r\nResponse: ${
    response === null ? 'NULL' : JSON.stringify(response)
  }`;

  console.info(msg);
}
