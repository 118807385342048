import filterChildren from 'common/functions/ReactChildrenFilter';
import { ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import {
  createFormMultiStepGroupContextProps,
  createFormMultiStepGroupProps,
  FormMultiStepGroupComponentProps,
  FormMultiStepGroupContext
} from './form-multi-step-group.models';
import { FormMultiStepItemProps } from './form-multi-step-item';

const getStepItems = (
  el: ReactNode
): Array<ReactElement<FormMultiStepItemProps>> => {
  const [items] = filterChildren(el, 'FormMultiStepItem');
  return items.map<ReactElement<FormMultiStepItemProps>>(
    (item) => item as ReactElement<FormMultiStepItemProps>
  );
};

const useFormMultiStepGroupContext = () =>
  useContext(FormMultiStepGroupContext);

const useFormMultiStepGroupConfig = (
  props: FormMultiStepGroupComponentProps & {
    children?: ReactNode;
  }
) => {
  const [navigating, setNavigating] = useState(false);
  const groupProps = createFormMultiStepGroupProps(
    props.config.setStep,
    props.onFinish,
    setNavigating,
    navigating
  );

  const node =
    typeof props.steps === 'function' ? props.steps(groupProps) : props.steps;

  const { stepItems, titles, activeStep } = useMemo(() => {
    const nodeStepItems = getStepItems(node);
    const childrenStepItems = getStepItems(props.children);
    const stepItems = [...nodeStepItems, ...childrenStepItems];

    return {
      stepItems,
      titles: stepItems.map((item) => item.props.title || ''),
      activeStep: stepItems[Math.min(stepItems.length, props.config.step) - 1]
    };
  }, [node, props.config.step, props.children]);

  const contextProps = createFormMultiStepGroupContextProps(
    props.config.data,
    props.config.setData,
    stepItems,
    activeStep,
    props.config.quotePubSub,
    props.config.setStep,
    props.onBeforeNavigate,
    props.onFinish,
    props.config.step,
    titles.length,
    setNavigating,
    navigating
  );

  return {
    step: props.config.step,
    setStep: props.config.setStep,
    groupProps,
    titles,
    activeStep,
    contextProps
  };
};

export { useFormMultiStepGroupContext, useFormMultiStepGroupConfig };
