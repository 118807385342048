import { PropsWithChildren, FC, ChangeEvent } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField } from 'formik';
import LookUp from '@mui/icons-material/NearMe';
import { fixPostcodeValue } from 'common/functions/FixPostcodeValue';

const defaultInputOtherWrapperClassName = 'tb-postcode';
const defaultPlaceholder = 'eg. SL4 1PD';
const defaultLabelText = 'Postcode';
const defaultButtonText = 'Lookup';

type FormTextBoxPostcodeProps = PropsWithChildren<
  Omit<FormControlWrapperProps, 'onChange'> &
    Omit<FormLabelProps, 'label-text'> & {
      id?: string;
      name: string;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      'label-text'?: string;
      loading?: boolean;
      onLookup: () => void;
      buttonText?: string;
      onChange?: (value: string) => void;
    }
>;

type FormTextBoxPostcodeComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

function handleOnChange(
  ev: ChangeEvent<HTMLInputElement>,
  setValue: (value: string, shouldValidate?: boolean) => void,
  onChange: undefined | ((value: string) => void)
) {
  const newVal =
    fixPostcodeValue(
      ev.currentTarget.value.toUpperCase().replace(/[^A-Z0-9]+/g, '')
    ) || '';
  setValue(newVal);
  if (onChange) {
    onChange(newVal);
  }
}

const FormTextBoxPostcode: FormTextBoxPostcodeComponent<
  FormTextBoxPostcodeProps
> = (props) => {
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    inputOtherWrapperClassName,
    placeholder,
    loading,
    onLookup,
    buttonText,
    onChange,
    children,
    ...otherProps
  } = splitProps[0];
  const [labels, others] = filterChildren(children, 'FormLabel');
  const [{ onChange: fieldOnChange, ...otherFields }, { error }, { setValue }] =
    useField<string>(props.name);

  const { text, ...otherLabelProps } = splitProps[1];

  const placeholderFix = () => {
    if (placeholder === undefined) {
      return defaultPlaceholder;
    } else if (!placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? (
        labels
      ) : (
        <FormLabel
          htmlFor={name}
          text={text === undefined ? defaultLabelText : text}
          {...otherLabelProps}
        />
      )}
      {inputOtherWrapperClassName ? (
        <div
          className={`${inputOtherWrapperClassName} ${
            error ? 'is-invalid' : ''
          }`}
        >
          <div className={defaultInputOtherWrapperClassName}>
            <input
              id={id}
              type="text"
              placeholder={placeholderFix()}
              className={`form-control ${error ? 'is-invalid' : ''}`}
              onChange={(ev) => handleOnChange(ev, setValue, onChange)}
              maxLength={8}
              {...otherFields}
            />
            <button
              className="btn btn-c-check d-flex justify-content-center align-items-center ms-2"
              disabled={loading}
              onClick={() => {
                if (!loading) {
                  onLookup();
                }
              }}
            >
              <LookUp className="text-secondary me-2" />
              <span>
                {buttonText === undefined ? defaultButtonText : buttonText}
              </span>
            </button>
          </div>
          {others}
        </div>
      ) : (
        <>
          <div className={defaultInputOtherWrapperClassName}>
            <input
              id={id}
              type="text"
              placeholder={placeholderFix()}
              className={`form-control ${error ? 'is-invalid' : ''}`}
              onChange={(ev) => handleOnChange(ev, setValue, onChange)}
              maxLength={8}
              {...otherFields}
            />
            <button
              className="btn btn-c-check d-flex justify-content-center align-items-center ms-2"
              onClick={() => onLookup()}
            >
              <LookUp className="text-secondary me-2" />
              <span>
                {buttonText === undefined ? defaultButtonText : buttonText}
              </span>
            </button>
          </div>
          {others}
        </>
      )}
      <FormControlError msg={error} />
    </FormControlWrapper>
  );
};
FormTextBoxPostcode.Label = FormLabel;

FormTextBoxPostcode.displayName = 'FormTextBoxPostcode';
export default FormTextBoxPostcode;
