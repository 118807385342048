import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, mixed, number, object, SchemaOf } from 'yup';

export interface CommercialCombinedStep11Props {
  initialValue: CommercialCombinedStep11ViewModel;
}

export type CommercialCombinedStep11Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep11Props
>;

export interface CommercialCombinedStep11ViewModel {
  outsideUK?: boolean | null;
  europe?: number | null;
  usaCanada?: number | null;
  restOfTheWorld?: number | null;
}

export const commercialCombinedStep11Schema: SchemaOf<CommercialCombinedStep11ViewModel> =
  object({
    outsideUK: boolean().nullable().required(),
    validator: mixed().test(
      'mustBeBelow100AndAtLeastOneValue',
      '',
      (_, ctx) => {
        const parent: CommercialCombinedStep11ViewModel = ctx.parent;

        if (!parent.outsideUK) return true;

        if (
          parent.europe == null &&
          parent.usaCanada == null &&
          parent.restOfTheWorld == null
        ) {
          return ctx.createError({
            message: 'You must enter at least one value'
          });
        }

        if (
          (parent.europe || 0) +
            (parent.usaCanada || 0) +
            (parent.restOfTheWorld || 0) >
          100
        ) {
          return ctx.createError({
            message: 'The Percentage of Annual Turnover must be less than 100'
          });
        }

        return true;
      }
    ),
    europe: number().optional(),
    usaCanada: number().optional(),
    restOfTheWorld: number().optional()
  });

export const CommercialCombinedStep11ViewModelInitialValue: CommercialCombinedStep11ViewModel =
  {
    outsideUK: undefined,
    europe: undefined,
    usaCanada: undefined,
    restOfTheWorld: undefined
  };
export const CommercialCombinedStep11ViewModelInitialValueDev: CommercialCombinedStep11ViewModel =
  {
    outsideUK: false
  };

export function useCommercialCombinedStep11InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep11ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep11ViewModelInitialValueDev
        : CommercialCombinedStep11ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
