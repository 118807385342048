import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean
} from 'components/controls';
import FormTextBoxPercentage from 'components/controls/form/form-text-box-percentage';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep13Component,
  commercialCombinedStep13Schema,
  CommercialCombinedStep13ViewModel
} from './Step13.models';

const getFieldName = nameOf<CommercialCombinedStep13ViewModel>;

const CommercialCombinedStep13: CommercialCombinedStep13Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep13ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep13Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Risk Details" />
        <FormRadioButtonListBoolean
          name={getFieldName('undertakenWork')}
          label-text="Do you undertake work away from your premises involving the application of Heat / Cutting or Welding?"
        />
        <FormConditionalCollapse name={getFieldName('undertakenWork')}>
          <FormTextBoxPercentage
            name={getFieldName('undertakenWorkPercentage')}
            label-subElement={true}
            label-text="What percentage?"
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep13);
