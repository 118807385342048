import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const backOfficeUrl = '/back-office';
export const brokerUrl = '/broker';

export function useRouterArea() {
  const pathname = useLocation().pathname;
  const isBackOffice = useMemo(
    () => pathname.startsWith(`${backOfficeUrl}/`),
    [pathname]
  );
  const isBroker = useMemo(
    () => pathname.startsWith(`${brokerUrl}/`),
    [pathname]
  );

  const urlPrefix = useMemo(
    () =>
      isBackOffice ? `${backOfficeUrl}/` : isBroker ? `${brokerUrl}/` : '/',
    [isBackOffice, isBroker]
  );

  return {
    isBackOffice: isBackOffice,
    isBroker: isBroker,
    urlPrefix
  };
}
