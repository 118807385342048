import { Link } from 'react-router-dom';
import {
  FormTextBox,
  FormSelect as FormSelectGeneric
} from 'components/controls';
import { Formik, FormikHelpers } from 'formik';
import { object, SchemaOf, string, number } from 'yup';
import { useState } from 'react';
import { scrollToFirstError } from 'common/functions/ScrollToFirstError';
import { DropdownItem } from 'common';
const FormSelect = FormSelectGeneric<number>();

interface MyFormValues {
  title?: number;
  firstName: string;
  surname: string;
}

const MyFormValuesSchema: SchemaOf<MyFormValues> = object().shape({
  title: number().required().label('Title'),
  firstName: string().required().label('First name'),
  surname: string().required().label('Surname')
});

export default function FormExample() {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const initialValues: MyFormValues = {
    firstName: '',
    surname: ''
  };

  const options: Array<DropdownItem<number>> = [
    { value: 1, text: 'Mr' },
    { value: 2, text: 'Mrs' },
    { value: 3, text: 'Miss' },
    { value: 4, text: 'Ms' },
    { value: 5, text: 'Dr' }
  ];

  function onSubmit(
    values: MyFormValues,
    actions: FormikHelpers<MyFormValues>
  ) {
    console.log(values);
    console.log(actions);
  }

  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>That feels like an existential question, don't you think?</p>
      </main>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={MyFormValuesSchema}
        validateOnChange={validateOnChange}
        validateOnBlur={false}
      >
        {(formProps) => {
          return (
            <div>
              <FormSelect
                id="title"
                name="title"
                validateOnChange={validateOnChange}
                options={options}
                label-text="Title"
              />
              <FormTextBox
                name="firstName"
                label-text="First name"
                placeholder="Enter your first name"
              />
              <FormTextBox
                name="surname"
                label-text="Surname"
                placeholder="Enter your surname"
              />
              <div className="text-end mb-4">
                <button
                  className="btn btn-outline-danger me-3"
                  onClick={() => {
                    setValidateOnChange(false);
                    formProps.setErrors({});
                    formProps.setTouched({});
                  }}
                >
                  Clear validation
                </button>
                <button
                  className="btn btn-primary"
                  onClick={async () => {
                    setValidateOnChange(true);
                    await formProps.submitForm();
                    formProps.setTouched({});
                    if (formProps.errors) {
                      scrollToFirstError();
                    }
                  }}
                >
                  Send Data
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
      <nav>
        <Link to="/">Go back to the Home Page</Link>
      </nav>
    </>
  );
}
