import { FC } from 'react';

export const SubHeading: FC<{
  title: string;
  color?: string;
  extraClassName?: string;
}> = ({ title, color, extraClassName }) => {
  return (
    <h5
      className={`mb-3 ${color ? `text-${color}` : ''} ${
        extraClassName ? extraClassName : ''
      }`}
    >
      {title}
    </h5>
  );
};
