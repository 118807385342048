import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { Heading, SubHeading } from 'components';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean
} from 'components/controls';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep23Component,
  CommercialCombinedStep23ItemViewModel,
  commercialCombinedStep23Schema,
  CommercialCombinedStep23ViewModel
} from './Step23.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';

const CommercialCombinedStep23: CommercialCombinedStep23Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep23ViewModel>(ref);

  const { getFieldName, locationHeadingText } = usePremisesExtraQuestionsHelper<
    CommercialCombinedStep23ItemViewModel,
    CommercialCombinedStep23ViewModel
  >();
  if (!locationHeadingText) return null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep23Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <FormRadioButtonListBoolean
          name={getFieldName('isLetAsResidential')}
          label-text="Is premises or part thereof let as residential accommodation?"
        />
        <FormConditionalCollapse name={getFieldName('isLetAsResidential')}>
          <FormRadioButtonListBoolean
            name={getFieldName('isLetAsResidentialGasSafety')}
            label-text="Will you comply with current gas safety regulations and laws and ensure you are always in possession of a current Gas Safety certificate issued by a Gas Safe registered engineer?"
            label-subElement
          />
        </FormConditionalCollapse>
        <FormRadioButtonListBoolean
          name={getFieldName('ensureGasCertificate')}
          label-text="If the premises are in commercial use and you are responsible for gas installations, do you ensure annual maintenance and safety checks of the gas installation(s) are completed by a Gas Safe registered engineer and that you are in possession of a valid Gas Safety certificate issued by a Gas Safe registered engineer"
        />
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep23);
