import { FC, HTMLAttributes, PropsWithChildren } from 'react';

type FormControlWrapperProps = PropsWithChildren<HTMLAttributes<HTMLElement>>;

type FormControlWrapperComponent<P> = FC<P>;

const FormControlWrapper: FormControlWrapperComponent<
  FormControlWrapperProps
> = ({ children, className, ...otherProps }) => {
  return (
    <div
      className={className ? className : 'errorParentWrapper mb-4 form-group'}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default FormControlWrapper;
export type { FormControlWrapperProps };
