import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import FormCheckBox from 'components/controls/form/form-check-box';
import FormConditionalCollapse from 'components/controls/form/form-conditional-collapse';
import FormTextBox from 'components/controls/form/form-textarea';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep14Component,
  commercialCombinedStep14Schema,
  CommercialCombinedStep14ViewModel
} from './Step14.models';
const getFieldName = nameOf<CommercialCombinedStep14ViewModel>;

const CommercialCombinedStep14: CommercialCombinedStep14Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep14ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep14Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Declaration" />
        <SubHeading title="Please select any that applies to you:" />
        <p>
          <strong>

          Have you and / or any Director / Partner / financially associated
          person(s) ever been
          </strong>
        </p>
        <div className="form-group mb-4">
          <FormCheckBox
            name={getFieldName('declarationBankrupt')}
            label-text="declared bankrupt"
          />
          <FormConditionalCollapse name={getFieldName('declarationBankrupt')}>
            <FormTextBox
              name={getFieldName('declarationBankruptDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('becomeInsolvent')}
            label-text="involved in any company that has become Insolvent"
            />
          <FormConditionalCollapse name={getFieldName('becomeInsolvent')}>
            <FormTextBox
              name={getFieldName('becomeInsolventDetails')}
              label-subElement={true}
              label-text="Please provide details"
            />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('liquidation')}
            label-text="gone into liquidation"
            />
          <FormConditionalCollapse name={getFieldName('liquidation')}>
            <FormTextBox
              name={getFieldName('liquidationDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('ccj')}
            label-text="subject to a County Court Judgement (CCJ)"
            />
             <FormConditionalCollapse name={getFieldName('ccj')}>
            <FormTextBox
              name={getFieldName('ccjDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('criminalOffence')}
            label-text="convicted of any criminal offence, other than motoring offence"
            />
             <FormConditionalCollapse name={getFieldName('criminalOffence')}>
            <FormTextBox
              name={getFieldName('criminalOffenceDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('disqualified')}
            label-text="have been disqualified from being a company director"
            />
            <FormConditionalCollapse name={getFieldName('disqualified')}>
            <FormTextBox
              name={getFieldName('disqualifiedDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('healthAndSafetyBreach')}
            label-text="have been charged with or convicted of, or been involved in a company charged with or convicted of, a breach of the Health and Safety at Work Act or other legislation relating to employee safety and safe working practices, or been served with, or been involved with a company served with, an improvement order or a prohibition notice under such legislation"
            />
            <FormConditionalCollapse name={getFieldName('healthAndSafetyBreach')}>
            <FormTextBox
              name={getFieldName('healthAndSafetyBreachDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          <FormCheckBox
            name={getFieldName('insuranceDeclined')}
            label-text="had an insurance proposal declined, renewal refused, insurance cover cancelled or special terms applied"
            />
            <FormConditionalCollapse name={getFieldName('insuranceDeclined')}>
            <FormTextBox
              name={getFieldName('insuranceDeclinedDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
          </div>
          <div className="form-group mb-4">
            <p><strong>Does The Company/Business</strong></p>
            <FormCheckBox
              name={getFieldName('legalDisputes')}
              label-text="have any current or ongoing legal or contractual disputes"
            />
              <FormConditionalCollapse name={getFieldName('legalDisputes')}>
            <FormTextBox
              name={getFieldName('legalDisputesDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
            <FormCheckBox
              name={getFieldName('changedName')}
              label-text="changed name"
            />
             <FormConditionalCollapse name={getFieldName('changedName')}>
            <FormTextBox
              name={getFieldName('changedNameDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
            <FormCheckBox
              name={getFieldName('changePerson')}
              label-text="had a change of Director / Partner/ financially associated person(s)"
            />
             <FormConditionalCollapse name={getFieldName('changePerson')}>
            <FormTextBox
              name={getFieldName('changePersonDetails')}
              label-subElement={true}
              label-text="Please provide details"
              />
          </FormConditionalCollapse>
        </div>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep14);