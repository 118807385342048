import { PropsWithChildren, FC, useState, useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField } from 'formik';
import { InputGroup } from 'react-bootstrap';

const percentageSymbol = '%';

type FormTextBoxPercentageProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      allowDecimals?: boolean;
      showPercentageSymbol?: boolean;
      forceInvalid?: boolean;
    }
>;

type FormTextBoxPercentageComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const renderInput = (
  id: string | undefined,
  value: string,
  placeholder: string | undefined,
  showError: boolean,
  allowDecimals: boolean,
  showPercentageSymbol: boolean,
  onChange: (strVal: string, numVal: number | null | undefined) => void,
  otherFields: any
) => {
  return (
    <InputGroup className={`mb-0 $ ${showError ? 'is-invalid' : ''}`}>
      <InputGroup.Text id="sumInsured" className="bg-primary text-white px-3">
        {percentageSymbol}
      </InputGroup.Text>
      <IMaskInput
        id={id}
        mask={`num${showPercentageSymbol ? percentageSymbol : ''}`}
        blocks={{
          num: {
            mask: Number,
            radix: '.',
            max: 100,
            thousandsSeparator: '',
            scale: allowDecimals ? 2 : 0
          }
        }}
        unmask={false}
        placeholder={placeholder}
        className={`form-control ${showError ? 'is-invalid' : ''}`}
        maxLength={3}
        value={value}
        onAccept={(v: string, el, ev) => {
          if (ev && ev.inputType && ev.inputType.startsWith('delete')) {
            if (allowDecimals && v.endsWith('.')) {
              v = v.slice(0, -1);
            }
            if (showPercentageSymbol && v === percentageSymbol) {
              v = '';
            }
          }

          const numVal = Number(el.unmaskedValue);
          onChange(
            v,
            !el.unmaskedValue.trim() || isNaN(numVal) ? undefined : numVal
          );
        }}
        {...otherFields}
      />
    </InputGroup>
  );
};

const FormTextBoxPercentage: FormTextBoxPercentageComponent<
  FormTextBoxPercentageProps
> = (props) => {
  const [internalValue, setInternalValue] = useState('');
  const [internallyTriggered, setInternallyTriggered] = useState(false);
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    inputOtherWrapperClassName,
    placeholder,
    allowDecimals,
    showPercentageSymbol,
    forceInvalid,
    children,
    ...otherProps
  } = splitProps[0];

  const [field, meta, helpers] = useField<number | null | undefined>(props);
  const [labels, others] = filterChildren(children, 'FormLabel');

  const { value, onChange, ...otherFields } = field;

  useEffect(() => {
    if (internallyTriggered) {
      setInternallyTriggered(false);
    } else {
      setInternalValue(
        value != null
          ? allowDecimals
            ? value.toString()
            : Math[value < 0 ? 'ceil' : 'floor'](value).toString()
          : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const placeholderFix = () => {
    if (!placeholder || !placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  const handleOnChange = (
    strVal: string,
    numVal: number | null | undefined
  ) => {
    setInternalValue(strVal);
    setInternallyTriggered(true);
    helpers.setValue(numVal);
  };

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? labels : <FormLabel htmlFor={name} {...splitProps[1]} />}
      {inputOtherWrapperClassName ? (
        <div className={inputOtherWrapperClassName}>
          {renderInput(
            id,
            internalValue,
            placeholderFix(),
            !!meta.error || forceInvalid || false,
            allowDecimals || false,
            showPercentageSymbol || false,
            handleOnChange,
            otherFields
          )}
          {others}
        </div>
      ) : (
        <>
          {renderInput(
            id,
            internalValue,
            placeholderFix(),
            !!meta.error || forceInvalid || false,
            allowDecimals || false,
            showPercentageSymbol || false,
            handleOnChange,
            otherFields
          )}
          {others}
        </>
      )}
      <FormControlError msg={meta?.error} />
    </FormControlWrapper>
  );
};
FormTextBoxPercentage.Label = FormLabel;

FormTextBoxPercentage.displayName = 'FormTextBoxPercentage';
export default FormTextBoxPercentage;
