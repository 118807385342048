import { FC } from 'react';
import FormRadioButtonListGeneric, {
  FormRadioButtonListGenericProps
} from './form-radio-button-list';

const FormRadioButtonListBoolean: FC<
  Omit<FormRadioButtonListGenericProps<boolean>, 'options'> & {
    trueText?: string;
    falseText?: string;
  }
> = ({ trueText, falseText, size, ...props }) => {
  const defaultTrueText = 'Yes';
  const defaultFalseText = 'No';
  const FormRadioButtonListBooleanInt = FormRadioButtonListGeneric<boolean>();

  const options = [
    { value: true, text: trueText || defaultTrueText },
    { value: false, text: falseText || defaultFalseText }
  ];

  return (
    <FormRadioButtonListBooleanInt
      options={options}
      size={size || 6}
      {...props}
    />
  );
};
FormRadioButtonListBoolean.displayName = 'FormRadioButtonListBoolean';

export default FormRadioButtonListBoolean;
