import { currencyFormat } from 'common/functions/formats/currency';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, number, object, SchemaOf, string, mixed } from 'yup';
import { businessInterruptionCoverTitle } from './Step7';
import {
  commercialCombinedStep7Schema,
  CommercialCombinedStep7ViewModelInitialValueDev
} from './Step7.models';

export const maxNumberOfOccupations = 5;

export interface CommercialCombinedStep8Props {
  initialValue: CommercialCombinedStep8ViewModel;
}

export type CommercialCombinedStep8Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep8Props
>;

export interface CommercialCombinedStep8ViewModel {
  activities: Array<CommercialCombinedStep8ActivityViewModel>;
  insuredTradeTypeId?: number | null;
  annualTurnover?: number | null;
  annualManualWageRoll?: number | null;
  annualClericalWageRoll?: number | null;
  businessInterruptionCoverSumForValidation?: number;
}

export interface CommercialCombinedStep8ActivityViewModel {
  id: number;
  name: string;
  value?: number | null;
}

const commercialCombinedStep8ActivitySchema: SchemaOf<CommercialCombinedStep8ActivityViewModel> =
  object({
    id: number().default(0).required(),
    name: string().default('').required(),
    value: number().nullable().notRequired()
  });
export const commercialCombinedStep8Schema: SchemaOf<CommercialCombinedStep8ViewModel> =
  object({
    activities: array()
      .of(commercialCombinedStep8ActivitySchema)
      .min(1)
      .required()
      .test(
        'activitySum100',
        'The total of all occupations must equal 100%',
        (activities) =>
          activities?.reduce(
            (sum, current) => sum + (current.value || 0),
            0
          ) === 100
      )
      .default([]),
    insuredTradeTypeId: number().nullable().required(),
    annualTurnover: number()
      .nullable()
      .required()
      .min(1)
      .label('Annual turnover')
      .test(
        'annualTurnoverLessThanManualAndClerical',
        // eslint-disable-next-line no-template-curly-in-string
        '${label} must be greater than the sum of wages',
        (value, ctx) => {
          if (!value) return true;

          return (
            (ctx.parent.annualManualWageRoll || 0) +
              (ctx.parent.annualClericalWageRoll || 0) <
            value
          );
        }
      )
      .test(
        'annualTurnoverGreaterThanBusinessInterruption',
        '',
        (value, ctx) => {
          if (!value) return true;

          if (!ctx.parent.businessInterruptionCoverSumForValidation) {
            return true;
          }

          if (value > ctx.parent.businessInterruptionCoverSumForValidation) {
            return true;
          }

          return ctx.createError({
            message: `${
              ctx.schema.spec.label
            } must be greater than ${businessInterruptionCoverTitle} ${
              commercialCombinedStep7Schema(null).fields.businessInterruptionSum
                .spec.label
            } (${currencyFormat(
              ctx.parent.businessInterruptionCoverSumForValidation,
              0
            )})`
          });
        }
      ),
    annualManualWageRoll: number().nullable().required(),                     
    annualClericalWageRoll: number().nullable().required(),
    validator: mixed().test('positiveWagesSum', '', (_, ctx) => {
      if (
        (ctx.parent.annualManualWageRoll || 0) +
          (ctx.parent.annualClericalWageRoll || 0) <=
        0
      ) {
        return ctx.createError({
          message: 'Sum of clerical and manual wages must be greater than £0.'
        });
      }

      return true;
    }),   
    businessInterruptionCoverSumForValidation: number()
  });

export const CommercialCombinedStep8ViewModelInitialValue: CommercialCombinedStep8ViewModel =
  {
    activities: []
  };
export const CommercialCombinedStep8ViewModelInitialValueDev: CommercialCombinedStep8ViewModel =
  {
    activities: [{ id: 2, name: 'Accountants or Bookkeepers', value: 100 }],
    insuredTradeTypeId: 1,
    annualTurnover: 50000,
    annualManualWageRoll: 20000,
    annualClericalWageRoll: 10000,
    businessInterruptionCoverSumForValidation:
      CommercialCombinedStep7ViewModelInitialValueDev.businessInterruptionCoverAdded
        ? CommercialCombinedStep7ViewModelInitialValueDev.businessInterruptionSum !=
          null
          ? CommercialCombinedStep7ViewModelInitialValueDev.businessInterruptionSum.reduce(
              (a, c) => (a || 0) + (c || 0),
              0
            ) || 0
          : undefined
        : undefined
  };

export function useCommercialCombinedStep8InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep8ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep8ViewModelInitialValueDev
        : CommercialCombinedStep8ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}