import { Add, Info, Remove } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo
} from 'react';
import { Button, Collapse, OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoType } from './controls/form/form-label';

interface CcAdditionalCoverCollapseProps {
  title: string;
  extraClassName?: string | null;
  formikName?: string;
  opened?: boolean;
  setOpened?: (opened: boolean) => void;
  onClose?: () => void;
  onBeforeOpen?: () => void;
  info?: InfoType;
}

type CcAdditionalCoverCollapseComponent = FC<
  PropsWithChildren<CcAdditionalCoverCollapseProps>
>;

const CcAdditionalCoverCollapse: CcAdditionalCoverCollapseComponent = ({
  title,
  extraClassName,
  formikName,
  opened,
  setOpened,
  onClose,
  onBeforeOpen,
  children,
  info
}) => {
  const formikContext = useFormikContext();
  const isOpen = useMemo<boolean>(() => {
    if (opened !== undefined) {
      return opened;
    }

    if (formikContext && formikName) {
      return !!formikContext.getFieldProps(formikName).value;
    }

    return false;
  }, [formikContext, formikName, opened]);

  const setOpenClose = useCallback(
    (open: boolean) => {
      if (open && onBeforeOpen) {
        onBeforeOpen();
      }
      if (setOpened !== undefined) {
        setOpened(open);
      }

      if (formikContext && formikName) {
        formikContext.setFieldValue(formikName, open, false);
      }

      if (!open && onClose) {
        onClose();
      }
    },
    [onBeforeOpen, setOpened, formikContext, formikName, onClose]
  );

  const popover: (infoData: InfoType) => ReactElement = (infoData) => (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{infoData.header}</Popover.Header>
      <Popover.Body>
        <div dangerouslySetInnerHTML={{ __html: infoData.content }} />
      </Popover.Body>
    </Popover>
  );
  return (
    <div
      className={`border border-primary rounded-4 p-3 mt-3 ${extraClassName}`.trim()}
    >
      <div className="d-flex align-items-center">
        <div className="h6 my-0">{title}</div>
        <div className="ms-auto d-flex align-items-center">
          {info && info.content && (
            <OverlayTrigger trigger={['focus']} overlay={popover(info)}>
              <Button
                variant=""
                className="ms-auto text-secondary p-0 border-0 bg-transparent"
                onClick={(e) => e.currentTarget.focus()}
              >
                <Info sx={{ fontSize: 20 }} />
              </Button>
            </OverlayTrigger>
          )}
          <Button
            variant=""
            className={`ms-2 btn-collapse ${isOpen ? 'open' : ''}`}
            onClick={() => setOpenClose(!isOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={isOpen}
          >
            {isOpen ? (
              <Remove sx={{ fontSize: 16 }} />
            ) : (
              <Add sx={{ fontSize: 16 }} />
            )}
          </Button>
        </div>
      </div>
      <Collapse in={isOpen}>
        <div id="example-collapse-text">
          <div className="border-top border-primary border-dashed mt-3 pt-4">
            {children}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CcAdditionalCoverCollapse;
