import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, mixed, number, object, SchemaOf, string } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep9ViewModel>();

export interface CommercialCombinedStep9Props {
  initialValue: CommercialCombinedStep9ViewModel;
}

export type CommercialCombinedStep9Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep9Props
>;

export interface CommercialCombinedStep9ViewModel {
  numberOfManualEmployees?: number | null;
  numberOfClericalEmployees?: number | null;
  paye?: boolean | null;
  payeERN?: string | null;
  payeERNExempt: boolean;
}

export const commercialCombinedStep9Schema: SchemaOf<CommercialCombinedStep9ViewModel> =
  object({
    numberOfManualEmployees: number()
      .nullable()
      .label('Number of Manual Employees'),
    numberOfClericalEmployees: number()
      .nullable()
      .label('Number of Clerical Employees'),
    validator: mixed().test('atLeastOneEmployee', '', (_, ctx) => {
      if (
        (ctx.parent.numberOfManualEmployees || 0) +
          (ctx.parent.numberOfClericalEmployees || 0) <=
        0
      ) {
        return ctx.createError({
          message: 'Number of employees must be at least one'
        });
      }

      return true;
    }),
    paye: boolean()
      .nullable()
      .required()
      .label('Do you operate a PAYE system for your employees'),
    payeERN: requiredIf(
      string().nullable().label('Employers Reference Number (ERN)'),
      ['paye', 'payeERNExempt'],
      (paye: boolean, payeERNExempt: boolean) => paye && !payeERNExempt
    ),
    payeERNExempt: boolean().defined()
  });

export const CommercialCombinedStep9ViewModelInitialValue: CommercialCombinedStep9ViewModel =
  { payeERNExempt: false };
export const CommercialCombinedStep9ViewModelInitialValueDev: CommercialCombinedStep9ViewModel =
  {
    numberOfManualEmployees: 1,
    numberOfClericalEmployees: 2,
    paye: false,
    payeERNExempt: false
  };

export function useCommercialCombinedStep9InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep9ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep9ViewModelInitialValueDev
        : CommercialCombinedStep9ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
