import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep4Props {
  initialValue: CommercialCombinedStep4ViewModel;
}

export type CommercialCombinedStep4Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep4Props
>;

export interface CommercialCombinedStep4ViewModel {
  plIndemnityLevel: string;
  elIndemnityLevel: string;
}

export const commercialCombinedStep4Schema: SchemaOf<CommercialCombinedStep4ViewModel> =
  object().shape({
    plIndemnityLevel: string().required('Please select at least one item'),
    elIndemnityLevel: string().required('Please select at least one item')
  });

export const CommercialCombinedStep4ViewModelInitialValue: CommercialCombinedStep4ViewModel =
  { plIndemnityLevel: '', elIndemnityLevel: '' };
export const CommercialCombinedStep4ViewModelInitialValueDev: CommercialCombinedStep4ViewModel =
  { plIndemnityLevel: '1000000', elIndemnityLevel: '10000000' };

export function useCommercialCombinedStep4InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep4ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];
    
    return {
      ...(devForcePp
        ? CommercialCombinedStep4ViewModelInitialValueDev
        : CommercialCombinedStep4ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, thisStep === currentStep]);
}
