// #region imports
import FormMultiStepGroup from 'components/controls/form/form-multi-step-group';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import {
  FC,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback
} from 'react';
import AxiosRequestManagerSummary from 'common/axios-request-manager/AxiosRequestManagerSummary';
import CommercialCombinedIndicator from './Indicator';
import {
  useGetClientDataAsync,
  useGetDataAsync,
  useQuoteAsync,
  useSubmitAsync
} from 'features/commercialCombined/quote';
import CommercialCombinedStep1 from './Step1';
import { useCommercialCombinedStep1InitialValue } from './Step1.models';
import CommercialCombinedStep2 from './Step2';
import {
  CommercialCombinedStep2ViewModel,
  useCommercialCombinedStep2InitialValue
} from './Step2.models';
import CommercialCombinedStep3 from './Step3';
import { useCommercialCombinedStep3InitialValue } from './Step3.models';
import CommercialCombinedStep4 from './Step4';
import { useCommercialCombinedStep4InitialValue } from './Step4.models';
import CommercialCombinedStep5 from './Step5';
import { CommercialCombinedStep5ViewModel, useCommercialCombinedStep5InitialValue } from './Step5.models';
import CommercialCombinedStep6 from './Step6';
import { useCommercialCombinedStep6InitialValue } from './Step6.models';
import CommercialCombinedStep7 from './Step7';
import { useCommercialCombinedStep7InitialValue } from './Step7.models';
import CommercialCombinedStep8 from './Step8';
import { useCommercialCombinedStep8InitialValue } from './Step8.models';
import CommercialCombinedStep9 from './Step9';
import { useCommercialCombinedStep9InitialValue } from './Step9.models';
import CommercialCombinedStep10 from './Step10';
import { useCommercialCombinedStep10InitialValue } from './Step10.models';
import CommercialCombinedStep11 from './Step11';
import { useCommercialCombinedStep11InitialValue } from './Step11.models';
import CommercialCombinedStep12 from './Step12';
import { useCommercialCombinedStep12InitialValue } from './Step12.models';
import CommercialCombinedStep13 from './Step13';
import { useCommercialCombinedStep13InitialValue } from './Step13.models';
import CommercialCombinedStep14 from './Step14';
import { useCommercialCombinedStep14InitialValue } from './Step14.models';
import CommercialCombinedStep15 from './Step15';
import { useCommercialCombinedStep15InitialValue } from './Step15.models';
import CommercialCombinedStep16 from './Step16';
import { useCommercialCombinedStep16InitialValue } from './Step16.models';
import CommercialCombinedStep17 from './Step17';
import { useCommercialCombinedStep17InitialValue } from './Step17.models';
import CommercialCombinedStep18 from './Step18';
import { useCommercialCombinedStep18InitialValue } from './Step18.models';
import CommercialCombinedStep19 from './Step19';
import { useCommercialCombinedStep19InitialValue } from './Step19.models';
import CommercialCombinedStep20 from './Step20';
import { useCommercialCombinedStep20InitialValue } from './Step20.models';
import CommercialCombinedStep21 from './Step21';
import { useCommercialCombinedStep21InitialValue } from './Step21.models';
import CommercialCombinedStep22 from './Step22';
import { useCommercialCombinedStep22InitialValue } from './Step22.models';
import CommercialCombinedStep23 from './Step23';
import { useCommercialCombinedStep23InitialValue } from './Step23.models';
import CommercialCombinedStep24 from './Step24';
import { useCommercialCombinedStep24InitialValue } from './Step24.models';
import CommercialCombinedStep25 from './Step25';
import { useCommercialCombinedStep25InitialValue } from './Step25.models';
import CommercialCombinedStep26 from './Step26';
import { useCommercialCombinedStep26InitialValue } from './Step26.models';
import CommercialCombinedStep27 from './Step27';
import { useCommercialCombinedStep27InitialValue } from './Step27.models';
import CommercialCombinedStep28 from './Step28';
import { useCommercialCombinedStep28InitialValue } from './Step28.models';
import { backOfficeBaseURL, brokerBaseURL, devForcePp } from 'config';
import { useNavigate, useParams } from 'react-router-dom';
import { CommercialCombinedQuoteResultViewModel } from 'features/commercialCombined/quoteResultViewModel';

// import CommercialCombinedStep99 from './Step99';
// import { useCommercialCombinedStep99InitialValue } from './Step99.models';
// #endregion

const startingStepPp = 1;

export const firstOccupationStepIndex = 0;
export const coverTypeSelectionStepIndex = 1;
export const coverRequiredStepIndex = 3;
export const locationsStepIndex = 4;
export const coverDetailsItemsStepIndex = 5;
export const contactDetailsStepIndex = 6;
export const businessInterruptionCoverStepIndex = 6;
export const riskDetailsStepIndexBegin = 7;
export const operationsStepIndexBegin = 15;
export const operationsStepIndexEnd = 17;
export const premisesExtraQuestionsStepIndexBegin = 18;
export const premisesExtraQuestionsStepIndexEnd = 24;
export const startDateStepIndex = 26;
export const policyNumberStepIndex = 27;

export const storeNameStep = 'step';
export const storeNameData = 'data';
export const storeQuoteData = 'quoteData';

export const defaultDataValue = () => new Array<any>(28);

const CommercialCombinedIndex: FC<{
  isBackOffice?: boolean;
  isBroker?: boolean; 
}> = ({ isBackOffice, isBroker }) => {
  const { guid, clientId } = useParams();
  const quoteSubscribers = useRef(new Set<() => void>());

  let initialData: CommercialCombinedQuoteResultViewModel = { refer: false };
  const strQuoteData = sessionStorage.getItem(storeQuoteData);
  if (strQuoteData) initialData = JSON.parse(strQuoteData);

  const quoteRef = useRef<CommercialCombinedQuoteResultViewModel>(initialData);

  const quoteGet = useCallback(() => quoteRef.current, []);
  const quoteSet = useCallback(
    (data: Partial<CommercialCombinedQuoteResultViewModel>) => {
      quoteRef.current = { ...quoteRef.current, ...data };
      sessionStorage.setItem(storeQuoteData, JSON.stringify(quoteRef.current));
      quoteSubscribers.current.forEach((s) => s());
    },
    []
  );
  const quoteSubscribe = useCallback((callback: () => void) => {
    quoteSubscribers.current.add(callback);
    return () => quoteSubscribers.current.delete(callback);
  }, []);

  const [step, setStep] = useState<number>(() => {
    const strData = sessionStorage.getItem(storeNameStep);
    if (!strData) {
      return 0;
    }
    return Number(strData);
  });
  const setStepStore = useCallback(
    (data: ((payload: number) => number) | number) => {
      let result = step;
      if (typeof data === 'function') {
        result = data(result);
      } else {
        result = data;
      }

      sessionStorage.setItem(storeNameStep, result.toString());
      setStep(result);
    },
    [step]
  );

  const [data, setData] = useState<Array<any>>(() => {
    const strData = sessionStorage.getItem(storeNameData);
    let aData = defaultDataValue();
    if (strData) {
      aData = JSON.parse(strData);
    }

    return aData;
  });
  const setDataStore: Dispatch<SetStateAction<Array<any>>> = useCallback(
    (value) => {
      let result: Array<any> = [];
      if (typeof value === 'function') {
        result = value(data);
      } else {
        result = value;
      }

      sessionStorage.setItem(storeNameData, JSON.stringify(result));
      setData(result);
    },
    [data]
  );

  const [loading, setLoading] = useState(
    (!!isBackOffice || !!isBroker) && (!!guid || !!clientId)
  );

  // #region initial values
  const step1 = useRef<ForwardedRefProps>(null);
  const step1IV = useCommercialCombinedStep1InitialValue(data, 1, step);
  const step2 = useRef<ForwardedRefProps>(null);
  const step2IV = useCommercialCombinedStep2InitialValue(data, 2, step);
  const step3 = useRef<ForwardedRefProps>(null);
  const step3IV = useCommercialCombinedStep3InitialValue(data, 3, step);
  const step4 = useRef<ForwardedRefProps>(null);
  const step4IV = useCommercialCombinedStep4InitialValue(data, 4, step);
  const step5 = useRef<ForwardedRefProps>(null);
  const step5IV = useCommercialCombinedStep5InitialValue(data, 5, step);
  const step6 = useRef<ForwardedRefProps>(null);
  const step6IV = useCommercialCombinedStep6InitialValue(data, 6, step);
  const step7 = useRef<ForwardedRefProps>(null);
  const step7IV = useCommercialCombinedStep7InitialValue(data, 7, step);
  const step8 = useRef<ForwardedRefProps>(null);
  const step8IV = useCommercialCombinedStep8InitialValue(data, 8, step);
  const step9 = useRef<ForwardedRefProps>(null);
  const step9IV = useCommercialCombinedStep9InitialValue(data, 9, step);
  const step10 = useRef<ForwardedRefProps>(null);
  const step10IV = useCommercialCombinedStep10InitialValue(data, 10, step);
  const step11 = useRef<ForwardedRefProps>(null);
  const step11IV = useCommercialCombinedStep11InitialValue(data, 11, step);
  const step12 = useRef<ForwardedRefProps>(null);
  const step12IV = useCommercialCombinedStep12InitialValue(data, 12, step);
  const step13 = useRef<ForwardedRefProps>(null);
  const step13IV = useCommercialCombinedStep13InitialValue(data, 13, step);
  const step14 = useRef<ForwardedRefProps>(null);
  const step14IV = useCommercialCombinedStep14InitialValue(data, 14, step);
  const step15 = useRef<ForwardedRefProps>(null);
  const step15IV = useCommercialCombinedStep15InitialValue(data, 15, step);
  const step16 = useRef<ForwardedRefProps>(null);
  const step16IV = useCommercialCombinedStep16InitialValue(data, 16, step);
  const step17 = useRef<ForwardedRefProps>(null);
  const step17IV = useCommercialCombinedStep17InitialValue(data, 17, step);
  const step18 = useRef<ForwardedRefProps>(null);
  const step18IV = useCommercialCombinedStep18InitialValue(data, 18, step);
  const step19 = useRef<ForwardedRefProps>(null);
  const step19IV = useCommercialCombinedStep19InitialValue(data, 19, step);
  const step20 = useRef<ForwardedRefProps>(null);
  const step20IV = useCommercialCombinedStep20InitialValue(data, 20, step);
  const step21 = useRef<ForwardedRefProps>(null);
  const step21IV = useCommercialCombinedStep21InitialValue(data, 21, step);
  const step22 = useRef<ForwardedRefProps>(null);
  const step22IV = useCommercialCombinedStep22InitialValue(data, 22, step);
  const step23 = useRef<ForwardedRefProps>(null);
  const step23IV = useCommercialCombinedStep23InitialValue(data, 23, step);
  const step24 = useRef<ForwardedRefProps>(null);
  const step24IV = useCommercialCombinedStep24InitialValue(data, 24, step);
  const step25 = useRef<ForwardedRefProps>(null);
  const step25IV = useCommercialCombinedStep25InitialValue(data, 25, step);
  const step26 = useRef<ForwardedRefProps>(null);
  const step26IV = useCommercialCombinedStep26InitialValue(data, 26, step);
  const step27 = useRef<ForwardedRefProps>(null);
  const step27IV = useCommercialCombinedStep27InitialValue(data, 27, step);
  const step28 = useRef<ForwardedRefProps>(null);
  const step28IV = useCommercialCombinedStep28InitialValue(data, 28, step);

  // const step99 = useRef<ForwardRefHandler>(null);
  // const step99IV = useCommercialCombinedStep99InitialValue(data, 99, step);
  // #endregion

  const navigate = useNavigate();
  const quoteAsync = useQuoteAsync(); 

  const quoteCB = useCallback(
    async (currentData: Array<any>) => {
      const quoteResult = await quoteAsync({
        step: 5,
        data: currentData
      });
      quoteSet(quoteResult);
    },
    [quoteAsync, quoteSet]
  );

  const totalNumSteps = 27;

  const onBeforeNavigateHandler = async (
    newData: Array<any>,
    direction: 'prev' | 'next'
  ) => {
    if (direction === 'next') {
      const step2ViewModel = newData[
        coverTypeSelectionStepIndex
      ] as CommercialCombinedStep2ViewModel;
      if (
        step < totalNumSteps &&
        quoteRef.current &&
        step2ViewModel &&
        (quoteRef.current?.refer || step > 5)
      ) {
        quoteSet({
          refer: false,
          quotePrice: null,
          excesses: null,
          guid: null,
          paymentRef: null
        });
        quoteCB(newData);
      }
    }
  };

  const getDataAsync = useGetDataAsync();
  const getClientDataSync = useGetClientDataAsync();

  useEffect(() => {
    async function getData(quoteGuid: string, clientId: string | undefined) {
      try {
        const response = quoteGuid?  await getDataAsync(quoteGuid): await getClientDataSync(clientId!);
        setDataStore(response);
        setStepStore(1);
      } catch {
        if (isBackOffice) {
          window.location.href = `${backOfficeBaseURL}/back-office`;
        } else if (isBroker) {
          window.location.href = `${brokerBaseURL}/broker`;
        }
      }

      setLoading(false);
    }
    if (loading) {
      getData(guid!, clientId);
      return;
    }

    if (devForcePp && step <= 1) {
      var newData = [...data];
      for (let i = 0; i < startingStepPp - 1; i++) {
        // eslint-disable-next-line no-eval
        newData[i] = eval(`step${i + 1}IV`);
        switch (i) {
          case locationsStepIndex:
            if (startingStepPp > premisesExtraQuestionsStepIndexEnd + 1) {
              (
                newData[i] as CommercialCombinedStep5ViewModel
              ).premisesExtraQuestionsLocationIndex =
                step5IV.locations.length - 1;
            }
            break;
        }
      }
      setDataStore(newData);
      setStepStore(startingStepPp);
      if (startingStepPp > coverDetailsItemsStepIndex) {
        quoteCB(newData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitAsync = useSubmitAsync();
  const onFinish = useCallback(async () => {
    try {
      const result = await submitAsync(guid, {
        step: policyNumberStepIndex + 1,
        data
      });
      setStepStore(1);
      setDataStore(defaultDataValue());

      navigate(`/pay/${result.guid}`);
    } catch {
      return;
    }
  }, [guid, data, navigate, setDataStore, setStepStore, submitAsync]);

  if (loading) {
    return <div>Retrieving data...</div>;
  }

  return (
    <>
      <FormMultiStepGroup
        config={{
          step: Math.max(1, step),
          setStep: setStepStore,
          data,
          setData: setDataStore,
          quotePubSub: {
            getQuote: quoteGet,
            setQuote: quoteSet,
            subscribeQuote: quoteSubscribe
          }
        }}
        indicator={CommercialCombinedIndicator}
        beforeActiveStep={<AxiosRequestManagerSummary />}
        onBeforeNavigate={onBeforeNavigateHandler}
        onFinish={onFinish}
        startOver={() => {
          setStepStore(1);
          setDataStore(defaultDataValue());
        }}
      >
        <FormMultiStepGroup.Item handler={() => step1.current!.handler}>
          <CommercialCombinedStep1 initialValue={step1IV} ref={step1} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step2.current!.handler}>
          <CommercialCombinedStep2 initialValue={step2IV} ref={step2} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step3.current!.handler}>
          <CommercialCombinedStep3 initialValue={step3IV} ref={step3} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step4.current!.handler}>
          <CommercialCombinedStep4 initialValue={step4IV} ref={step4} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step5.current!.handler}>
          <CommercialCombinedStep5 initialValue={step5IV} ref={step5} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step6.current!.handler}>
          <CommercialCombinedStep6 initialValue={step6IV} ref={step6} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step7.current!.handler}>
          <CommercialCombinedStep7 initialValue={step7IV} ref={step7} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step8.current!.handler}>
          <CommercialCombinedStep8 initialValue={step8IV} ref={step8} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step9.current!.handler}>
          <CommercialCombinedStep9 initialValue={step9IV} ref={step9} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step10.current!.handler}>
          <CommercialCombinedStep10 initialValue={step10IV} ref={step10} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step11.current!.handler}>
          <CommercialCombinedStep11 initialValue={step11IV} ref={step11} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step12.current!.handler}>
          <CommercialCombinedStep12 initialValue={step12IV} ref={step12} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step13.current!.handler}>
          <CommercialCombinedStep13 initialValue={step13IV} ref={step13} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step14.current!.handler}>
          <CommercialCombinedStep14 initialValue={step14IV} ref={step14} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step15.current!.handler}>
          <CommercialCombinedStep15 initialValue={step15IV} ref={step15} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step16.current!.handler}>
          <CommercialCombinedStep16 initialValue={step16IV} ref={step16} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step17.current!.handler}>
          <CommercialCombinedStep17 initialValue={step17IV} ref={step17} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step18.current!.handler}>
          <CommercialCombinedStep18 initialValue={step18IV} ref={step18} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step19.current!.handler}>
          <CommercialCombinedStep19 initialValue={step19IV} ref={step19} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step20.current!.handler}>
          <CommercialCombinedStep20 initialValue={step20IV} ref={step20} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step21.current!.handler}>
          <CommercialCombinedStep21 initialValue={step21IV} ref={step21} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step22.current!.handler}>
          <CommercialCombinedStep22 initialValue={step22IV} ref={step22} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step23.current!.handler}>
          <CommercialCombinedStep23 initialValue={step23IV} ref={step23} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step24.current!.handler}>
          <CommercialCombinedStep24 initialValue={step24IV} ref={step24} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step25.current!.handler}>
          <CommercialCombinedStep25 initialValue={step25IV} ref={step25} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step26.current!.handler}>
          <CommercialCombinedStep26 initialValue={step26IV} ref={step26} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step27.current!.handler}>
          <CommercialCombinedStep27 initialValue={step27IV} ref={step27} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item handler={() => step28.current!.handler}>
          <CommercialCombinedStep28 initialValue={step28IV} ref={step28} />
        </FormMultiStepGroup.Item>

        {/* <FormMultiStepGroup.Item handler={() => step99.current!.handler}>
        <CommercialCombinedStep99 initialValue={step99IV} ref={step99} />
      </FormMultiStepGroup.Item> */}
      </FormMultiStepGroup>
    </>
  );
};

export default CommercialCombinedIndex;
