import nameOf from 'common/functions/NameOfHelper';
import { FC } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

export interface PostcodeLookupResponseViewModel {
  result: PostcodeLookupResponseItemViewModel[];
}

export interface PostcodeLookupResponseItemViewModel {
  value: string;
  text: string;
}

export interface PostcodeLookupRetrieveItemViewModel {
  postcode: string;
  address1: string;
  address2: string;
  town?: string | null;
  city?: string | null;
  county: string;
}

export interface PostcodeLookupViewModel
  extends PostcodeLookupRetrieveItemViewModel {
  showAddress: boolean;
}

export const postcodeLookupSchema: SchemaOf<PostcodeLookupViewModel> =
  object().shape({
    showAddress: boolean().required(),
    postcode: string()
      .required()
      .label('Postcode')
      .when(nameOf<PostcodeLookupViewModel>('showAddress'), {
        is: false,
        then: (schema) =>
          schema.test(
            'postcodeLookupPostcodeShowAddress',
            'You must either lookup your address or click Enter manually',
            () => false
          )
      }),
    address1: string()
      .label('House No')
      .optional()
      .when(nameOf<PostcodeLookupViewModel>('showAddress'), {
        is: true,
        then: (schema) => schema.required()
      })
      .default(''),
    address2: string()
      .label('Street')
      .optional()
      .when(nameOf<PostcodeLookupViewModel>('showAddress'), {
        is: true,
        then: (schema) => schema.required()
      })
      .default(''),
    town: string().nullable(true).optional(),
    city: string().nullable(true).optional(),
    county: string()
      .label('County')
      .optional()
      .when(nameOf<PostcodeLookupViewModel>('showAddress'), {
        is: true,
        then: (schema) => schema.required()
      })
      .default('')
  });

export const postcodeLookupViewModelInitialValue: PostcodeLookupViewModel = {
  showAddress: false,
  postcode: '',
  address1: '',
  address2: '',
  town: '',
  city: '',
  county: ''
};

export const postcodeLookupViewModelInitialValueDev1: PostcodeLookupViewModel =
  {
    showAddress: true,
    postcode: 'EC3N 1NT',
    address1: 'Clegg Gifford Insurance',
    address2: '128-129 Minories',
    town: '',
    city: '',
    county: 'London'
  };

export const postcodeLookupViewModelInitialValueDev2: PostcodeLookupViewModel =
  {
    showAddress: true,
    postcode: 'EC3A 2AD',
    address1: 'MagiClick',
    address2: '65 Leadenhall St',
    town: '',
    city: '',
    county: 'London'
  };

export interface PostcodeLookupProps {
  name: string;
}

export type PostcodeLookupComponent = FC<PostcodeLookupProps>;
