import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, number, object, SchemaOf } from 'yup';

export interface CommercialCombinedStep13Props {
  initialValue: CommercialCombinedStep13ViewModel;
}

export type CommercialCombinedStep13Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep13Props
>;

export interface CommercialCombinedStep13ViewModel {
  undertakenWork?: boolean | null;
  undertakenWorkPercentage?: number | null;
}

export const commercialCombinedStep13Schema: SchemaOf<CommercialCombinedStep13ViewModel> =
  object({
    undertakenWork: boolean().nullable().required(),
    undertakenWorkPercentage:
      requiredIfTyped<CommercialCombinedStep13ViewModel>()(
        number().nullable().optional(),
        'undertakenWork'
      )
  });

export const CommercialCombinedStep13ViewModelInitialValue: CommercialCombinedStep13ViewModel =
  {
    undertakenWork: null,
    undertakenWorkPercentage: null
  };
export const CommercialCombinedStep13ViewModelInitialValueDev: CommercialCombinedStep13ViewModel =
  {
    undertakenWork: false,
    undertakenWorkPercentage: null
  };

export function useCommercialCombinedStep13InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep13ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep13ViewModelInitialValueDev
        : CommercialCombinedStep13ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
