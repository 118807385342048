import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean
} from 'components/controls';
import FormTextBoxPercentage from 'components/controls/form/form-text-box-percentage';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep10Component,
  commercialCombinedStep10Schema,
  CommercialCombinedStep10ViewModel
} from './Step10.models';

const getFieldName = nameOf<CommercialCombinedStep10ViewModel>;

const CommercialCombinedStep10: CommercialCombinedStep10Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep10ViewModel>(ref);

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep10Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Risk Details" />
        <FormRadioButtonListBoolean
          name={getFieldName('employBonaFide')}
          label-text="Do you employ Bona Fide Subcontractors?"
          label-info={{
            header: 'Bona Fide Subcontractor',
            content: 'Bona-fide sub-contractors are generally deemed to be contractors who work without direction from the Insured, hold their own insurance and usually provide their own materials and tools'
          }}
        />
        <FormConditionalCollapse name={getFieldName('employBonaFide')}>
          <FormTextBoxPercentage
            name={getFieldName('bonaFideAnnualPct')}
            label-subElement={true}
            label-text="What Percentage of Annual Turnover is undertaken by Bona Fide Subcontractors?"
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep10);
