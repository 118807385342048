import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import {
  mocked_PaymentViewModel_Get,
  PaymentViewModel
} from './paymentViewModel';

export function useGetPaymentAsync(): (
  quoteGuid: string
) => Promise<PaymentViewModel> {
  const { getAsync } = useAxiosRequestManager();

  return async (quoteGuid) =>
    (
      await getAsync(
        `/payment/${quoteGuid}`,
        undefined,
        mocked_PaymentViewModel_Get
      )
    ).data!;
}

export function usePayAsync(): (
  quoteGuid: string,
  paymentId: string
) => Promise<void> {
  const { postAsync } = useAxiosRequestManager();

  return async (quoteGuid, paymentId) => {
    await postAsync(`/payment`, undefined, { quoteGuid, paymentId }, null);
  };
}
