export interface PaymentViewModel {
  paymentRef: string;
  clientName: string;
  postCode: string;
  coverTypeIds: Array<number>;
  quotePrice: number;
}

export const mocked_PaymentViewModel_Get: PaymentViewModel = {
  paymentRef: 'DEF456',
  clientName: 'John Smith',
  postCode: 'EC3A 2AD',
  coverTypeIds: [1, 2],
  quotePrice: 123
};
