import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import CcAdditionalCoverCollapse from 'components/cc-additional-cover-collapse';
import { FormControlError, FormTextBoxCurrency } from 'components/controls';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { Formik } from 'formik';
import { forwardRef, Fragment, useMemo } from 'react';
import {
  CommercialCombinedStep6Component,
  CommercialCombinedStep6CoverDetailsItemViewModel,
  commercialCombinedStep6Schema,
  CommercialCombinedStep6ViewModel,
  ValuesType
} from './Step6.models';
import { useQuoteAsync } from 'features/commercialCombined/quote';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { locationsStepIndex } from './index';
import { PostcodeLookupViewModel } from 'components/postcode-lookup.models';

const CommercialCombinedStep6: CommercialCombinedStep6Component = (
  { initialValue },
  ref
) => {
  const quoteAsync = useQuoteAsync();
  const multiStepContext = useFormMultiStepGroupContext();

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep6ViewModel>(ref, {
      onBeforeNextAsync: async (data: any[]) => {
        try {
          const quoteResult = await quoteAsync({
            step: 5,
            data
          });
          if (multiStepContext.quotePubSub) {
            multiStepContext.quotePubSub.setQuote(quoteResult);
          }
          return true;
        } catch {
          return false;
        }
      }
    });

  const locations = useMemo<Array<PostcodeLookupViewModel>>(
    () => multiStepContext.data[locationsStepIndex]?.locations || [],
    [multiStepContext.data]
  );

  function getCoverDetailsItemsFieldNameByIndex(
    index: number,
    field: Extract<
      keyof CommercialCombinedStep6CoverDetailsItemViewModel,
      string
    >
  ) {
    return `${nameOf<CommercialCombinedStep6ViewModel>(
      'coverDetailsItems'
    )}[${index}].${field}`;
  }

  const vehiclesAtPremiseIdx = 10;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep6Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ values: { coverDetailsItems }, setFieldValue, getFieldMeta }) => (
        <>
          <Heading title="Cover Details" />
          <SubHeading title="Please select all that apply." />
          {[...coverDetailsItems]
            .splice(0, vehiclesAtPremiseIdx)
            .map((item, itemIdx: number) => (
              <CcAdditionalCoverCollapse
                key={`cover${itemIdx}`}
                title={item.title}
                opened={item.added}
                onBeforeOpen={() => {
                  const newArray: ValuesType = locations.map((x) => [
                    undefined
                  ]);

                  setFieldValue(
                    getCoverDetailsItemsFieldNameByIndex(itemIdx, 'values'),
                    newArray,
                    false
                  );

                  setTimeout(() => {
                    setFieldValue(
                      getCoverDetailsItemsFieldNameByIndex(itemIdx, 'added'),
                      true,
                      false
                    );
                  }, 1);
                }}
                onClose={() => {
                  setFieldValue(
                    getCoverDetailsItemsFieldNameByIndex(itemIdx, 'values'),
                    [],
                    false
                  );

                  setTimeout(() => {
                    setFieldValue(
                      getCoverDetailsItemsFieldNameByIndex(itemIdx, 'added'),
                      false,
                      false
                    );
                  }, 1);
                }}
                info={item.info}
              >
                <>
                  <div className="errorParentWrapper">
                    <FormControlError
                      mb={4}
                      mt={0}
                      msg={
                        getFieldMeta(
                          getCoverDetailsItemsFieldNameByIndex(itemIdx, 'added')
                        )?.error
                      }
                    />
                  </div>
                  {locations.map((location, viIdx) => {
                    const thisFieldName = `${getCoverDetailsItemsFieldNameByIndex(
                      itemIdx,
                      'values'
                    )}[${viIdx}][0]`;

                    return (
                      <FormTextBoxCurrency
                        key={`cover${itemIdx}_v${viIdx}`}
                        name={thisFieldName}
                        label-text={`Location ${viIdx + 1}: ${
                          location.postcode
                        }`}
                      />
                    );
                  })}
                </>
              </CcAdditionalCoverCollapse>
            ))}

          <CcAdditionalCoverCollapse
            title={coverDetailsItems[vehiclesAtPremiseIdx].title}
            extraClassName="mb-4"
            opened={coverDetailsItems[vehiclesAtPremiseIdx].added}
            onBeforeOpen={() => {
              const newArray: ValuesType = locations.map((x) => [
                undefined,
                undefined,
                undefined
              ]);

              setFieldValue(
                getCoverDetailsItemsFieldNameByIndex(
                  vehiclesAtPremiseIdx,
                  'values'
                ),
                newArray,
                false
              );

              setTimeout(() => {
                setFieldValue(
                  getCoverDetailsItemsFieldNameByIndex(
                    vehiclesAtPremiseIdx,
                    'added'
                  ),
                  true,
                  false
                );
              }, 1);
            }}
            onClose={() => {
              setFieldValue(
                getCoverDetailsItemsFieldNameByIndex(
                  vehiclesAtPremiseIdx,
                  'values'
                ),
                [],
                false
              );

              setTimeout(() => {
                setFieldValue(
                  getCoverDetailsItemsFieldNameByIndex(
                    vehiclesAtPremiseIdx,
                    'added'
                  ),
                  false,
                  false
                );
              }, 1);
            }}
            info={coverDetailsItems[vehiclesAtPremiseIdx].info}
          >
            <>
              <div className="errorParentWrapper">
                <FormControlError
                  mb={4}
                  mt={0}
                  msg={
                    getFieldMeta(
                      getCoverDetailsItemsFieldNameByIndex(
                        vehiclesAtPremiseIdx,
                        'added'
                      )
                    )?.error
                  }
                />
              </div>

              {locations.map((location, viIdx) => {
                const thisFieldName = `${getCoverDetailsItemsFieldNameByIndex(
                  vehiclesAtPremiseIdx,
                  'values'
                )}[${viIdx}]`;

                return (
                  <Fragment key={`cover${vehiclesAtPremiseIdx}_v${viIdx}`}>
                    <SubHeading
                      title={`Location ${viIdx + 1}${
                        location.postcode.trim() ? `: ${location.postcode}` : ''
                      }`}
                    />
                    <FormTextBoxCurrency
                      name={`${thisFieldName}[0]`}
                      label-text="Vehicles in Building"
                    />
                    <FormTextBoxCurrency
                      name={`${thisFieldName}[1]`}
                      label-text="Vehicles in Compound"
                    />
                    <FormTextBoxCurrency
                      name={`${thisFieldName}[2]`}
                      label-text="Vehicles in the open"
                    />
                    <FormTextBoxCurrency
                      name={`${thisFieldName}[3]`}
                      label-text="Do you handle any vehicles that are worth more than £50,000, if so please state the maximum value any one vehicle you require"
                    />
                  </Fragment>
                );
              })}
            </>
          </CcAdditionalCoverCollapse>
        </>
      )}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep6);
