import FormMultiStepGroup from 'components/controls/form/form-multi-step-group';
import { useMemo, useRef, useState } from 'react';
import Step1 from './Step1';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import {
  MultiStepExampleStep1ViewModel,
  multiStepExampleStep1ViewModelInitialValue
} from './Step1.models';
import Step2 from './Step2';

const MultiStepExampleIndex = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(new Array<any>(3));
  const step1 = useRef<ForwardedRefProps>(null);

  const step1InitialValue = useMemo<MultiStepExampleStep1ViewModel>(() => {
    return {
      ...multiStepExampleStep1ViewModelInitialValue,
      ...(data[0] || {})
    };
  }, [data]);

  const onFinishHandler = () => {
    alert('FINISHED');
  };

  return (
    <div>
      <FormMultiStepGroup
        config={{ step, setStep, data, setData }}
        onFinish={onFinishHandler}
      >
        <FormMultiStepGroup.Item
          title="Step 1"
          handler={() => step1.current!.handler}
        >
          <Step1 initialValue={step1InitialValue} ref={step1} />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item title="Step 2">
          <Step2 />
        </FormMultiStepGroup.Item>
        <FormMultiStepGroup.Item title="Step 3">
          <div>Step4Content</div>
        </FormMultiStepGroup.Item>
      </FormMultiStepGroup>
    </div>
  );
};

export default MultiStepExampleIndex;
