import React from 'react';
import { useAxiosRequestManagerContext } from 'common/axios-request-manager/AxiosRequestManagerContext';

export default function AxiosRequestManagerSummary(props: {
  className?: string;
  titleClassName?: string;
  wrapperClassName?: string;
  as?: keyof JSX.IntrinsicElements;
  children?: (errMsg: string, idx: number) => React.ReactNode;
  header?: React.ReactNode;
  [prop: string]: any;
}) {
  let Wrapper = props.as;
  if (!Wrapper) {
    Wrapper = 'ul';
  }

  let ChildWrapper: keyof JSX.IntrinsicElements = 'li';
  if (props.as && props.as !== 'ul' && props.as !== 'ol') {
    ChildWrapper = 'p';
  }

  const { errors } = useAxiosRequestManagerContext();

  return (
    <>
      {errors && !!errors.length && (
        <div
          className={`${
            props.className || 'alert alert-danger rounded-0'
          } serverSideErrorList`}
          {...props}
        >
          {props.header !== undefined ? (
            props.header
          ) : (
            <p className={props.titleClassName || 'mb-1'}>
              <strong>Please review the following errors below:</strong>
            </p>
          )}
          <Wrapper className={props.wrapperClassName || 'mb-0'}>
            {errors.map((item, i) =>
              props.children ? (
                props.children(item, i)
              ) : (
                <ChildWrapper key={i}>{item}</ChildWrapper>
              )
            )}
          </Wrapper>
        </div>
      )}
    </>
  );
}
