import ConfirmationDocumentViewModel from './confirmationDocumentViewModel';
import { DocumentGenerationStatus } from './documentGenerationStatusEnum';

export default interface ConfirmationDocsViewModel {
  generationStatusID: number;
  documents?: Array<ConfirmationDocumentViewModel> | null;
}

export const mocked_ConfirmationDocsViewModel_Get: ConfirmationDocsViewModel = {
  generationStatusID: DocumentGenerationStatus.Pending
};
