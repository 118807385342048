import { createContext, useContext } from 'react';
import AxiosRequestManagerContextProps, {
  axiosRequestManagerContextPropsDefaultValue
} from './AxiosRequestManagerContextProps';

const AxiosRequestManagerContext =
  createContext<AxiosRequestManagerContextProps>(
    axiosRequestManagerContextPropsDefaultValue
  );

const useAxiosRequestManagerContext = () =>
  useContext(AxiosRequestManagerContext);

export default AxiosRequestManagerContext;
export { useAxiosRequestManagerContext };
