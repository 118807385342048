import moment from 'moment';
import ConfirmationDocumentViewModel from './confirmationDocumentViewModel';
import ConfirmationExcessViewModel from './confirmationExcessViewModel';
import ConfirmationTaxViewModel from './confirmationTaxViewModel';

export default interface ConfirmationViewModel {
  policyNumber: string;
  coverTypeIds?: Array<number> | null;
  quotePrice: number;
  quotePriceExclTaxes: number;
  taxes?: Array<ConfirmationTaxViewModel> | null;
  hasTaxes: boolean;
  taxNames?: string | null;
  excesses?: Array<ConfirmationExcessViewModel> | null;
  startDate: Date;
  endDate: Date;
  documents?: Array<ConfirmationDocumentViewModel> | null;
}

export const mocked_ConfirmationViewModel_Get: ConfirmationViewModel = {
  policyNumber: 'QC0001',
  coverTypeIds: [1, 2],
  quotePrice: 123,
  quotePriceExclTaxes: 120,
  taxes: [{ name: 'IPT', value: 3 }],
  hasTaxes: true,
  taxNames: 'IPT',
  excesses: [{ name: 'Excess1 Test', value: 56 }],
  startDate: moment().toDate(),
  endDate: moment().add(1, 'year').toDate(),
  documents: [
    {
      name: 'BBC Test',
      generationStatusID: 2,
      downloadURL: 'https://bbc.co.uk'
    }
  ]
};
