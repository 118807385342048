import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep20Props {
  initialValue: CommercialCombinedStep20ViewModel;
}

export type CommercialCombinedStep20Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep20Props
>;

export interface CommercialCombinedStep20ViewModel {
  locations: Array<CommercialCombinedStep20ItemViewModel>;
}

export interface CommercialCombinedStep20ItemViewModel {
  heatingFrostFree?: boolean | null;
  portableHeaters?: boolean | null;
  portableHeatersDetails?: string | null;
}

const commercialCombinedStep20ItemSchema: SchemaOf<CommercialCombinedStep20ItemViewModel> =
  object({
    heatingFrostFree: boolean().nullable().required().label('It'),
    portableHeaters: boolean().nullable().required().label('It'),
    portableHeatersDetails: requiredIf(
      string().nullable().label('It'),
      'portableHeaters'
    ),
  });

export const commercialCombinedStep20Schema: SchemaOf<CommercialCombinedStep20ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep20ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep20ViewModelInitialValue: CommercialCombinedStep20ViewModel =
  { locations: [] };
export const CommercialCombinedStep20ViewModelInitialValueDev: CommercialCombinedStep20ViewModel =
  {
    locations: [{ heatingFrostFree: true, portableHeaters: true }]
  };

export function useCommercialCombinedStep20InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep20ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep20ViewModelInitialValueDev
        : CommercialCombinedStep20ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
