import nameOf from 'common/functions/NameOfHelper';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormTextarea,
  FormTextBoxCurrency,
  FormRadioButtonListBoolean,
  FormTextBoxDate,
  FormControlError,
  FormRadioButtonListGeneric
} from 'components/controls';
import { Heading } from 'components/Heading';
import { Formik } from 'formik';
import { forwardRef, Fragment, useState } from 'react';
import {
  CommercialCombinedStep15ClaimViewModel,
  CommercialCombinedStep15ClaimViewModelInitialValue,
  CommercialCombinedStep15Component,
  commercialCombinedStep15Schema,
  CommercialCombinedStep15ViewModel
} from './Step15.models';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import {
  coverTypeSelectionStepIndex,
  operationsStepIndexBegin,
  operationsStepIndexEnd
} from './index';
import { SubHeading } from 'components';
import { optionsCC } from 'config/options';

const getFieldName = nameOf<CommercialCombinedStep15ViewModel>;

const CommercialCombinedStep15: CommercialCombinedStep15Component = (
  { initialValue },
  ref
) => {
  const maxNumberOfClaims = 5;

  const [addAnotherErrMsg, setAddAnotherErrMsg] = useState<string>('');
  const multiStepContext = useFormMultiStepGroupContext();
  const FormRadioButtonList = FormRadioButtonListGeneric<number>();
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep15ViewModel>(
      ref,
      {
        onBeforeNextAsync: () => {
          if (formikForm.current!.values.madeClaim) {
            const addAnotherClaim: boolean | undefined = (
              formikForm.current!.values as any
            )[
              `addAnotherClaim_${formikForm.current!.values.claims.length - 1}`
            ];

            if (addAnotherClaim === undefined) {
              setAddAnotherErrMsg('Add another claim is a required field');
              return Promise.resolve(false);
            }
          }

          return Promise.resolve(true);
        },
        nextStepIndex: () => {
          const step2ViewModel: CommercialCombinedStep2ViewModel =
            multiStepContext.data[coverTypeSelectionStepIndex];

          return step2ViewModel.selectedCoverType === 'PC'
            ? operationsStepIndexEnd + 1
            : operationsStepIndexBegin;
        }
      }
    );

  const getClaimFieldName = (
    fieldName: keyof CommercialCombinedStep15ClaimViewModel,
    idx: number
  ) => {
    return `${getFieldName(
      'claims'
    )}[${idx}].${nameOf<CommercialCombinedStep15ClaimViewModel>(fieldName)}`;
  };

  const addClaim = () => {
    const claims = [...formikForm.current!.values.claims];
    claims.push(CommercialCombinedStep15ClaimViewModelInitialValue);
    formikForm.current!.setFieldValue(getFieldName('claims'), claims);
  };

  const removeClaimByIndex = (idxToBeRemoved: number) => {
    const claims = [...formikForm.current!.values.claims];
    claims.splice(idxToBeRemoved, 1);

    formikForm.current!.setFieldValue(
      `addAnotherClaim_${claims.length - 1}`,
      undefined
    );
    formikForm.current!.setFieldValue(getFieldName('claims'), claims);
  };

  const madeClaimChangeHandler = (val: boolean | null | undefined) => {
    if (val) {
      formikForm.current!.setFieldValue(getFieldName('claims'), [
        CommercialCombinedStep15ClaimViewModelInitialValue
      ]);
    } else {
      formikForm.current!.setFieldValue(getFieldName('claims'), []);
    }
  };

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => { }}
      validationSchema={commercialCombinedStep15Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({ values: { claims } }) => (
        <>
          <Heading title="Claim" />
          <FormRadioButtonListBoolean
            name={getFieldName('madeClaim')}
            label-text="Have you or and Director, Business Partner or Financially Associated person(s) made a claim under a Commercial Property and/or Public, Product or Employers Liability Policy within the last 5 years?"
            onChange={madeClaimChangeHandler}
          />
          <FormConditionalCollapse name={getFieldName('madeClaim')}>
            {claims.map((_, claimIdx) => (
              <Fragment key={`claim_${claimIdx}`}>
                <SubHeading title={`Claim ${claimIdx + 1}`} />
                <FormTextBoxDate
                  name={getClaimFieldName('incidentDate', claimIdx)}
                  label-text="Incident date"
                />

                <FormRadioButtonList
                  name={getClaimFieldName('claimCategory', claimIdx)}
                  label-text="Claim Type"
                  options={optionsCC.claimTypes}
                  size={6}
                />
                <FormConditionalCollapse name={getClaimFieldName('claimCategory', claimIdx)} is={1}>
                  <FormRadioButtonList
                    name={getClaimFieldName('claimType', claimIdx)}
                    label-text="Please select"
                    label-subElement
                    options={optionsCC.propertyClaims}
                  />
                </FormConditionalCollapse>
                <FormConditionalCollapse name={getClaimFieldName('claimCategory', claimIdx)} is={2}>
                  <FormRadioButtonList
                    name={getClaimFieldName('claimType', claimIdx)}
                    label-text="Please select"
                    label-subElement
                    options={optionsCC.liabilityClaims}
                  />
                </FormConditionalCollapse>
                <FormTextarea
                  name={getClaimFieldName('claimDetails', claimIdx)}
                  label-text="Please provide full claims details"
                />
                <FormTextBoxCurrency
                  name={getClaimFieldName('claimValue', claimIdx)}
                  label-text="Value of claim"
                />
                <FormTextarea
                  name={getClaimFieldName('futureReoccurrence', claimIdx)}
                  label-text="What has been done to prevent future recurrence?"
                />
                {claimIdx > 0 && claims.length === claimIdx + 1 && (
                  <div className="text-center mb-4">
                    <button
                      className="btn btn-danger"
                      onClick={() => removeClaimByIndex(claimIdx)}
                    >
                      Remove Claim {claimIdx + 1}
                    </button>
                  </div>
                )}
                {claims.length === claimIdx + 1 &&
                  claims.length < maxNumberOfClaims && (
                    <>
                      <FormRadioButtonListBoolean
                        name={`addAnotherClaim_${claimIdx}`}
                        className="mb-0"
                        label-text="Do you want to add another claim?"
                        forceInvalid={!!addAnotherErrMsg}
                        onChange={(val) => {
                          setAddAnotherErrMsg('');
                          if (val) {
                            addClaim();
                          }
                        }}
                      >
                        <FormControlError msg={addAnotherErrMsg} />
                      </FormRadioButtonListBoolean>
                    </>
                  )}
              </Fragment>
            ))}
          </FormConditionalCollapse>
        </>
      )}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep15);
