import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import { FormRadioButtonListBoolean } from 'components/controls';
import PostcodeLookup from 'components/postcode-lookup';
import { Formik } from 'formik';
import { forwardRef, Fragment, useEffect, useMemo, useState } from 'react';
import {
  CommercialCombinedStep5Component,
  commercialCombinedStep5Schema,
  CommercialCombinedStep5ViewModel,
  maxNumberOfLocations
} from './Step5.models';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import nameOf from 'common/functions/NameOfHelper';
import { postcodeLookupViewModelInitialValue } from 'components/postcode-lookup.models';
import { Collapse } from 'react-bootstrap';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import {
  contactDetailsStepIndex,
  coverDetailsItemsStepIndex,
  coverRequiredStepIndex,
  coverTypeSelectionStepIndex,
  locationsStepIndex
} from './index';
import { CommercialCombinedStep6ViewModel } from './Step6.models';
import { CommercialCombinedStep2ViewModel } from './Step2.models';

const CommercialCombinedStep5: CommercialCombinedStep5Component = (
  { initialValue },
  ref
) => {
  const [hideLast, setHideLast] = useState(false);
  const [hideAddSecond, setHideAddSecond] = useState(false);
  const [idxToBeRemoved, setIdxToBeRemoved] = useState(-1);

  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep5ViewModel>(ref, {
      prevStepIndex: () => {
        return step2ViewModel.selectedCoverType === 'PC'
          ? coverTypeSelectionStepIndex
          : step2ViewModel.selectedCoverType === 'PLEL'
          ? contactDetailsStepIndex
          : coverRequiredStepIndex;
      },
      nextStepIndex: () =>
        step2ViewModel.selectedCoverType === 'PLEL'
          ? contactDetailsStepIndex + 1
          : locationsStepIndex + 1
    });

  const Step6AddRemoveIndex = (adding: boolean, index?: number) => {
    const Step6ViewModel: CommercialCombinedStep6ViewModel | null =
      multiStepContext.data[coverDetailsItemsStepIndex];

    if (Step6ViewModel) {
      multiStepContext.setData((currentData) => {
        const newData = [...currentData];

        for (let i = 0; i < Step6ViewModel.coverDetailsItems.length; i++) {
          const currentValues: Array<any> =
            newData[coverDetailsItemsStepIndex].coverDetailsItems[i].values;

          const coverDetailsItem = Step6ViewModel.coverDetailsItems[i];
          if (coverDetailsItem.added) {
            if (adding && formikForm.current) {
              while (
                currentValues.length <
                formikForm.current.values.locations.length
              ) {
                currentValues.push(undefined);
              }
            } else if (!adding && index !== undefined) {
              currentValues.splice(index, 1);
            }
          }
        }
        return newData;
      });
    }
  };

  // useEffect(() => {
  //   setHideAddSecond(formikForm.current!.values.locations.length > 1);
  // }, []);

  useEffect(() => {
    if (hideLast) {
      setHideLast(false);
    }
  }, [hideLast]);

  const addLocation = () => {
    if (!formikForm.current) {
      return;
    }

    setHideAddSecond(true);
    setHideLast(true);
    formikForm.current.setFieldValue(
      nameOf<CommercialCombinedStep5ViewModel>('locations'),
      [
        ...formikForm.current.values.locations,
        postcodeLookupViewModelInitialValue
      ]
    );
    setTimeout(() => {
      Step6AddRemoveIndex(true);
    }, 20);
  };

  const removePendingLocation = () => {
    if (!formikForm.current) {
      return;
    }

    if (idxToBeRemoved >= 0) {
      formikForm.current.values.locations.splice(idxToBeRemoved, 1);
      formikForm.current.setFieldValue(
        nameOf<CommercialCombinedStep5ViewModel>('locations'),
        formikForm.current.values.locations
      );

      // formikForm.current.setFieldValue(
      //   nameOf<CommercialCombinedStep5ViewModel>('operatesAnotherLocation'),
      //   false
      // );

      Step6AddRemoveIndex(false, idxToBeRemoved);

      setIdxToBeRemoved(-1);
    }
  };

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep5Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <>
          <Heading title="Cover Details" />
          {formikProps.values.locations.map((location, idx) => {
            return (
              <Fragment key={idx}>
                <Collapse
                  in={
                    (idx < formikProps.values.locations.length - 1 ||
                      (idx === formikProps.values.locations.length - 1 &&
                        !hideLast)) &&
                    idx !== idxToBeRemoved
                  }
                  onExited={removePendingLocation}
                >
                  <div>
                    <SubHeading
                      title={`Location ${idx + 1}${
                        location.postcode.trim() ? `: ${location.postcode}` : ''
                      }`}
                      extraClassName="mt-4"
                    />
                    <PostcodeLookup name={`locations[${idx}]`} />
                    {idx > 0 &&
                      idx === formikProps.values.locations.length - 1 && (
                        <>
                          <div className="text-center mb-4">
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                if (formikProps.values.locations.length === 2) {
                                  formikProps.setFieldValue(
                                    nameOf<CommercialCombinedStep5ViewModel>(
                                      'operatesAnotherLocation'
                                    ),
                                    undefined
                                  );
                                  setHideAddSecond(false);
                                }
                                setIdxToBeRemoved(idx);
                                formikProps.setFieldValue(
                                  nameOf<CommercialCombinedStep5ViewModel>(
                                    'addAnother'
                                  ),
                                  undefined
                                );
                              }}
                            >
                              Remove Location {idx + 1}
                            </button>
                          </div>
                          {formikProps.values.locations.length <
                            maxNumberOfLocations && (
                            <FormRadioButtonListBoolean
                              name={nameOf<CommercialCombinedStep5ViewModel>(
                                'addAnother'
                              )}
                              label-text="Do you want to add another location?"
                              onChange={(val) => {
                                if (val) {
                                  formikProps.setFieldValue(
                                    nameOf<CommercialCombinedStep5ViewModel>(
                                      'addAnother'
                                    ),
                                    undefined
                                  );
                                  addLocation();
                                }
                              }}
                            />
                          )}
                        </>
                      )}
                  </div>
                </Collapse>
                <Collapse
                  in={
                    !hideAddSecond && formikProps.values.locations.length === 1
                  }
                >
                  <div>
                    {idx === 0 && (
                      <FormRadioButtonListBoolean
                        name={nameOf<CommercialCombinedStep5ViewModel>(
                          'operatesAnotherLocation'
                        )}
                        label-text="Do you operate from more than one location?"
                        onChange={(val) => {
                          if (val) {
                            addLocation();
                          }
                        }}
                      />
                    )}
                  </div>
                </Collapse>
              </Fragment>
            );
          })}
        </>
      )}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep5);
