import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormRadioButtonListBoolean,
  FormTextarea,
  FormTextBoxNumber
} from 'components/controls';
import { Heading } from 'components/Heading';
import { SubHeading } from 'components/SubHeading';
import { Formik } from 'formik';
import { forwardRef } from 'react';
import {
  CommercialCombinedStep21Component,
  CommercialCombinedStep21ItemViewModel,
  commercialCombinedStep21Schema,
  CommercialCombinedStep21ViewModel
} from './Step21.models';
import { usePremisesExtraQuestionsHelper } from './Step5.models';

const CommercialCombinedStep21: CommercialCombinedStep21Component = (
  { initialValue },
  ref
) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep21ViewModel>(ref);

  const { getFieldName, locationHeadingText } = usePremisesExtraQuestionsHelper<
    CommercialCombinedStep21ItemViewModel,
    CommercialCombinedStep21ViewModel
  >();
  if (!locationHeadingText) return null;

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep21Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      <>
        <Heading title="Premises" />
        <SubHeading title={locationHeadingText} />
        <FormTextBoxNumber
          name={getFieldName('buildingOldestPart')}
          label-text="Approximate date the oldest part of the buildings was constructed:"
        />
        <FormRadioButtonListBoolean
          name={getFieldName('detached')}
          label-text="Are the buildings detached from neighbouring properties?"
        />
        <FormConditionalCollapse is={false} name={getFieldName('detached')}>
          <FormTextarea
            name={getFieldName('detachedDetails')}
            label-subElement={true}
            label-text="Please provide details of the adjoining occupants business activities"
          />
        </FormConditionalCollapse>
      </>
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep21);
