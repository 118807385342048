import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import ConfirmationDocsViewModel, {
  mocked_ConfirmationDocsViewModel_Get
} from './confirmationDocsViewModel';
import ConfirmationViewModel, {
  mocked_ConfirmationViewModel_Get
} from './confirmationViewModel';

export function useGetConfirmationAsync(): (
  quoteGuid: string
) => Promise<ConfirmationViewModel | null> {
  const { getAsync } = useAxiosRequestManager();

  return async (quoteGuid) =>
    (
      await getAsync(
        `/confirmation/${quoteGuid}`,
        undefined,
        mocked_ConfirmationViewModel_Get
      )
    ).data!;
}

export function useGetConfirmationDocsAsync(): (
  quoteGuid: string
) => Promise<ConfirmationDocsViewModel | null> {
  const { getAsync } = useAxiosRequestManager();

  return async (quoteGuid) =>
    (
      await getAsync(
        `/confirmation/docs/${quoteGuid}`,
        undefined,
        mocked_ConfirmationDocsViewModel_Get
      )
    ).data!;
}
