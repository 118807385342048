import { DropdownItem } from 'common';
import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormSelect as FormSelectGeneric,
  FormTextBox
} from 'components/controls';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { Formik } from 'formik';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { Collapse } from 'react-bootstrap';
import {
  multiStepExampleStep1Schema,
  MultiStepExampleStep1ViewModel
} from './Step1.models';
const FormSelect = FormSelectGeneric<number>();

const MultiStepExampleStep1: ForwardRefRenderFunction<
  ForwardedRefProps,
  { initialValue: MultiStepExampleStep1ViewModel }
> = ({ initialValue }, ref) => {
  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<MultiStepExampleStep1ViewModel>(ref);

  const yesNo: Array<DropdownItem<number>> = [
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' }
  ];

  /*
  field1: string | null;
  showNext: boolean | null;
  conditionalShowOptional?: string | null;
  conditionalRequired: string | null;
  */
  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={multiStepExampleStep1Schema}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(props) => (
        <>
          <FormTextBox
            name="field1"
            label-text="Field 1"
            placeholder="Enter at least 3 chars"
          />

          <FormSelect
            id="showNext"
            name="showNext"
            label-text="Show next 2 questions"
            validateOnChange={validateOnChange}
            options={yesNo}
          />
          <Collapse in={props.values.showNext === 1}>
            <div>
              <FormTextBox
                name="conditionalShowOptional"
                label-text="Conditional shows only if above true. This is an optional field"
                placeholder="(optional)"
              />
              <FormTextBox
                name="conditionalRequired"
                label-text="Conditional required if 'Show next 2 questions' is 'Yes'"
                placeholder="Enter something"
              />
            </div>
          </Collapse>
        </>
      )}
    </Formik>
  );
};

export default forwardRef(MultiStepExampleStep1);
