import { PropsWithChildren, FC, ReactNode } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField } from 'formik';
import { DropdownItem } from 'common';
import RadioIcons from 'components/ui/controls/radioIcons';

export type FormRadioButtonListGenericProps<T> = PropsWithChildren<
  Omit<FormControlWrapperProps, 'onChange'> &
    FormLabelProps & {
      name: string;
      options: Array<DropdownItem<T | null | undefined>>;
      controlsBtwLabelInput?: () => ReactNode;
      onChange?: (value: T | null | undefined) => void;
      size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      forceInvalid?: boolean;
    }
>;

type FormRadioButtonListGenericComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormRadioButtonListGeneric = <T extends {}>() => {
  const Instance: FormRadioButtonListGenericComponent<
    FormRadioButtonListGenericProps<T>
  > = (props) => {
    const splitProps = separateFormLabelProps(props);
    const {
      name,
      options,
      controlsBtwLabelInput,
      onChange,
      size,
      children,
      className,
      forceInvalid,
      ...otherProps
    } = splitProps[0];
    const [labels, others] = filterChildren(children, 'FormLabel');

    const [{ value, ...fieldProps }, { error }, { setValue }] = useField<
      T | null | undefined
    >(name);

    const selectedIndex = options.findIndex((o) => o.value === value);

    const onChangeHandler = (index: number) => {
      const isIndexValid = index >= 0 && index < options.length;

      setValue(isIndexValid ? options[index].value : undefined);
      if (onChange) {
        onChange(isIndexValid ? options[index].value : undefined);
      }
    };

    return (
      <FormControlWrapper
        className={`errorParentWrapper mb-4 form-group ${
          className ? className : ''
        }`.trim()}
        {...otherProps}
      >
        {labels.length ? labels : <FormLabel {...splitProps[1]} />}
        {controlsBtwLabelInput && controlsBtwLabelInput()}
        <div
          className={`${'customRadioWrapper row gy-3'} ${
            !!error || forceInvalid ? 'is-invalid' : ''
          }`.trim()}
        >
          {options.map((opt, idx) => {
            return (
              <div
                key={`i${opt.value}`}
                className={`customRadioWrapperItem ${`col-${size || 12}`}`}
              >
                <input
                  id={`opt_${name}_${opt.value}`}
                  name={fieldProps.name}
                  type="radio"
                  value={idx}
                  checked={idx === selectedIndex}
                  className={`btn-check ${
                    !!error || forceInvalid ? 'is-invalid' : ''
                  }`.trim()}
                  onChange={() => onChangeHandler(idx)}
                />
                <label
                  htmlFor={`opt_${name}_${opt.value}`}
                  className="btn w-100 d-flex justify-content-center align-items-center"
                >
                  <RadioIcons icon={opt.selectedIcon} />
                  <span>{opt.text}</span>
                </label>
              </div>
            );
          })}
        </div>
        {others}
        <FormControlError msg={error} />
      </FormControlWrapper>
    );
  };
  Instance.Label = FormLabel;

  Instance.displayName = 'FormRadioButtonListGeneric';
  return Instance;
};

export default FormRadioButtonListGeneric;
