import { FC, useEffect, useState } from 'react';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { MultiStepExampleStep1ViewModel } from './Step1.models';

const MultiStepExampleStep2: FC = () => {
  const multiStepContext = useFormMultiStepGroupContext();
  const [step1, setStep1] = useState<MultiStepExampleStep1ViewModel | null>(
    null
  );

  useEffect(() => {
    setStep1(multiStepContext.data[0]);
  }, [multiStepContext]);

  return (
    <>
      <h4 className="mb-4">
        Example that we can access info from another steps here
      </h4>
      <dl className="mb-4">
        <dt className="bg-dark text-light px-2">Field 1:</dt>
        <dd className="px-2">{step1?.field1}</dd>
        <dt className="bg-dark text-light px-2">Field 2:</dt>
        <dd className="px-2">{step1?.showNext === 1 ? 'Yes' : 'No'}</dd>
        {step1?.showNext === 1 && (
          <>
            <dt className="bg-secondary text-light px-2">Field 3:</dt>
            <dd className="px-2">{step1?.conditionalShowOptional}</dd>
            <dt className="bg-secondary text-light px-2">Field 4:</dt>
            <dd className="px-2">{step1?.conditionalRequired}</dd>
          </>
        )}
      </dl>
    </>
  );
};

export default MultiStepExampleStep2;
