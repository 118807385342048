import useAxiosRequestManager from 'common/axios-request-manager/AxiosRequestManager';
import { useCallback } from 'react';
import {
  BackOfficeLoggedInViewModel,
  mocked_BackOfficeLoggedInViewModel_Get
} from './backOfficeLoggedInViewModel';

export function useGetLoggedUserAsync(): () => Promise<BackOfficeLoggedInViewModel | null> {
  const { getAsync } = useAxiosRequestManager();

  return useCallback(async () => {
    try {
      const response = await getAsync(
        '/back-office/logged-user',
        undefined,
        mocked_BackOfficeLoggedInViewModel_Get,
        undefined,
        false
      );

      return response.data!;
    } catch (err) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
