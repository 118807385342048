import { requiredIf } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep16Props {
  initialValue: CommercialCombinedStep16ViewModel;
}

export type CommercialCombinedStep16Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep16Props
>;

export interface CommercialCombinedStep16ViewModel {
  materialsAndServices?: boolean | null;
  materialsAndServicesDetails?: string | null;
  importProductOutside?: boolean | null;
  importProductOutsideDetails?: string | null;
}

export const commercialCombinedStep16Schema: SchemaOf<CommercialCombinedStep16ViewModel> =
  object({
    materialsAndServices: boolean().nullable().required(),
    materialsAndServicesDetails: requiredIf(
      string().nullable().label('It'),
      'materialsAndServices',
      (materialsAndServices: boolean) => !materialsAndServices
    ),
    importProductOutside: boolean().nullable().required(),
    importProductOutsideDetails: requiredIf(
      string().nullable().label('It'),
      'importProductOutside'
    )
  });

export const CommercialCombinedStep16ViewModelInitialValue: CommercialCombinedStep16ViewModel =
  {
    materialsAndServices: null,
    importProductOutside: null
  };
export const CommercialCombinedStep16ViewModelInitialValueDev: CommercialCombinedStep16ViewModel =
  {
    materialsAndServices: true,
    materialsAndServicesDetails: '',
    importProductOutside: false,
    importProductOutsideDetails: ''
  };

export function useCommercialCombinedStep16InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep16ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];
    
    return {
      ...(devForcePp
        ? CommercialCombinedStep16ViewModelInitialValueDev
        : CommercialCombinedStep16ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
