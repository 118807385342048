import { PropsWithChildren, FC } from 'react';
import filterChildren from 'common/functions/ReactChildrenFilter';
import FormLabel, {
  FormLabelProps,
  separateFormLabelProps
} from './form-label';
import FormControlWrapper, {
  FormControlWrapperProps
} from './form-control-wrapper';
import FormControlError from './form-control-error';
import { useField } from 'formik';

type FormTextBoxProps = PropsWithChildren<
  FormControlWrapperProps &
    FormLabelProps & {
      id?: string;
      name: string;
      maxLength?: number;
      inputOtherWrapperClassName?: string;
      placeholder?: string;
      disabled?: boolean;
    }
>;

type FormTextBoxComponent<P> = FC<P> & {
  Label: typeof FormLabel;
};

const FormTextBox: FormTextBoxComponent<FormTextBoxProps> = (props) => {
  const splitProps = separateFormLabelProps(props);
  const {
    id,
    name,
    maxLength,
    inputOtherWrapperClassName,
    placeholder,
    disabled,
    children,
    ...otherProps
  } = splitProps[0];
  const [labels, others] = filterChildren(children, 'FormLabel');
  const [{ value, ...field }, meta] = useField(props);

  const placeholderFix = () => {
    if (!placeholder || !placeholder.trim()) {
      return undefined;
    }

    return placeholder.trim();
  };

  const customInput = (
    <input
      id={id}
      type="text"
      placeholder={disabled ? undefined : placeholderFix()}
      className={`form-control ${meta?.error ? 'is-invalid' : ''}`}
      disabled={disabled}
      value={value || ''}
      maxLength={maxLength}
      {...field}
    />
  );

  return (
    <FormControlWrapper {...otherProps}>
      {labels.length ? labels : <FormLabel htmlFor={name} {...splitProps[1]} />}
      {inputOtherWrapperClassName ? (
        <div
          className={`${inputOtherWrapperClassName} ${
            meta?.error ? 'is-invalid' : ''
          }`}
        >
          {customInput}
          {others}
        </div>
      ) : (
        <>
          {customInput}
          {others}
        </>
      )}
      <FormControlError msg={meta?.error} />
    </FormControlWrapper>
  );
};
FormTextBox.Label = FormLabel;

FormTextBox.displayName = 'FormTextBox';
export default FormTextBox;
