import { useImperativeHandleForMultiStepItem } from 'components/controls/form/form-multi-step-item';
import {
  FormConditionalCollapse,
  FormTextBox,
  FormTextBoxCurrency,
  FormRadioButtonListBoolean,
  FormRadioButtonListGeneric,
  FormTextBoxDate,
  FormCheckBox,
  FormLabel,
  FormControlError,
  FormSelect as FormSelectGeneric
} from 'components/controls';
import {
  CcAdditionalCoverCollapse,
  Heading,
  InfoCard,
  SubHeading
} from 'components';
import { Formik } from 'formik';
import {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useSyncExternalStore
} from 'react';
import {
  CommercialCombinedStep7Component,
  commercialCombinedStep7Schema,
  CommercialCombinedStep7ViewModel
} from './Step7.models';

import { businessTypes_IsCompany, optionsCC } from 'config/options';
import FormTextarea from 'components/controls/form/form-textarea';
import PostcodeLookup from 'components/postcode-lookup';
import nameOf from 'common/functions/NameOfHelper';
import {
  contactDetailsStepIndex,
  coverRequiredStepIndex,
  coverTypeSelectionStepIndex,
  locationsStepIndex
} from './index';
import { useFormMultiStepGroupContext } from 'components/controls/form/form-multi-step-group.hooks';
import { CommercialCombinedStep2ViewModel } from './Step2.models';
import { CommercialCombinedStep5ViewModel } from './Step5.models';
const FormSelect = FormSelectGeneric<number>();
const FormRadioButtonList = FormRadioButtonListGeneric<number>();

export const businessInterruptionCoverTitle = 'Business Interruption cover';
export const detoriationOfStockCoverTitle = 'Detoriation Of Stock cover';

const CommercialCombinedStep7: CommercialCombinedStep7Component = (
  { initialValue },
  ref
) => {
  const multiStepContext = useFormMultiStepGroupContext();
  const step2ViewModel = useMemo<CommercialCombinedStep2ViewModel>(
    () => multiStepContext.data[coverTypeSelectionStepIndex],
    [multiStepContext.data]
  );

  const Step5ViewModel = useMemo<CommercialCombinedStep5ViewModel | null>(
    () => multiStepContext.data[locationsStepIndex],
    [multiStepContext.data]
  );

  const { formikForm, validateOnChange } =
    useImperativeHandleForMultiStepItem<CommercialCombinedStep7ViewModel>(ref, {
      prevStepIndex: () =>
        step2ViewModel.selectedCoverType === 'PLEL'
          ? coverRequiredStepIndex
          : contactDetailsStepIndex - 1,
      nextStepIndex: () =>
        step2ViewModel.selectedCoverType === 'PLEL'
          ? locationsStepIndex
          : contactDetailsStepIndex + 1
    });

  const getFieldName = nameOf<CommercialCombinedStep7ViewModel>;

  const numLocations = Step5ViewModel?.locations.length || 0;

  useEffect(() => {
    if (
      formikForm.current &&
      formikForm.current.values.businessInterruptionSum == null
    ) {
      formikForm.current.setFieldValue(
        getFieldName('businessInterruptionSum'),
        numLocations ? new Array(numLocations).fill(null) : null
      );
    }
  }, [formikForm, getFieldName, numLocations]);

  const quoteResult = useSyncExternalStore(
    multiStepContext.quotePubSub!.subscribeQuote,
    multiStepContext.quotePubSub!.getQuote
  );

  return (
    <Formik
      innerRef={formikForm}
      initialValues={initialValue}
      onSubmit={() => {}}
      validationSchema={commercialCombinedStep7Schema(multiStepContext)}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {({
        values: { businessTypeId, goodsInTransitTypes, specifiedAllRisksTypes },
        getFieldMeta
      }) => {
        const biSumInsuredError = getFieldMeta(
          getFieldName('businessInterruptionCoverAdded')
        )?.error;
        const detoriationOfStockInsuredError = getFieldMeta(
          getFieldName('detoriationOfStockCoverAdded')
        )?.error;

        const tradingAsLabel =
          businessTypeId != null &&
          businessTypes_IsCompany.includes(businessTypeId)
            ? 'Trading Name'
            : 'Trading Name (if different)';
        return (
          <>
            <Heading title="Your cover" />
            <InfoCard
              refer={quoteResult.refer}
              price={quoteResult.quotePrice || 0}
            />
            {step2ViewModel?.selectedCoverType !== 'PLEL' && (
              <>
                <Heading title="Additional cover" />
                <SubHeading title="Select all that you would like to add" />
                <div className="mb-4">
                  <CcAdditionalCoverCollapse
                    title={businessInterruptionCoverTitle}
                    formikName={getFieldName('businessInterruptionCoverAdded')}
                  >
                    {Step5ViewModel &&
                      Step5ViewModel.locations.map((location, lIdx) => (
                        <Fragment key={`Step7_location_${lIdx}`}>
                          <SubHeading
                            title={`Location ${lIdx + 1}${
                              location.postcode.trim()
                                ? `: ${location.postcode}`
                                : ''
                            }`}
                          />
                          <FormTextBoxCurrency
                            name={`${getFieldName(
                              'businessInterruptionSum'
                            )}[${lIdx}]`}
                            label-text="Sum Insured"
                            forceInvalid={!!biSumInsuredError}
                          />
                        </Fragment>
                      ))}
                    <div className="errorParentWrapper">
                      <FormControlError mb={4} mt={0} msg={biSumInsuredError} />
                    </div>
                    <FormRadioButtonList
                      name={getFieldName(
                        'businessInterruptionIndemnityPeriodId'
                      )}
                      label-text="Indemnity period"
                      options={optionsCC.indemnityPeriod}
                    />
                    <FormRadioButtonList
                      name={getFieldName('businessInterruptionBasisIfCoverId')}
                      label-text="Basis of Cover"
                      options={optionsCC.basisIfCover}
                    />
                  </CcAdditionalCoverCollapse>
                  <CcAdditionalCoverCollapse
                    title="Money cover"
                    formikName={getFieldName('moneyCoverAdded')}
                  >
                    <FormTextBoxCurrency
                      name={getFieldName('moneyAnnualCarrying')}
                      label-text="Estimated Annual Carrying of money"
                    />
                    <FormTextBoxCurrency
                      name={getFieldName('moneyInTransitToFromBank')}
                      label-text="Money in transit to and from the bank"
                    />
                    <FormTextBoxCurrency
                      name={getFieldName('moneyAtAllPremisesBusinessHour')}
                      label-text="At premises in business hours"
                    />
                    <FormTextBoxCurrency
                      name={getFieldName(
                        'moneyAtAllPremisesOutBusinessHourAndNotInSafe'
                      )}
                      label-text="At premises out of business hours and not in a safe"
                    />
                    <FormTextBoxCurrency
                      name={getFieldName(
                        'moneyAtAllPremisesOutBusinessHourAndInSafe'
                      )}
                      label-text="At premises in a locked safe out of business hours"
                    />
                  </CcAdditionalCoverCollapse>
                  <CcAdditionalCoverCollapse
                    title="Goods in transit cover"
                    formikName={getFieldName('goodsInTransitCoverAdded')}
                  >
                    <FormLabel text="What are goods in transit? (Vehicle insured is responsible for:)" />
                    {goodsInTransitTypes.map((item, itemIdx) => {
                      const fieldName_Selected = `${getFieldName(
                        'goodsInTransitTypes'
                      )}[${itemIdx}].selected`;
                      return (
                        <Fragment key={`goodsInTransit_${item.id}`}>
                          <FormCheckBox
                            name={fieldName_Selected}
                            label-text={item.text}
                          />
                          <FormConditionalCollapse name={fieldName_Selected}>
                            <FormTextBoxCurrency
                              name={`${getFieldName(
                                'goodsInTransitTypes'
                              )}[${itemIdx}].sumInsured`}
                              label-text="Sum insured"
                              blockDecimals
                            />
                          </FormConditionalCollapse>
                        </Fragment>
                      );
                    })}
                    {/* <FormCheckBoxList
                  name={getFieldName('goodsInTransitTypeId')}
                  label-text="What are goods in transit? (Vehicle insured is responsible for:)"
                  options={optionsCC.goodsInTransit}
                  validateOnChange={validateOnChange}
                /> */}
                    <FormTextBoxCurrency
                      name={getFieldName('goodsInTransitAnnualValue')}
                      label-text="What is the estimated annual value of goods in transit?"
                    />
                    <FormTextBoxCurrency
                      name={getFieldName('goodsInTransitMaxValueSingleTransit')}
                      label-text="What is the maximum value in any one transit?"
                    />
                  </CcAdditionalCoverCollapse>
                  <CcAdditionalCoverCollapse
                    title="Loss of licence cover"
                    formikName={getFieldName('lossOfLicenceCoverAdded')}
                  >
                    <FormTextBox
                      name={getFieldName('lossOfLicenceType')}
                      label-text="Type of Licence"
                    />
                    <FormTextBoxCurrency
                      name={getFieldName('lossOfLicenceSumInsured')}
                      label-text="Sum insured"
                      blockDecimals
                    />
                    <FormTextBoxDate
                      name={getFieldName('lossOfLicenceDateOfIssue')}
                      label-text="Date of issue"
                    />
                    <FormTextBox
                      name={getFieldName('lossOfLicenceIssuingAuthority')}
                      label-text="Issuing Authority"
                    />
                    <FormRadioButtonListBoolean
                      name={getFieldName(
                        'lossOfLicencePreviousWarningsOrNotices'
                      )}
                      label-text="Have you ever had any warning or notices on your licence?"
                    />
                    <FormConditionalCollapse
                      name={getFieldName(
                        'lossOfLicencePreviousWarningsOrNotices'
                      )}
                    >
                      <FormTextBox
                        name={getFieldName(
                          'lossOfLicencePreviousWarningsOrNoticesDetails'
                        )}
                        label-text="Please provide details"
                        label-subElement={true}
                      />
                    </FormConditionalCollapse>
                    <FormRadioButtonListBoolean
                      name={getFieldName(
                        'lossOfLicencePreviousPendingConvictions'
                      )}
                      label-text="Have you ever had any past/pending proceedings/convictions for any breach of licensing law?"
                    />
                    <FormConditionalCollapse
                      name={getFieldName(
                        'lossOfLicencePreviousPendingConvictions'
                      )}
                    >
                      <FormTextBox
                        name={getFieldName(
                          'lossOfLicencePreviousPendingConvictionsDetails'
                        )}
                        label-text="Please provide details"
                        label-subElement={true}
                      />
                    </FormConditionalCollapse>
                  </CcAdditionalCoverCollapse>
                  <CcAdditionalCoverCollapse
                    title="Personal accident cover"
                    formikName={getFieldName('personalAccidentCoverAdded')}
                  >
                    <FormTextBox
                      name={getFieldName('personalAccidentUnitsRequired')}
                      label-text="How many units required?"
                    />
                    <FormRadioButtonListBoolean
                      name={getFieldName('personalAccidentAnyInsuredOver70')}
                      label-text="Are any persons to be insured over 70 years of age?"
                    />
                    <FormTextarea
                      name={getFieldName(
                        'personalAccidentAnyInsuredPreExistingConditions'
                      )}
                      label-text="Please describe any known pre-existing conditions for any of the persons to be insured"
                    />
                    <FormRadioButtonListBoolean
                      name={getFieldName(
                        'personalAccidentAnyInsuredApplicationDeclinedOrImposed'
                      )}
                      label-text="Any application for personal accident ever been declined or special terms imposed?"
                    />
                    <FormConditionalCollapse
                      name={getFieldName(
                        'personalAccidentAnyInsuredApplicationDeclinedOrImposed'
                      )}
                    >
                      <FormTextBox
                        name={getFieldName(
                          'personalAccidentAnyInsuredApplicationDeclinedOrImposedDetails'
                        )}
                        label-text="Please provide details"
                        label-subElement={true}
                      />
                    </FormConditionalCollapse>
                  </CcAdditionalCoverCollapse>
                  <CcAdditionalCoverCollapse
                    title={detoriationOfStockCoverTitle}
                    formikName={getFieldName('detoriationOfStockCoverAdded')}
                  >
                    {Step5ViewModel &&
                      Step5ViewModel.locations.map((location, lIdx) => (
                        <Fragment key={`Step7_location_${lIdx}`}>
                          <SubHeading
                            title={`Location ${lIdx + 1}${
                              location.postcode.trim()
                                ? `: ${location.postcode}`
                                : ''
                            }`}
                          />
                          <FormTextBoxCurrency
                            name={`${getFieldName(
                              'detoriationOfStockSum'
                            )}[${lIdx}]`}
                            label-text="Sum Insured"
                            forceInvalid={!!detoriationOfStockInsuredError}
                          />
                        </Fragment>
                      ))}
                    <div className="errorParentWrapper">
                      <FormControlError
                        mb={4}
                        mt={0}
                        msg={detoriationOfStockInsuredError}
                      />
                    </div>
                  </CcAdditionalCoverCollapse>
                  <CcAdditionalCoverCollapse
                    title="Specified All Risks"
                    formikName={getFieldName('specifiedAllRisksCoverAdded')}
                  >
                    <FormLabel text="What are specified risks?" />
                    {specifiedAllRisksTypes.map((item, itemIdx) => {
                      const fieldName_Selected = `${getFieldName(
                        'specifiedAllRisksTypes'
                      )}[${itemIdx}].selected`;
                      return (
                        <Fragment key={`specifiedRisk_${item.id}`}>
                          <FormCheckBox
                            name={fieldName_Selected}
                            label-text={item.text}
                          />
                          <FormConditionalCollapse name={fieldName_Selected}>
                            <FormTextBoxCurrency
                              name={`${getFieldName(
                                'specifiedAllRisksTypes'
                              )}[${itemIdx}].sumInsuredUK`}
                              label-text="Sum insured UK"
                              blockDecimals
                            />
                            <FormTextBoxCurrency
                              name={`${getFieldName(
                                'specifiedAllRisksTypes'
                              )}[${itemIdx}].sumInsuredEU`}
                              label-text="Sum insured EU"
                              blockDecimals
                            />
                            <FormTextBoxCurrency
                              name={`${getFieldName(
                                'specifiedAllRisksTypes'
                              )}[${itemIdx}].sumInsuredWW`}
                              label-text="Sum insured world wide"
                              blockDecimals
                            />
                          </FormConditionalCollapse>
                        </Fragment>
                      );
                    })}
                    {}
                    <FormTextBoxCurrency
                      name={getFieldName('specifiedAllRisksMaxValueSingle')}
                      label-text="What is the maximum value of any one risk item?"
                    />
                  </CcAdditionalCoverCollapse>
                </div>
              </>
            )}
            <Heading title="Contact details" />
            <FormSelect
              id={getFieldName('businessTypeId')}
              name={getFieldName('businessTypeId')}
              label-text="Business type"
              validateOnChange={validateOnChange}
              options={optionsCC.businessTypes}
            />
            <FormSelect
              id={getFieldName('policyHolderTitleId')}
              name={getFieldName('policyHolderTitleId')}
              label-text="Policyholder title"
              validateOnChange={validateOnChange}
              options={optionsCC.titles}
            />
            <FormTextBox
              name={getFieldName('policyHolderFirstName')}
              label-text="Policyholder first name"
            />
            <FormTextBox
              name={getFieldName('policyHolderSurname')}
              label-text="Policyholder surname"             
            />

            <FormTextBox
              name={getFieldName('tradingAs')}
              label-text={tradingAsLabel}
            />
            <PostcodeLookup
              name={nameOf<CommercialCombinedStep7ViewModel>('contactAddress')}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CommercialCombinedStep7);
