import { useState, PropsWithChildren, useEffect, useRef, useMemo } from 'react';
import AxiosRequestManagerContext from './AxiosRequestManagerContext';
import { useLocation } from 'react-router-dom';
import { recalculateTargets } from 'common/functions/ScrollToFirstError';
// import AxiosRequestManagerContextProps from './AxiosRequestManagerContextProps';

function errorHandlingAsync(error: any): string[] {
  if (isNetworkError(error)) {
    alert('Please contact system administrator.');
    // this.routerHelper.goToBackofficeLogin();
    return [];
  }

  if (error && error.response) {
    if (typeof error.response.status === 'number') {
      switch (error.response.status) {
        case 400:
          return resolveHttp400(error, error.response.data);

        // case 401:
        // case 440:
        //   await this.authModule.logOut(true);
        //   if (
        //     error &&
        //     error.config &&
        //     error.config.url &&
        //     error.config.url.startsWith('/backoffice')
        //   ) {
        //     this.routerHelper.goToBackofficeLogin();
        //   } else {
        //     this.routerHelper.goToLogin();
        //   }
        //   break;

        case 403:
          if (error.response.statusText) {
            alert(error.response.statusText);
          } else {
            alert('Attempted to perform an unauthorized operation.');
          }
          // await this.authModule.logOut(true);
          break;

        case 404:
        case 500:
        default:
          alert(
            'An unexpected error occurred, please contact the system administrator.'
          );
          // if (this.authModule.isAuthenticated) {
          //   this.routerHelper.goToDashboard();
          // } else {
          //   this.routerHelper.goToLogin();
          // }
          break;
      }
    }
  }

  return [];
}

function isNetworkError(error: any) {
  return error && error.message === 'Network Error';
}

function resolveHttp400(error: any, data: any) {
  const errMessages = getErrorResponseDataMessages(data);
  if (errMessages !== null) {
    return errMessages;
  }
  // await this.authModule.logOut(true);
  alert('Please contact system administrator.');
  // if (
  //   error &&
  //   error.config &&
  //   error.config.url &&
  //   error.config.url.startsWith('/backoffice')
  // ) {
  //   this.routerHelper.goToBackofficeLogin();
  // } else {
  //   this.routerHelper.goToLogin();
  // }
  return [];
}

function getErrorResponseDataMessages(data: any): string[] | null {
  if (!data) {
    return null;
  }
  if (data.constructor === Array) {
    return data;
  }
  if (typeof data === 'string') {
    return [data];
  }

  if (Object.keys(data).length !== 0 && data.constructor === Object) {
    let errors: string[] = [];
    for (const property in data) {
      if (Object.prototype.hasOwnProperty.call(data, property)) {
        const propValue = data[property];
        if (propValue !== null) {
          if (propValue.constructor === Array) {
            errors = errors.concat(propValue);
          } else {
            errors.push(propValue);
          }
        }
      }
    }
    return errors;
  }

  return null;
}

export default function AxiosRequestManagerProvider({
  children,
  defaultDelay
}: PropsWithChildren<{ defaultDelay: number }>): JSX.Element {
  const [errors, setErrors] = useState<string[]>([]);
  const requestCount = useRef<number>(0);

  const location = useLocation();
  useEffect(() => {
    requestCount.current = 0;
    setErrors([]);
  }, [location?.key]);

  function incRequest() {
    requestCount.current = requestCount.current + 1;
  }

  function decRequest() {
    requestCount.current = Math.max(0, requestCount.current - 1);
  }

  function scrollToServerSideError(
    container: any = undefined,
    offset = -10,
    setTimeoutInterval = 10
  ) {
    setTimeout(() => {
      const elSsErrorList = document.getElementsByClassName(
        'serverSideErrorList'
      );

      if (elSsErrorList && elSsErrorList.length > 0) {
        window.scrollTo({
          top: recalculateTargets(container!, elSsErrorList[0], offset!),
          behavior: 'smooth'
        });
      }
    }, setTimeoutInterval!);
  }

  const value = useMemo(
    () => ({
      defaultDelay,
      errors,
      setErrors: (newErrors: any) => {
        // if (requestCount.current <= 0) {
        //   return;
        // }

        decRequest();
        setErrors(errorHandlingAsync(newErrors));
        scrollToServerSideError();
      },
      resetState: () => {
        setErrors([]);
      }
    }),
    [errors, defaultDelay]
  );

  return (
    <AxiosRequestManagerContext.Provider
      value={{ ...value, incRequest, decRequest }}
    >
      {children}
    </AxiosRequestManagerContext.Provider>
  );
}
