import { number, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedExcessViewModel {
  name: string;
  value: number;
}

export const excessSchema: SchemaOf<CommercialCombinedExcessViewModel> = object(
  {
    name: string().default('').required(),
    value: number().default(0).required()
  }
);
