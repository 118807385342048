import { useAuthBroker } from 'common/auth/useAuthBroker';
import { useMemo } from 'react';
import { brokerBaseURL } from 'config';

export const BrokerRibbon = () => {
  const email = useMemo(useAuthBroker, []);

  return (
    <div className="instantQuoteBrokerInfo">
      <div>
        <span>
          Your are on a <strong>broker portal quote process</strong> and
          currently logged in as {email}
        </span>
        <a href={`${brokerBaseURL}/broker/dashboard/`}>Return to Dashboard</a>
      </div>
    </div>
  );
};
