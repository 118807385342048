import { useParams } from 'react-router-dom';
import { Heading } from 'components';
import ReferralViewModel from 'features/commercialCombined/referralViewModel';
import { useGetReferralAsync } from 'features/commercialCombined/referral';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const CommercialCombinedReferIndex = () => {
  const { guid } = useParams();
  const getReferralAsync = useGetReferralAsync();
  const [referralDetails, setReferralDetails] =
    useState<ReferralViewModel | null>(null);

  useEffect(() => {
    async function loadAsync() {
      setReferralDetails(await getReferralAsync(guid || ''));
    }
    loadAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <div className="alert alert-danger mx-4 text-center">
        <h4>DEV NOTE</h4>
        <div>
          To enable fetch please remove attribute{' '}
          <pre className="m-0 d-inline">&#123; forceMocked: true &#125;</pre> on
          <br />
          file './src/features/commercialCombined/referral.ts: 9'
        </div>
      </div> */}
      <div className="container container-body-sm">
        <Heading title="Policy has referred" />
        {referralDetails === null && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
        {referralDetails && (
          <>
            <p>
              Your policy has referred for our underwriters to review before we
              provide a quotation. We aim to come back to you within 1 working
              day.
            </p>
            <p>
              We will reply to your email with an update when this is ready.
            </p>
            <p>Reference: {referralDetails.quoteRef}</p>
            <div className="text-center">
              <button className="btn btn-primary">Return to website</button>
              {/* <button className="btn btn-primary">Return to Broker Portal Dashboard</button> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};
