import { FC } from 'react';
import { IconTypes } from 'common';
import { Done, Close } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';

const RadioIcons: FC<{
  icon?: IconTypes;
  className?: string;
  sx?: SxProps<Theme>;
}> = ({ icon, className, sx }) => {
  if (!icon) {
    return null;
  }

  const classNameDefault = 'text-secondary me-2';
  const sxDefault = { fontSize: 20 };

  switch (icon) {
    case 'check':
      return (
        <Done
          className={className === undefined ? classNameDefault : className}
          sx={sx || sxDefault}
        />
      );
    case 'cross':
      return (
        <Close
          className={className === undefined ? classNameDefault : className}
          sx={sx || sxDefault}
        />
      );
  }
};

export default RadioIcons;
