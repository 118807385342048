export interface CommercialCombinedBackOfficeSubmitResponse {
  id: number;
  guid?: never;
  referred?: never;
}

export interface CommercialCombinedPortalSubmitResponse {
  id?: never;
  guid: string;
  referred: boolean;
}

export type CommercialCombinedSubmitResponse =
  | CommercialCombinedBackOfficeSubmitResponse
  | CommercialCombinedPortalSubmitResponse;

export const mocked_CommercialCombinedPortalSubmitResponse_Submit: CommercialCombinedSubmitResponse =
  {
    guid: 'ABC123',
    referred: false
  };

export const mocked_CommercialCombinedBackOfficeSubmitResponse_Submit: CommercialCombinedSubmitResponse =
  {
    id: 1
  };
