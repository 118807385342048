import { number, object, SchemaOf, string } from 'yup';

interface MultiStepExampleStep1ViewModel {
  field1: string;
  showNext: number | undefined;
  conditionalShowOptional: string | undefined;
  conditionalRequired: string | undefined;
}

export type { MultiStepExampleStep1ViewModel };

const multiStepExampleStep1ViewModelInitialValue: MultiStepExampleStep1ViewModel =
  {
    field1: '',
    showNext: undefined,
    conditionalShowOptional: '',
    conditionalRequired: ''
  };

const multiStepExampleStep1Schema: SchemaOf<MultiStepExampleStep1ViewModel> =
  object().shape({
    field1: string().required().min(3).label('Field 1'),
    showNext: number().required().label('Show next 2 questions'),
    conditionalShowOptional: string().optional(),
    conditionalRequired: string()
      .label('Conditional required')
      .when('showNext', {
        is: 1,
        then: string().nullable().required(),
        otherwise: string().nullable().optional()
      })
  });

export {
  multiStepExampleStep1ViewModelInitialValue,
  multiStepExampleStep1Schema
};
