import nameOf from 'common/functions/NameOfHelper';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import moment from 'moment';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, date, number, object, SchemaOf, string } from 'yup';

export interface CommercialCombinedStep15Props {
  initialValue: CommercialCombinedStep15ViewModel;
}

export type CommercialCombinedStep15Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep15Props
>;

export interface CommercialCombinedStep15ClaimViewModel {
  incidentDate?: Date | null;
  claimDetails?: string | null;
  claimValue?: number | null;
  futureReoccurrence?: string | null;
  claimCategory?: number | null;
  claimType?: number | null;
}

export interface CommercialCombinedStep15ViewModel {
  madeClaim: boolean;
  claims: Array<CommercialCombinedStep15ClaimViewModel>;
}

export const commercialCombinedStep15ClaimSchema: SchemaOf<CommercialCombinedStep15ClaimViewModel> =
  object({
    incidentDate: date().nullable().required().label('Incident date'),
    claimDetails: string().nullable().required().label('Claims details'),
    claimValue: number()
      .nullable()
      .nullable()
      .required()
      .label('Value of claim'),
    futureReoccurrence: string().nullable(),
    claimCategory: number().nullable().required().label('Claim type'),
    claimType: number().nullable().required().label('Please select')
  });

export const commercialCombinedStep15Schema: SchemaOf<CommercialCombinedStep15ViewModel> =
  object({
    madeClaim: boolean().nullable().required(),
    claims: array()
      .of(commercialCombinedStep15ClaimSchema)
      .when(nameOf<CommercialCombinedStep15ViewModel>('madeClaim'), {
        is: true,
        then: (schema) => schema.min(1, 'You must at least add one claim')
      })
  });

export const CommercialCombinedStep15ClaimViewModelInitialValue: CommercialCombinedStep15ClaimViewModel =
  {};
export const CommercialCombinedStep15ClaimViewModelInitialValueDev1: CommercialCombinedStep15ClaimViewModel =
  {
    incidentDate: moment().subtract(2, 'day').startOf('day').toDate(),
    claimType: 1,
    claimCategory: 2,
    claimDetails: '1411',
    claimValue: 1412,
    futureReoccurrence: '1413'
  };
export const CommercialCombinedStep15ClaimViewModelInitialValueDev2: CommercialCombinedStep15ClaimViewModel =
  {
    incidentDate: moment().startOf('day').toDate(),
    claimType: 2,
    claimCategory: 8,
    claimDetails: '1421',
    claimValue: 1422,
    futureReoccurrence: '1423'
  };

export const CommercialCombinedStep15ViewModelInitialValue: CommercialCombinedStep15ViewModel =
  { madeClaim: false, claims: [] };
export const CommercialCombinedStep15ViewModelInitialValueDev: CommercialCombinedStep15ViewModel & {
  [prop: `addAnotherClaim_${number}`]: boolean | null | undefined;
} = {
  madeClaim: false,
  claims: []
};

export function useCommercialCombinedStep15InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep15ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep15ViewModelInitialValueDev
        : CommercialCombinedStep15ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
