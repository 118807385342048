import { requiredIfTyped } from 'common/functions/Yup';
import { ForwardedRefProps } from 'components/controls/form/form-multi-step-item';
import { devForcePp } from 'config';
import { ForwardRefRenderFunction, useMemo } from 'react';
import { array, boolean, object, SchemaOf } from 'yup';

const requiredIf = requiredIfTyped<CommercialCombinedStep23ItemViewModel>();

export interface CommercialCombinedStep23Props {
  initialValue: CommercialCombinedStep23ViewModel;
}

export type CommercialCombinedStep23Component = ForwardRefRenderFunction<
  ForwardedRefProps,
  CommercialCombinedStep23Props
>;

export interface CommercialCombinedStep23ViewModel {
  locations: Array<CommercialCombinedStep23ItemViewModel>;
}

export interface CommercialCombinedStep23ItemViewModel {
  isLetAsResidential?: boolean | null;
  isLetAsResidentialGasSafety?: boolean | null;
  ensureGasCertificate?: boolean | null;
}

const commercialCombinedStep23ItemSchema: SchemaOf<CommercialCombinedStep23ItemViewModel> =
  object({
    isLetAsResidential: boolean().nullable().required(),
    isLetAsResidentialGasSafety: requiredIf(
      boolean().nullable(),
      'isLetAsResidential'
    ),
    ensureGasCertificate: boolean().nullable().required()
  });

export const commercialCombinedStep23Schema: SchemaOf<CommercialCombinedStep23ViewModel> =
  object({
    locations: array()
      .of(commercialCombinedStep23ItemSchema)
      .default([])
      .required()
  });

export const CommercialCombinedStep23ViewModelInitialValue: CommercialCombinedStep23ViewModel =
  { locations: [] };
export const CommercialCombinedStep23ViewModelInitialValueDev: CommercialCombinedStep23ViewModel =
  {
    locations: [
      {
        isLetAsResidential: false,
        ensureGasCertificate: true
      }
    ]
  };

export function useCommercialCombinedStep23InitialValue(
  data: Array<any>,
  thisStep: number,
  currentStep: number
) {
  return useMemo<CommercialCombinedStep23ViewModel>(() => {
    if (data[thisStep - 1] != null) return data[thisStep - 1];

    return {
      ...(devForcePp
        ? CommercialCombinedStep23ViewModelInitialValueDev
        : CommercialCombinedStep23ViewModelInitialValue),
      ...(data[thisStep - 1] || {})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data, thisStep === currentStep]);
}
